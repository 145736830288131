import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tool-die',
  templateUrl: './tool-die.component.html',
  styleUrls: ['./tool-die.component.css']
})
export class ToolDieComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
