<p>our-services works!</p>
<section class="bg-cover industries-main">
  <div class="container">
    <h1 class="pb-2">Industries</h1>
    <div class="row mb-5">
      <div class="col-lg-4">
        <div class="indust-wrap">
          <img src="assets/images/auto-1.png" alt="">
          <div class="indust-info">
            <h5>Automotive Industry</h5>
            <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
            <a [routerLink]="['/', 'services','automotive']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="indust-wrap">
          <img src="assets/images/auto-2.png" alt="">
          <div class="indust-info">
            <h5>Digital Manufacturing Industry</h5>
            <p>Digital manufacturing is an integral part of product lifecycle management (PLM)</p>
            <a [routerLink]="['/','services', 'digital-industry']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="indust-wrap">
          <img src="assets/images/industires/semiconductor.png" alt="">
          <div class="indust-info">
            <h5>semiconductor</h5>
            <p>A semiconductor material has an electrical conductivity value falling between that of a conductor</p>
            <a [routerLink]="['/', 'digital-industry', 'semiconductor']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
          </div>
        </div>
      </div>
    </div>
    <div class="row pb-5">
      <div class="col-lg-4">
        <div class="indust-wrap">
          <img src="assets/images/industires/webdevelop.png" alt="">
          <div class="indust-info">
            <h5>Web development</h5>
            <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
            <a [routerLink]="['/','services','web-development']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="indust-wrap">
          <img src="assets/images/aec-bim.jpg" alt="">
          <div class="indust-info">
            <h5>AEC/BIM</h5>
            <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
            <a [routerLink]="['/','services','epc-bim']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="indust-wrap">
          <img src="assets/images/aerospace.png" alt="">
          <div class="indust-info">
            <h5>Aerospace</h5>
            <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of Aerospace.</p>
            <a [routerLink]="['/','services','aerospace']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <div class="indust-wrap">
          <img src="assets/images/energy.png" alt="">
          <div class="indust-info">
            <h5>Energy</h5>
            <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
            <a [routerLink]="['/','services','energy']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="indust-wrap">
          <img src="assets/images/waste-water-treatment.png" alt="">
          <div class="indust-info">
            <h5>Waste Water Treatment</h5>
            <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
            <a [routerLink]="['/','services','waste-water-treatment']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
          </div>
        </div>
      </div>
      </div>
  </div>
</section>
