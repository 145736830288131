<section id="aec-bim-banner" class="min-banner text-white overlay">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="banner-text">
          <h1>EPC/BIM</h1>
        </div>
      </div>
    </div>
  </div>
</section>
<section id="getintouch" class="pt-5 pb-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="lato-reg ourservices-text">
          <h1>Design Support Services <br>for the Global EPC Industry</h1>
          <p>We are the pioneers in offering Design Support Services across the Global Architecture, Engineering, and
            Construction Industry. Our team has Architects, Engineers, Interior Designers, and CAD Technicians. The
            senior management at MSAII Associates has collective experience in setting up cutting edge operational
            processes and communication regime across different time zones. With the right mix of talent and domain
            expertise, we have designed a seamless process to offer Architectural, CAD and BIM services globally.</p>
          <p>At, The MSAII we value commitment, transparency, quality and collaboration to nurture a compelling work
            culture.</p>
          <h1>OUR SERVICES</h1>
          <h6>Overall incorporation for your project from concept to completion</h6>
          <p>MSAII Engineering is a leading engineering company. We use the latest technologies, such as Building
            Information Modeling and provide steel and rebar detailing design for any kind of steel and concrete
            structures. We as a Global firm, have vast experience in the United States, Canada, UK, Europe and Middle
            East. Our company guarantees to do the job at a high level without losing sight of your country-specific
            issues or knowledge of legal requirements.</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="bg-orange pt-5 pb-5 text-white">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="ace-bim-content">
          <h4 class="lato-bold">Rebar Detailing Services:</h4>
          <h5 class="lato-reg text-warning">3D BIM Services (LOD 500)</h5>
          <ul class="lato-reg">
            <li>Architecture</li>
            <li>Structural</li>
            <li>MEP BIM Modeling</li>
            <li>Detailing</li>
          </ul>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="ace-bim-content">
          <h4 class="lato-bold">Scan to BIM Services</h4>
          <h5 class="lato-reg text-warning">Detailing (2D CAD) Services:</h5>
          <ul class="lato-reg">
            <li>Steel Detailing</li>
            <li>Rebar Detailing</li>
            <li>Precast Detailing</li>
            <li>PT Detailing</li>
            <li>MEP Detailing</li>
            <li>As-built drawings</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="bg-cover pt-5 overlay standards-follow">
  <div class="container">
    <div class="row">
      <div class="col">
        <h2>Standards Follow</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 mb-4">
        <div class="follow-text">
          <img src="assets/images/support.png" alt="">
          <h4>ACI</h4>
        </div>
        <div class="follow-text">
          <img src="assets/images/support.png" alt="">
          <h4>British Standards</h4>
        </div>
        <div class="follow-text">
          <img src="assets/images/support.png" alt="">
          <h4>Indian Standards</h4>
        </div>
        <div class="follow-text">
          <img src="assets/images/support.png" alt="">
          <h4>Australian standards</h4>
        </div>
        <div class="follow-text">
          <img src="assets/images/support.png" alt="">
          <h4>As required by the client</h4>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="bg-cover pt-5 our-cap pb-5">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="lato-bold">Our Capabilities</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-4 mb-4">
        <div class="cap-title">
          <img src="assets/images/navisworks-no-year-lockup-stacked-screen.png" class="mg-auto" alt="">
        </div>
      </div>
      <div class="col-4 mb-4">
        <div class="cap-title">
          <img
            src="assets/images/png-clipart-logo-autocad-2014-autodesk-computer-software-architecture-drawing-angle-3d-computer-graphics.png"
            class="mg-auto" alt="">
        </div>
      </div>
      <div class="col-4 mb-4">
        <div class="cap-title">
          <img src="assets/images/autodesk-revit.png" class="mg-auto" alt="">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-3 mb-4">
        <div class="cap-title">
          <img src="assets/images/etabs.jpg" class="mg-auto" alt="">
        </div>
      </div>
      <div class="col-3 mb-4">
        <div class="cap-title">
          <img src="assets/images/logo5.png" class="mg-auto" alt="">
        </div>
      </div>
      <div class="col-3 mb-4">
        <div class="cap-title">
          <img src="assets/images/logo6.png" class="mg-auto" alt="">
        </div>
      </div>
      <div class="col-3 mb-4">
        <div class="cap-title">
          <img src="assets/images/tekla.png" class="mg-auto" alt="">
        </div>
      </div>
    </div>
  </div>
</section>

