<section id="print-3d-banner" class="min-banner overlay">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="banner-text">
            <h1 class="py-4">3D Printing</h1>
            <h4>Our team is passionate about delivering results</h4><br>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="pt-5 pb-5 design-content">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <p>One of the best ways to communicate your ideas and evaluate your product is by producing high quality, accurate 3D prototypes. Our comprehensive, rapid prototyping services can shorten the time it takes to work through design iterations and hone in on any assembly issues. Rapid Prototyping reduces lead times and helps control the overall cost of your product development.</p>
                <p>Here at Prototype House we ensure you are utilizing the correct processes and prototyping applications for your product. Prototype House provides rapid prototyping services, additive manufacturing, 3D printing and subtractive rapid prototyping services. See your designs and Ideas take shape with our full suite of rapid prototyping services.</p>
                <img src="assets/images/3d-printing-2.jpg" class="img-fluid b-radius w-50">
                <h1>Rapid Prototyping Services Including</h1>
                <h6>Stereolithography (SLA), Selective Laser Sintering (SLS), PolyJet, Fused Deposition Modeling (FDM), RTV Casting and 3-axis, 4-axis and 5-axis CNC machining.</h6>
                <p>Prototype House’s also has a wide range of rapid prototyping services that can produce almost any 3D shape or component. We work with many different mediums including plastics, metals, woods, and various composites.</p>
                <p>Prototypes help you to understand the interaction with your end use, and feel the ergonomics of the product. You will be able to receive valuable feedback only a physical model can create. Selecting the correct Rapid Prototyping services can be a daunting task, be sure to call us. Our dedicated staff  is here to answer your questions and ensure your prototyping service will be a success. From 3D prints, Functional Prototypes, Invention Prototypes, 3D printing and more!</p>
                <h6>Form Factor Prototypes</h6>
                <p>Iterate quickly on early stage designs with 3D printed parts delivered as fast as 24 hours.</p>
                <ul>
                    <li>Technologies: Fused Deposition Modeling (FDM)</li>
                    <li>Materials: PLA, ABS</li>
                    <li>Quote time: Instant</li>
                    <li>Production time: As fast as 1 day</li>
                </ul>
                <h6>Functional Prototypes</h6>
                <p>Get high quality precision prototypes to test functional builds.</p>
                <ul>
                    <li>Technologies: PolyJet, Selective Laser Sintering (SLS), Stereolithography (SLA)</li>
                    <li>Materials: ABS-like, Nylon 12, Rubber-like, VeroWhite, VeroBlack, VeroClear, Accura 25, Accura ClearVue</li>
                </ul>
                <h6>Low Volume Production Parts</h6>
                <p>Get your product to market faster with production-quality custom parts manufactured on demand, no expensive tooling required.</p>
                <ul>
                    <li>Technologies: HP® Multi Jet Fusion (MJF)</li>
                    <li>Materials: PA 12, PA 12 Glass Beads</li>
                </ul>
            </div>
        </div>
    </div>
  </section>

