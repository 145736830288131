import { Component } from '@angular/core';
import SwiperCore, { Autoplay, EffectFade, SwiperOptions, Swiper } from 'swiper';

// install Swiper modules
SwiperCore.use([Autoplay, EffectFade]);

@Component({
  selector: 'app-homepage-banner',
  templateUrl: './homepage-banner.component.html',
  styleUrls: ['./homepage-banner.component.css']
})
export class HomepageBannerComponent {
   constructor() {}


  ngOnInit(): void {
    const observerOptions = {
      root: null,
      rootMargin: "100px",
      threshold: 0.3
    };

    function observerCallback(entries: any, observer: any) {
      entries.forEach((entry: { isIntersecting: any; target: { classList: string; }; }) => {
        if (entry.isIntersecting) {
          // fade in observed elements that are in view
          entry.target.classList.replace('fadeOut', 'fadeIn');
        } else {
          // fade out observed elements that are not in view
          entry.target.classList.replace('fadeIn', 'fadeOut');
        }
      });
    }

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    const fadeElms = document.querySelectorAll('.fade');
    fadeElms.forEach(el => observer.observe(el));

  }

  config: SwiperOptions = {
    initialSlide: 0,
    speed: 5000,
    autoplay: {
      delay: 6000,
      disableOnInteraction: true
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    allowTouchMove: false
  }
}
