import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-functional-safety',
  templateUrl: './functional-safety.component.html',
  styleUrls: ['./functional-safety.component.css']
})
export class FunctionalSafetyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
