<section id="energy-banner" class="text-white overlay banner">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="banner-text" style="margin-top: 25%">
          <h1>Energy</h1>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="pt-5 pb-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <h2 class="lato-bold">Energy</h2>
        <p class="lato-reg">At ASR we design and analyze the energy systems that will power our society into the future.
          We leverage
          decades of experience with the ASME code along with advanced engineering tools and resources to deliver
          unrivaled results.</p>

        <p class="lato-reg">We use Finite Element Analysis (FEA) and Computational Fluid Dynamics (CFD) to generate
          powerful computer
          models to assist with the design and optimization of Nuclear, Wind, Solar, Fossil Fuel, and Hydroelectric
          power plant components.</p>
      </div>
    </div>
  </div>
</section>

<section class="pt-5 pb-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <div class="indust-wrap">
          <img src="assets/images/wind-energy.png" class="img-fluid">
          <div class="indust-info">
            <h5 class="lato-bold">Wind</h5>
            <p class="pb-4">For the past 5 years, wind energy has been growing at a rate of over 200%
              thanks to technological innovations and operational improvements.</p>
            <a [routerLink]="[]" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="indust-wrap">
          <img src="assets/images/solar-energy.png" class="img-fluid">
          <div class="indust-info">
            <h5 class="lato-bold">Solar</h5>
            <p class="pb-4">With one of our principal engineers, Michael Costen, holding a patent for
              the invention of a lightweight low-cost solar collector we have extensive experience with the design and
              analysis of solar technologies.</p>
            <a [routerLink]="[]" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="indust-wrap">
          <img src="assets/images/fossil-energy.png" class="img-fluid">
          <div class="indust-info">
            <h5 class="lato-bold">Fossil</h5>
            <p class="pb-4">As the mainstay for generating electricity, it has been advancements in
              fossil fuels that have done the most to reduce CO2 emissions in the US. Specifically the advancement of
              the drilling and extraction technology best known as fracking.</p>
            <a [routerLink]="[]" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
