import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fpga-emulation-post-si-validation',
  templateUrl: './fpga-emulation-post-si-validation.component.html',
  styleUrls: ['./fpga-emulation-post-si-validation.component.css']
})
export class FpgaEmulationPostSiValidationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
