<section id="modal-based-banner" class="overlay min-banner text-white ">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="banner-text">
            <h1>Model Based </h1> <br> <h2> System Engineering</h2>
            <h4>Our team is passionate about delivering results</h4>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="pad-50 design-content pt-5 pb-5">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <p>Utilizing the MSAII team of experienced engineers, we create embedded software using MATLAB/Simulink, offering high-quality solutions to several OEMs. Using state of the art technologies, we design and develop control software, validating it through Model in the Loop Testing, Hardware in the Loop Testing, and the respective hardware. Our thorough model-based software development approach confirms software strategies and fixes problems early on before applying anything onto an actual Vehicle component. This approach allows for accelerated development of control strategies, while our unmatched experience enables us to develop quality control software.</p>
                <p>Models lays a strong foundation for development and are very useful for proving out and testing algorithms. The more the models complaint to requirements, better would the development and testing results.Combined with strong system understanding , tool mastery and process knowledge , you can be rest assured on performance and quality of our Models. The models are close to match real world attributes and thus creating a digital twin of the environment and physical components that can interact seamlessly.With higher interactions of Hardware and software , the inter- dependability and complexity of systems increase exponentially.</p>
                <p>Our approach relies on model-based systems engineering (MBSE), to describe the system architecture in SysML and analyze the behavior of the components involved with all dependencies and interactions. We specialize in systems and right tools and thus catering a wide product range and domains across automotive, medical and aviation.Thus ensuring uniqueness in the models developed based on the customer needs.</p>
                <p>Using proven standards and shareable libraries , we ensure the development process is sustainable and scalable.High degree of reusability is ensured with Model library use , to enable effective cost reduction.We have active participation with leading industry consortium and actively engage in enabling developments of Co-simulation and Model Exchange.</p>
                <div class="text-center pb-5 pt-5">
                  <img src="assets/images/info1.png" class="img-fluid w-50" alt="">
                </div>
                <h1>Our Services</h1>
                <ul>
                    <li>High accuracy Model Design System with multiple fidelity levels</li>
                    <li>Application Dependent Use Case Modeling Approaches and Tools</li>
                    <li>Implementation of Co-Simulation</li>
                    <li>Automated, Measurement-Based Generation of Plant Models</li>
                    <li>Automated, Measurement-Based Parameterization of Control Loops</li>
                    <li>Target Platform Optimized Code Generation</li>
                    <li>Model Libraries</li>
                </ul>
                <div class="text-center pt-5 pb-5">
                  <img src="assets/images/info2.png" class="img-fluid w-50" alt="">
                </div>
            </div>
        </div>
    </div>
  </section>
  <section class="common-pad industries-main">
    <div class="container">
      <h1 class="py-2">Other services</h1>
      <div class="row">
        <div class="col-lg-4 mb-5">
          <div class="indust-wrap">
            <img src="assets/images/embeded-system/embeded-system.jpg" alt="">
            <div class="indust-info">
              <h5>Embedded Software Development</h5>
              <p>The global automotive industry is always in flux, as new models &amp; designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','embedded-software-system']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-5">
          <div class="indust-wrap">
            <img src="assets/images/embeded-system/design-verification.jpg" alt="">
            <div class="indust-info">
              <h5>Design Verification & Validation</h5>
              <p>Digital manufacturing is an integral part of product lifecycle management (PLM)</p>
              <a [routerLink]="['/','design-verification-validation']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-5">
          <div class="indust-wrap">
            <img src="assets/images/embeded-system/functional-safety.jpg" alt="">
            <div class="indust-info">
              <h5>Functional Safety</h5>
              <p>The global automotive industry is always in flux, as new models &amp; designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','functional-safety']"  class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-5">
          <div class="indust-wrap">
            <img src="assets/images/embeded-system/system-engineering.jpg" alt="">
            <div class="indust-info">
              <h5>Systems Engineering</h5>
              <p>The global automotive industry is always in flux, as new models &amp; designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','system-engineering']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-5">
            <div class="indust-wrap">
              <img src="assets/images/embeded-system/cyber-security.jpg" alt="">
              <div class="indust-info">
                <h5>Cyber Security</h5>
                <p>The global automotive industry is always in flux, as new models &amp; designs alter the shape and performance of automobiles.</p>
                <a [routerLink]="['/','cyber-security']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
      </div>
    </div>
  </section>
  <section class="bg-cover mb-4 industries-main">
    <div class="container">
      <h1 class="pb-2">Industries</h1>
      <div class="row mb-5">
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/auto-1.png" alt="">
            <div class="indust-info">
              <h5>Automotive Industry</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/', 'services','automotive']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/auto-2.png" alt="">
            <div class="indust-info">
              <h5>Digital Manufacturing Industry</h5>
              <p>Digital manufacturing is an integral part of product lifecycle management (PLM)</p>
              <a [routerLink]="['/','services', 'digital-industry']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/industires/semiconductor.png" alt="">
            <div class="indust-info">
              <h5>semiconductor</h5>
              <p>A semiconductor material has an electrical conductivity value falling between that of a conductor</p>
              <a [routerLink]="['/', 'digital-industry', 'semiconductor']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row pb-5">
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/industires/webdevelop.png" alt="">
            <div class="indust-info">
              <h5>Web development</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','services','web-development']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/aec-bim.jpg" alt="">
            <div class="indust-info">
              <h5>AEC/BIM</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','services','epc-bim']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/aerospace.png" alt="">
            <div class="indust-info">
              <h5>Aerospace</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of Aerospace.</p>
              <a [routerLink]="['/','services','aerospace']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/energy.png" alt="">
            <div class="indust-info">
              <h5>Energy</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','services','energy']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/waste-water-treatment.png" alt="">
            <div class="indust-info">
              <h5>Waste Water Treatment</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','services','waste-water-treatment']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        </div>
    </div>
  </section>
