import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-design-verification',
  templateUrl: './design-verification.component.html',
  styleUrls: ['./design-verification.component.css']
})
export class DesignVerificationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
