<section id="ui-ux-banner" class="min-banner overlay">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="banner-text">
            <h1>UI/UX DESIGN</h1>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="pt-5 pb-5 design-content">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1>UI/UX DESIGN</h1>
                <p>Our young automotive UI / UX Design department follows a unique approach to design Interfaces. For us Interface Design is about optimizing ergonomics, integrating the latest technology and developing user-centric products, carefully built around different user personas for automotive applications.</p>
                <p>We bring your idea to reality by building creative Wireframes and Prototypes, combined with a great user experience. Our high demanded automotive UI UX Design applications include advanced tech features like Voice control, Face-recognition and gesture control as well as holographic</p>
                <img src="assets/images/ui-ux-2.jpg" class="b-radius img-fluid w-50" alt="">
                <h1>UX/UI</h1>
                <h6>HOW WE DELIVER UI AND UX SERVICES</h6>
                <p>A decade in design has allowed our team to perfect the process of delivering UI and UX services. We follow established design standards, workflows, and guidelines — you get the product you need, delivered by expert designers within the set timeframe.</p>
                <img src="assets/images/ui-ux-3.jpg" class="b-radius img-fluid w-50" alt="">
            </div>
        </div>
    </div>
  </section>
  <section id="core-services" class="common-pad pt-5 pb-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="section-title mb-5">
            <h1>Digital Industry Services</h1>
            <p>MSAII is a modern engineering company, dedicated to providing high quality design and product development
              services for the engineering industry.</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="core-services-wrap">
            <a [routerLink]="['/','digital-industry', 'ai-ml']">
              <img src="assets/images/al-ml.png" class="img-fluid" alt="">
              <div class="lato-bold">AI / ML</div>
            </a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="core-services-wrap">
            <a [routerLink]="['/','digital-industry', 'iot']">
              <img src="assets/images/iot.png" alt="iot">
              <div class="lato-bold">IOT</div>
            </a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="core-services-wrap">
            <a [routerLink]="['/', 'digital-industry','software-testing']">
              <img src="assets/images/testing/software-testing.png" alt="software-testing">
              <div class="lato-bold">Software Testing</div>
            </a>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-4">
          <div class="core-services-wrap">
            <a [routerLink]="['/', 'digital-industry', 'semiconductor']">
              <img src="assets/images/industires/digital-banner.png" alt="digital banner">
              <div class="lato-bold">Semiconductor</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
