<!-- <section id="home-banner" class="text-white overlay banner fade fadeOut">
  <div class="container" >
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="banner-text b1" style="margin-top: 46%">
          <h1 class="animated bounceInRight pb-3" style="animation-delay: 1s;">We create powerful strategies <span class="border-right pr-2"></span> </h1>
          <h1 class="sub-heading">practical solutions</h1><br>
          <h5><span  class="sub-heading">Msaii is a result-driven firm. A masterful team that, execute
              every engagement</span></h5>
          <h5 style="margin-top: 1% !important;" class="ht0"><span>with purpose and focus, on measurable,
              actionable results.</span></h5>
          <h5 style="line-height: 60px;" class="ht0"><a class="nav-link" routerLink="/about" class="know-more">Know more
              <i class="fa fa-arrow-right" aria-hidden="true"></i></a></h5>
        </div>
      </div>
    </div>
  </div>
</section> -->

<header class="intro">

  <app-homepage-banner></app-homepage-banner>

  <!-- <div class="intro-header">
    <div class="container fade fadeOut">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="banner-text b1">
            <h1 class="animateText pb-3">We create powerful strategies <span class="border-right pr-2"></span> </h1>
            <h1 class="sub-heading animateText">practical solutions</h1><br>
            <h5 style="color: white;">
              <span class="sub-heading">
                Msaii is a result-driven firm. A masterful team that, execute every engagement
              </span>
            </h5>
            <h5 style="margin-top: 1% !important;color: white;" class="ht0">
              <span>with purpose and focus, on measurable, actionable results.</span>
            </h5>
            <h5 style="line-height: 60px;color: white;" class="ht0">
              <a class="nav-link" routerLink="/about" class="know-more">
                Know more
                <i class="fa fa-arrow-right" aria-hidden="true"></i>
              </a>
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</header>

<section id="home-banner2" class="feature-driven htext0">
  <div class="container fade fadeOut">
    <div class="row">
      <div class="col-lg-7 col-sm classic__div p-5" style="padding-top: 4rem !important;">
        <h1 class="classic__h1" data-aos="zoom-in" data-aos-duration="2000" data-aos-anchor-placement="bottom-bottom">
          Results Driven Team
        </h1>
        <p class="classic__p" data-aos="fade-up" data-aos-duration="3000">
          We are a team of engineering experts dedicated to providing effective, efficient and practical integrated
          solutions for clients.
        </p>
        <div data-aos="fade-up" data-aos-duration="3000" data-aos-anchor-placement="bottom-bottom">
          <a class="nav-link" routerLink="/about" class="know-more about-know">
            Know more About <i class="fa fa-arrow-right" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <div class="robot_hand_mobile col-lg-5 col-sm p-0" data-aos="fade-up" data-aos-duration="2000"></div>
    </div>
  </div>
</section>
<app-industry-cards></app-industry-cards>

<!-- <section id="industries" class="pt-5 pb-5">
  <div class="container">

    <div class="row">
      <div class="col-lg-12">
        <div class="section-title" data-aos="fade-down" data-aos-duration="2000">
          <h2 class="title_color">Industries we serve</h2>
          <p class="text-white">Vertical and horizontal market – we dive deep across industries.</p>
        </div>
      </div>
    </div>

    <div class="row" style="margin-left: -15px !important;margin-right: -15px !important;">

      <div class="col" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
        <header class="mainHeading">
          <div class="mainHeading__content">
            <article class="mainHeading__text">
              <h1 class="mainHeading__title">Automotive</h1>
              <p class="mainHeading__description">
                The global automotive industry is always in flux, as new models & designs alter the shape and
                performance of automobiles.
              </p>
              <div class="button">
                <a [routerLink]="['/', 'services','automotive']" class="link">Explore</a>
              </div>
            </article>

            <figure class="mainHeading__image">
              <img src="assets/images/auto-1.png" alt="stock_illustration" />
            </figure>
          </div>
        </header>
      </div>

      <div class="col" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
        <header class="mainHeading">
          <div class="mainHeading__content">
            <article class="mainHeading__text">
              <h1 class="mainHeading__title">Aerospace</h1>
              <p class="mainHeading__description">The global automotive industry is always in flux, as new models &
                designs alter the shape and performance of Aerospace.</p>
              <div class="button">
                <a [routerLink]="['/','services','aerospace']" class="link">Explore</a>
              </div>
            </article>
            <figure class="mainHeading__image">
              <img src="assets/images/aerospace.png" alt="stock_illustration">
            </figure>
          </div>
        </header>
      </div>

      <div class="col" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
        <header class="mainHeading digital_manufacturing_heading">
          <div class="mainHeading__content">
            <article class="mainHeading__text">
              <h1 class="mainHeading__title" style="line-height: 1.7rem !important;">Digital Manufacturing</h1>
              <p class="mainHeading__description">
                Digital manufacturing is an integral part of product lifecycle management (PLM)
              </p>
              <div class="button">
                <a [routerLink]="['/','services', 'digital-industry']" class="link">Explore</a>
              </div>
            </article>

            <figure class="mainHeading__image">
              <img src="assets/images/auto-2.png" alt="stock_illustration" />
            </figure>
          </div>
        </header>
      </div>

    </div>

    <div class="row" style="margin-left: -15px !important;margin-right: -15px !important;">

      <div class="col" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
        <header class="mainHeading semiconductor_heading">
          <div class="mainHeading__content">
            <article class="mainHeading__text">
              <h1 class="mainHeading__title">SEMICONDUCTOR</h1>
              <p class="mainHeading__description">
                A semiconductor material has an electrical conductivity value falling between that of a conductor.
              </p>
              <div class="button">
                <a [routerLink]="['/', 'digital-industry', 'semiconductor']" class="link">Explore</a>
              </div>
            </article>

            <figure class="mainHeading__image">
              <img src="assets/images/industires/semiconductor.png" alt="stock_illustration" />
            </figure>
          </div>
        </header>
      </div>

      <div class="col" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
        <header class="mainHeading">
          <div class="mainHeading__content">
            <article class="mainHeading__text">
              <h1 class="mainHeading__title web_development__h1">Web development</h1>
              <p class="mainHeading__description">
                The global automotive industry is always in flux, as new models & designs alter the shape and
                performance of automobiles.
              </p>
              <div class="button">
                <a [routerLink]="['/','services','web-development']" class="link">Explore</a>
              </div>
            </article>

            <figure class="mainHeading__image">
              <img src="assets/images/industires/webdevelop.png" alt="stock_illustration" />
            </figure>
          </div>
        </header>
      </div>

      <div class="col" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
        <header class="mainHeading">
          <div class="mainHeading__content">
            <article class="mainHeading__text">
              <h1 class="mainHeading__title">Energy</h1>
              <p class="mainHeading__description">The global automotive industry is always in flux, as new models &
                designs alter the shape and performance of automobiles.</p>
              <div class="button">
                <a [routerLink]="['/','services','energy']" class="link">Explore</a>
              </div>
            </article>
            <figure class="mainHeading__image">
              <img src="assets/images/energy.png" alt="stock_illustration">
            </figure>
          </div>
        </header>
      </div>

    

    </div>
  </div>
</section> -->

<section class="our_services fade fadeOut">

  <div class="container">
    <div class="row">
      <div class="col-lg-12 pt-3 pb-3">
        <div class="our_services_title" data-aos="slide-right" data-aos-duration="2000">
          <h2 class="title_color">Core Services we do</h2>
          <p> Global product development | Digital platform engineering and Solutions provider.</p>
        </div>
      </div>
    </div>
  </div>

  <div class="whole_services connect-pad">
    <div class="row">

      <div class="col">
        <div class="services_list overlay design_image" style="animation-delay: 5s;">

          <a [routerLink]="['/','automotive', 'designs']">
            <img src="assets/images/designs/designs.png" width="100%">
          </a>

          <div class="service_txt">
            <p><a class="design_text" [routerLink]="['/','automotive', 'designs']">Design</a></p>
          </div>

        </div>
      </div>

      <div class="col">
        <div class="services_list overlay virtual_validation">

          <a [routerLink]="['/','automotive', 'virtual-validation']">
            <img class="img-responsive blur_one" src="assets/images/virtual_copy.png" width="100%">
          </a>

          <div class="service_txt">
            <p><a class="virtual_validation_text" [routerLink]="['/','automotive', 'virtual-validation']">Virtual
                Validation</a></p>
          </div>

        </div>
      </div>

      <div class="col">
        <div class="services_list overlay embedded_system">

          <a [routerLink]="['/','automotive', 'embedded-systems']">
            <img class="img-fluid blur_one" src="assets/images/embeded-system/banner3_copy.png" width="100%">
          </a>

          <div class="service_txt">
            <p><a class="embedded_system_text" [routerLink]="['/','automotive', 'embedded-systems']">Embedded System</a>
            </p>
          </div>

        </div>
      </div>

      <div class="col">
        <div class="services_list overlay ai_ml">

          <a [routerLink]="['/', 'digital-industry', 'ai-ml']">
            <img class="img-fluid blur_one" src="assets/images/al-ml_copy1.png" width="100%">
          </a>

          <div class="service_txt">
            <p><a class="ai_ml_text" [routerLink]="['/','', 'ai-ml']">AI/ML</a></p>
          </div>

        </div>
      </div>

      <div class="col">
        <div class="services_list overlay ui_ux">

          <a [routerLink]="['/', 'digital-industry','ui-ux']">
            <img class="img-fluid blur_one" src="assets/images/ui-ux-2_copy1.jpg" width="100%">
          </a>

          <div class="service_txt">
            <p><a class="ui_ux_text" [routerLink]="['/', 'digital-industry','ui-ux']">UI/UX</a></p>
          </div>

        </div>
      </div>
    </div>
  </div>

</section>

<!--Start of Tawk.to Script-->
<script type="text/javascript">
  var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
  (function () {
    var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = 'https://embed.tawk.to/615fd42f157d100a41ab5d14/1fhf59mng';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);
  })();
</script>
<!--End of Tawk.to Script-->