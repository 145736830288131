import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }
  
  sendMail(f: NgForm) {
    this.http.post('http://localhost:3000/send', f.form.value).subscribe(message => {
      console.log("successfully sent mail");
    })
  }
}
