import { Injectable, Component, OnInit, NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent {


  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }

  sendMail(f: NgForm) {
    this.http.post('http://localhost:3000/send', f.form.value).subscribe(message => {
      console.log("successfully sent mail");
    })
  }

  
}
