<section id="automative-banner" class="min-banner overlay text-white">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="banner-text">
                    <h1 class="py-4">AUTOMOTIVE</h1>
                    <p>From concept to realization</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="pt-5 pb-5 industries-main designs">
  <div class="container">
      <div class="row">
          <div class="col-12 mb-3">
              <div class="design-content">
                  <p>MSAII provides industrial design services for the automotive, consumer, commercial, and healthcare industries. Our industrial design services combined with our engineering expertise allow us to generate design solutions that are innovative and have fast-turnaround development schedules.</p>
                  <p>MSAII can assist in all of the following areas: ideas, concept development, concept refinement, 3-D rendering, and package design. The industrial design process usually begins with conceptual studies and iteration between MSAII and our clients. MSAII will work closely with the client to ensure that the holistic design intent is captured and engineering considerations are incorporated at this early stage of development.</p>
              </div>
          </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h1>MSAII's core competencies include</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-5">
          <ul>
            <li>Production Design and Engineering</li>
            <li>Concept Development</li>
            <li>Feasibility Studies</li>
            <li>Vehicle Packaging</li>
            <li>Target Setting</li>
            <li>System Integration</li>
            <li>Powertrain/Calibration</li>
            <li>Automotive: BIW, Interior, Exterior,Lighting,Closures,Chassis, Seating</li>
            <li>CAD customization </li>
          </ul>
        </div>
      </div>
    <div class="row text-white">
      <div class="col-lg-4 mb-5">
          <div class="indust-wrap">
              <a [routerLink]="['/','product-design']">
                  <img src="assets/images/designs/product-design.png" alt="">
                  <h3>Product design</h3>
              </a>
          </div>
      </div>
      <div class="col-lg-4 mb-5">
          <div class="indust-wrap">
              <a [routerLink]="['/','engineering']">
                  <img src="assets/images/designs/eng.png" alt="">
                  <h3>Engineering</h3>
              </a>
          </div>
      </div>
      <div class="col-lg-4 mb-5">
        <div class="indust-wrap">
          <a [routerLink]="['/','competency-development']">
              <img src="assets/images/designs/competancy.png" alt="">
              <h3>Competancy Development</h3>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>


<script src="https://code.jquery.com/jquery-3.2.1.slim.min.js"
integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN"
crossorigin="anonymous"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js"
integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q"
crossorigin="anonymous"></script>
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"
integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl"
crossorigin="anonymous"></script>

<script>
/**
* 鼠标移上去显示图片 文字
*/
document.querySelector("#icons").addEventListener(
"mouseover",
function (e) {
    // 不是 hover 在图片上，就退出
    if (e.target.tagName != "IMG") {
        return;
    }

    var plant = e.target, // 被 hover 的植物图片
        me = e.target.parentNode; // 被 over 的圆圈

    var className = me.getAttribute("data-class"),
        title = me.getAttribute("data-title"),
        plantSrc = plant.getAttribute("src");

    document.querySelector(".mid").src = plantSrc;
    document.querySelector(".mid").classList.add("active"); // 激活中间图片
    document.querySelector(".middle").classList.add("active"); // 圆圈激活
    document.querySelector("#showText").innerHTML = title; // 显示文字

    var temps = document.querySelectorAll(".icons .icon");

    for (var i = 0; i < temps.length; i++) {
        temps[i].classList.remove("active");
        temps[i].classList.add("disabled");

        if (temps[i].getAttribute("data-class") === className) {
            // 高亮 线
            temps[i].classList.add("active");
        }
    }

    me.classList.add("active");
},
false
);

/**
* 鼠标移开去掉图片 文字
*/
document.querySelector("#icons").addEventListener(
"mouseout",
function (e) {
    var me = e.target;
    var temps = document.querySelectorAll(".icons .icon");

    for (var i = 0; i < temps.length; i++) {
        temps[i].classList.remove("active");
        temps[i].classList.remove("disabled");
    }

    document.querySelector(".middle").classList.remove("active");
    document.querySelector(".mid").classList.remove("active"); // 中间图片去激活
    me.classList.remove("active");
    me.classList.remove("disabled");
    this.$.showText.innerHTML = "";
},
false
);

$(window).scroll(function () {
var scroll = $(window).scrollTop();

if (scroll >= 150) {
    $(".header-dark").addClass("mani");
} else {
    $(".header-dark").removeClass("mani");
}
});
</script>
