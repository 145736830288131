<section id="wwt-banner" class="text-white overlay banner">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="banner-text" style="margin-top: 25%">
          <h1>Waste Water Treatment</h1>
        </div>
      </div>
    </div>
  </div>
</section>

