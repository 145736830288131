<section id="howWeWork" class="text-white min-banner pt-3 pb-3">
    <div class="container">
        <div class="row ">
            <div class="col-lg-12 howWeWorkDiv">
                <div class="banner-text">
                    <h1 data-aos="fade-up" data-aos-duration="2000">We deliver exceptional value to our clients</h1>
                    <p class="py-4" data-aos="fade-up" data-aos-duration="3000">We are experts in delivering managed
                        team extension, AgileSquads and consulting services.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="engagementModels" class="engagementModels pt-5 pb-5">

    <div class="container">
        <div class="row ">
            <div class="col-lg-7">
                <div class="engagementBanner">
                    <h1 data-aos="fade-up" data-aos-duration="2000" data-aos-offset="0">Engagement Models</h1>
                    <p class="py-4" data-aos="fade-up" data-aos-duration="3000" data-aos-offset="0">Because every client
                        is unique, we have developed engagement models tailored for businesses from start-ups to Fortune
                        500 companies.</p>
                </div>
            </div>
            <div class="col-lg-5 engagementImage"></div>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-lg">
                <h1>Managed Team Extension</h1>
                <h5>Recommended For:</h5>
                <ul>
                    <li>Team Extensions: Our talented technical team becomes an extension of your team, saving you time
                        and resources on recruiting and onboarding</li>
                    <li>Ongoing Bug Fixes & Support: You can rely on the team 24/7 for availability and assistance</li>
                    <li>Long-Term Development: Our team becomes as vested in the success of your project as your own
                        team
                    </li>
                </ul>
                <!-- <a class="nav-link" routerLink="/how-we-work/team-extension">Know More</a> -->
                <a routerLink='/how-we-work/team-extension' class="nav-link">Know More</a>
            </div>
            <div class="col-lg">
                <h1>AgileSquads</h1>
                <h5>Recommended For:</h5>
                <ul>
                    <li>Smart Delegation: No need to assign internal staff to build a project from scratch </li>
                    <li>Faster Results: With AgileSquads approach, short interactions guarantee that every step of your
                        project is implemented on time </li>
                    <li>Transparency & Control: It is easy to hand over the requirements to a ready-to-go AgileSquad and
                        check in with them periodically</li>
                </ul>
                <a class="nav-link" routerLink="/how-we-work/agile-squads">Know More</a>
            </div>
        </div>

        <div class="row">
            <div class="col-lg">
                <h1>Professional Service</h1>
                <h5>Recommended For:</h5>
                <ul>
                    <li>Strategy development: Get the help you need to translate your business goals to technology plans
                        before you build your team</li>
                    <li>Situational assessment: Receive a second opinion about challenges you are facing and
                        recommendations
                        to move forward</li>
                    <li>Prepare to partner: We can help you define a project in a way that raises your comfort working
                        with
                        a technology partner on your software development needs</li>
                </ul>
                <a class="nav-link" routerLink="/how-we-work/professional-services">Know More</a>
            </div>
        </div>

    </div>
</section>
<section id="ourProcess" class="ourProcess pt-5 pb-5">
    <div class="container">
        <div class="row ">
            <div class="col-lg-12">

                <h1 data-aos="fade-up" data-aos-duration="2000">Our process</h1>

                <h5 data-aos="fade-up" data-aos-duration="2000">We've developed a unique recruitment process, based
                    on industry best practices, that enables our recruitment team to find the best experts in the
                    market. This vital part of a project life-cycle ensures a succesful result.
                </h5>

                <h1 data-aos="fade-up" data-aos-duration="2000">MSAII's Standard Recruitment Process</h1>

                <p class="py-4" data-aos="fade-up" data-aos-duration="2000">
                    Typical candidate search lasts 2 weeks.
                </p>

            </div>
        </div>
    </div>

</section>