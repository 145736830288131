<section id="industries-banner" class="min-banner overlay text-white">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
            <div class="banner-text">
            <h1>VLSI <span style="font-family: Lato-thin">Design</span></h1>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="pt-5 chip semiCont">
    <div class="container">
        <div class="row">
            <div class="col-8">
                <h2>Design Engineering</h2>
                <h6>Rich experience, innovative mindset – driving Chip solutions</h6>
                <p>Complementing Tessolve’s post silicon Test engineering solutions, VLSI design capabilities are added to our service portfolio in 2012. This is to support the increasing demand for design solutions for the state-of-the-art VLSI chipsets. The team was built with expertise and capability in multiple domains and technologies. Today, we are poised to be the leading solutions provider for Chip Designs across verticals – Automotive, Server, Graphics and mobile platforms, to name a few. Our design team works cohesively with Embedded, Test, PCB and Validation teams to provide relevant, rewarding, and complete solutions.</p>
            </div>
            <div class="col-4">
              <img src="assets/images/industires/Chip_Solutions_IMG.png" class="w-75" alt="">
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h3 class="semi-header">The Process/Technology Nodes:</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <ul>
                    <li>Design Solutions from Specifications to GDSII sign-off for Analog, Digital and Mixed Signal chips, across process nodes from 350nm to most advanced 5nm</li>
                    <li>Digital Design Turnkey Capabilities – RTL, DV, DFT, PD, till GDS sign off</li>
                    <li>Analog/AMS Design Turnkey Capabilities – Analog Modelling, Circuit Design, Layout Design, AMS Verification</li>
                    <li>Domain experience includes Data Converters, Power Management, High Speed Interfaces – PCIe, DDR, SerDes, SAS/SATA, Ethernet, MIPI, Foundation IPs etc</li>
                </ul>
            </div>
            <div class="col-lg-6">
                <ul>
                    <li>Applications include Servers, Automotive, Graphics, Mobile platforms, Medical, Consumer products etc</li>
                    <li>Expertise in Pre-silicon verification, FPGA Prototyping and Emulation</li>
                    <li>Expertise in Post-silicon validation on Bench Char and ATE</li>
                    <li>Expertise in Low power, High Power, High Speed designs with the knowledge and experience in power savings and design optimisation techniques</li>
                </ul>
            </div>
        </div>
    </div>
  </section>
  <section class="pad-50 chip semiCont" id="amsdesign">
    <div class="container">
        <div class="row">
            <div class="col-8">
                <h2>Analog & Mixed Signal (AMS) Design</h2>
                <h6>Agile and high quality – analog design creation</h6>
                <p>Analog and Mixed signal design team at Tessolve specializes in High quality design for different applications with process nodes varying from 350nm to most advanced 5nm designs. The IPs were developed for different industry verticals like Automotive, Communication, Consumer, Medical, IoT etc. The competent team has rich experience of successfully delivering more than 50+ silicon proven Analog chips during last few years with full ownership of the delivery from Spec to GDSII sign off, supported with silicon validation to global semiconductor companies.</p>
              </div>
            <div class="col-4">
              <img src="assets/images/industires/Analog-Design_infographic.png" class="w-75" alt="">
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h3 class="semi-header">Analog & Mixed Signal – The Highlights:</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <ul>
                    <li>Complete Analog Design life-cycle from specs to post-silicon validation</li>
                    <li>Expertise for developing Full IP & Block level</li>
                    <li>Expertise on CMOS/FinFET process node: 5nm, 7nm, 10nm, 14nm, 22nm, 45nm, 65nm, 90nm, 130nm, 180nm & 350nm</li>
                </ul>
            </div>
            <div class="col-lg-6">
                <ul>
                    <li>High Speed AMS Design & Layout</li>
                    <li>RF Layout</li>
                    <li>IO Design & Layout</li>
                    <li>Standard Cell Design & Layout</li>
                    <li>Verilog-A & V-AMS Modelling</li>
                </ul>
            </div>
        </div>
    </div>
  </section>
  <section class="bg-yellow text-white pt-5 pb-5" id="rtldesign">
    <div class="container">
        <div class="row">
            <div class="col-4">
                <img src="assets/images/industires/rtl-side-img.png" class="w-75" alt="">
              </div>
            <div class="col-8">
                <h2>RTL Design</h2>
                <p>Tessolve offers RTL design from product specification which include Synthesis & Post Netlist simulations. And supports integration of RTL Blocks for full SOC development.</p>
                <h3>Highlights:</h3>
                <ul>
                    <li>Integration: Block integration, Clocks and Reset, Clock gating, DFT and DFD, Lint, CDC</li>
                    <li>IP Block Development: Reusable, Pipelined, CDC, Lint, DFT, HW-SW partitioning</li>
                    <li>Protocols: AMBA CHI/AXI/AHB/APB, MIPI (DSI, CSI, Slimbus, Soundwire, Unipro, NAND Flash); PCIe; SAS/SATA; SDIO Host/Device/Memory/Combo; I2C, SPI, UART, MDIO</li>
                </ul>
            </div>
        </div>
    </div>
  </section>
  <section class="pt-5 pb-5 chip" id="systemcsol">
    <div class="container">
        <div class="row">
            <div class="col-8">
                <h2>SystemC Solutions</h2>
                <p>SystemC addresses the need for a system design and verification language that spans hardware and software. It is a language built in standard C++ by extending the language with a set of class libraries created for design and verification. Tessolve customers are applying SystemC for architectural exploration, performance modelling, functional verification, and high-level synthesis.</p>
              </div>
            <div class="col-4">
              <img src="assets/images/industires/system-c-color-ico.png" class="w-75" alt="">
            </div>
        </div>
    </div>
  </section>
  <section class="pt-5 pb-5 bg-light chip semiCont" id="designverify">
    <div class="container">
        <div class="row">
            <div class="col-4">
                <img src="assets/images/industires/Design_Verification_img.png" class="w-75" alt="">
              </div>
            <div class="col-8">
                <h2>Design Verification</h2>
                <h6>Silicon success thanks to design prowess</h6>
                <p>Hardware Verification is the process of checking the design functionality for the given specifications. It is one of the largest tasks in silicon development and has the biggest impact on the key business drivers of quality, schedule, and cost. Tessolve offering covers all aspects of Verification flow from feature extraction to coverage closure and equipped with custom-designed verification productivity tools for reducing verification turnaround times. Tessolve bring verification expertise across multiple industry verticals spanning Consumer Electronics, Wireless, Data Centre, Automotive and Memory/Storage segments.</p>
                <h3 class="semi-header">DV – The Highlights:</h3>
                <ul>
                    <li>Flexible resources proficient in verification methodologies and tools.</li>
                    <li>Tools for verification productivity</li>
                    <li>Consultancy programs and continuous improvements through Benchmarking.</li>
                    <li>Training on verification strategy and the latest verification methodologies.</li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h3 class="semi-header">Key expertise areas</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <ul>
                    <li>Languages and Methodologies: C/C++/SystemVerilog/Verilog/SystemC/UVM</li>
                    <li>Protocol Knowledge: High-speed, ARM-based, Memory, Storage, Serial IO, MIPI</li>
                    <li>Processor Expertise: ARM, MIPS, x86, Power</li>
                    <li>Low Power Verification – UPF Power-aware RTL and Gate Simulation</li>
                </ul>
            </div>
            <div class="col-lg-6">
                <ul>
                    <li>Formal/Static Property based Verification</li>
                    <li>Emulation Platforms – Zebu, Palladium, Veloce</li>
                </ul>
            </div>
        </div>
    </div>
  </section>
  <section class="pt-5 pb-5 text-white chip bg-orange semiCont" id="designdebug">
    <div class="container">
        <div class="row">
            <div class="col-8">
                <h2>Design For Test And Debug</h2>
                <h6>Engineering chip anatomy with testability and debugging</h6>
                <p>Design For Testing (DFT) and Debugging (DFD) are critical stages in the micro-architectural phase of the design. Working in tandem with client’s design team, our experts understand the anatomy of the chip and thus helps carve out its DFT and DFD architecture. They leverage the implemented DFT architecture incorporating RTL and design verification via the pattern generation phase.</p>
              </div>
            <div class="col-4">
              <img src="assets/images/industires/DesignFor_Test_And_Debug_infographic.png" class="w-75" alt="">
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h3>DFT & DFD – The Highlights:</h3>
                <h6>Complex design samples we handled including, but not limited to</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <ul>
                    <li>Multiple Clock and Voltage Domains</li>
                    <li>Mixed signal low speed and high-speed designs</li>
                    <li>Power sensitive designs</li>
                    <li>Embedded processor-based designs</li>
                    <li>Large number of memory types – SRAM, ROM, CAM etc.</li>
                    <li>Complex Analog testing includes SERDES, DDR and A/D, D/A converters</li>
                </ul>
            </div>
            <div class="col-lg-6">
                <ul>
                    <li>Experience in industry standard EDA tools for Memory BIST, ATPG, JTAG etc. from Mentor Graphics, Synopsys and Cadence</li>
                    <li>Post silicon debug on ATE and Bench, demonstrating ownership from silicon architecture through silicon production</li>
                </ul>
            </div>
        </div>
    </div>
  </section>
  <section class="pt-5 pb-5 chip semiCont" id="physicaldesg">
    <div class="container">
        <div class="row">
            <div class="col-4">
                <img src="assets/images/industires/Physical_Design_infographic.png" class="w-75" alt="">
              </div>
            <div class="col-8">
                <h2>Physical Design</h2>
                <h6>Motivated team, better design capability</h6>
                <p>Rich and extensive Physical Design (PD) experience has enabled the team to work on multiple successful tape-outs. Expertise with all Industry standard EDA tools, Design Flow and well trained to handle low power, high performance area critical designs.</p>
                <h3 class="semi-header">PD – The Highlights:</h3>
                <ul>
                    <li>Experience in most advanced Process nodes down to 5nm.</li>
                    <li>Integration of Analog & Mixed signal SOC</li>
                    <li>Low Power design</li>
                    <li>High Performance design</li>
                    <li>Expertise on all industry standard EDA tools – Synopsys, Mentor, Cadence, Ansys etc.</li>
                </ul>
            </div>
        </div>
    </div>
  </section>
  <section class="pt-5 pb-5 text-white bg-orange semiCont" id="sivalidation">
    <div class="container">
        <div class="row">
            <div class="col-8">
                <h2>FPGA Emulation and Post SI Validation</h2>
                <h6>Prototyping across multi platforms – we make it possible</h6>
                <p>The team has extensive experience in successfully executing FPGA programs for customers across Networking, Automotive, Industrial and Consumer Electronics domains. The team has delivered 80 + FPGA products with multiple specs involving bit-file generation and validation programs. Expertise areas include high Speed Interconnects, Bus Interfaces, Network Protocols, SoC Interfaces, Audio/Video Applications and Controllers.</p>
                <p>We offer full-service spectrum covering FPGA Design, FPGA Prototyping and Emulation Flows. Pre-Silicon Validation, SW development across different platforms.</p>
              </div>
            <div class="col-4">
              <img src="assets/images/industires/FPGA_Emulation_infographic.png" class="w-75" alt="">
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h3>FPGA Emulation – The Highlights:</h3>
                <h6>Complex design samples we handled including, but not limited to</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <ul>
                    <li>Tools expertise – Synthesis (Synolify Premier/Pro, Altera Tools), PAR & Timing Analysis (Xilinx & Altera Tools), Debug (Chip scope Altera Signal, Logic Analyzer, Oscilloscope, Trace 32)</li>
                </ul>
            </div>
            <div class="col-lg-6">
                <ul>
                    <li>Simulation Tools – Modelsim, NCSIM, Questasim</li>
                </ul>
            </div>
        </div>
    </div>
  </section>
