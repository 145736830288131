<section id="system-eng-banner" class="min-banner text-white overlay">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="banner-text">
            <h1>System Engineering</h1>
            <h4>Our team is passionate about delivering results</h4>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-cover pt-5 design-content pb-5">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <p>MSAII offer wide range of expert employees to handle Interdisciplinary collaboration which essential to the development and networking of complex distributed systems.</p>
                <p>we perform simulations of system behavior and look for changes that result from using different algorithms or parameter variations. Our core competences consist of mechanical, electrical and software engineering.</p>
                <p>Effective Variant handling is required for ever increasing demand of features and variations in hardware and software products. We support you by maintaining traceability and bring in cross-domain experience to enable think ahead.</p>
                <p>We consider safety and security aspects in all stages of development with great emphasis on continuous quality assurance and deliver detailed process documentation to meet industry-specific standards compliance (e.g. ISO 26262, SPICE / ISO 15504.00</p>
                <h1>Our Services</h1>
                <ul>
                    <li>Requirements Engineering</li>
                    <li>System Architectures</li>
                    <li>Modeling and simulation of technical system behavior</li>
                    <li>Platform Development / Variant Management</li>
                    <li>Multi-Standard Conformity</li>
                    <li>System Integration</li>
                    <li>Real-time acquisition of measuring data</li>
                    <li>System Testing</li>
                </ul>
            </div>
        </div>
    </div>
  </section>
  <section class="common-pad industries-main">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 mb-5">
          <div class="indust-wrap">
            <img src="assets/images/embeded-system/embeded-system.jpg" alt="">
            <div class="indust-info">
              <h5>Embedded Software Development</h5>
              <p>The global automotive industry is always in flux, as new models &amp; designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','embedded-software-system']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-5">
          <div class="indust-wrap">
            <img src="assets/images/embeded-system/design-verification.jpg" alt="">
            <div class="indust-info">
              <h5>Design Verification & Validation</h5>
              <p>Digital manufacturing is an integral part of product lifecycle management (PLM)</p>
              <a [routerLink]="['/','design-verification-validation']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
            <div class="indust-wrap">
              <img src="assets/images/embeded-system/modal-based.jpg" alt="">
              <div class="indust-info">
                <h5>Model Based Development</h5>
                <p>A semiconductor material has an electrical conductivity value falling between that of a conductor</p>
                <a [routerLink]="['/','model-based-development']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
        <div class="col-lg-4 mb-5">
          <div class="indust-wrap">
            <img src="assets/images/embeded-system/functional-safety.jpg" alt="">
            <div class="indust-info">
              <h5>Functional Safety</h5>
              <p>The global automotive industry is always in flux, as new models &amp; designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','functional-safety']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-5">
            <div class="indust-wrap">
              <img src="assets/images/embeded-system/cyber-security.jpg" alt="">
              <div class="indust-info">
                <h5>Cyber Security</h5>
                <p>The global automotive industry is always in flux, as new models &amp; designs alter the shape and performance of automobiles.</p>
                <a [routerLink]="['/','cyber-security']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
      </div>
    </div>
  </section>
  <section class="bg-cover industries-main">
    <div class="container">
      <h1 class="pb-2">Industries</h1>
      <div class="row mb-5">
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/auto-1.png" alt="">
            <div class="indust-info">
              <h5>Automotive Industry</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/', 'services','automotive']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/auto-2.png" alt="">
            <div class="indust-info">
              <h5>Digital Manufacturing Industry</h5>
              <p>Digital manufacturing is an integral part of product lifecycle management (PLM)</p>
              <a [routerLink]="['/','services', 'digital-industry']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/industires/semiconductor.png" alt="">
            <div class="indust-info">
              <h5>semiconductor</h5>
              <p>A semiconductor material has an electrical conductivity value falling between that of a conductor</p>
              <a [routerLink]="['/', 'digital-industry', 'semiconductor']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row pb-5">
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/industires/webdevelop.png" alt="">
            <div class="indust-info">
              <h5>Web development</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','services','web-development']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/aec-bim.jpg" alt="">
            <div class="indust-info">
              <h5>AEC/BIM</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','services','epc-bim']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/aerospace.png" alt="">
            <div class="indust-info">
              <h5>Aerospace</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of Aerospace.</p>
              <a [routerLink]="['/','services','aerospace']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/energy.png" alt="">
            <div class="indust-info">
              <h5>Energy</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','services','energy']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/waste-water-treatment.png" alt="">
            <div class="indust-info">
              <h5>Waste Water Treatment</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','services','waste-water-treatment']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        </div>
    </div>
</section>
