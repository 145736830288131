import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-systemc-solutions',
  templateUrl: './systemc-solutions.component.html',
  styleUrls: ['./systemc-solutions.component.css']
})
export class SystemcSolutionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
