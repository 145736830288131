import { Component, HostListener } from '@angular/core';
import * as AOS from 'aos';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'Angular';
  ngOnInit() {
    AOS.init();
  }

  @HostListener('document:load')
  refreshAOS() {
    AOS.refresh();
  }

}
