import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  options = {
    fragment: 'exact',
    matrixParams: 'exact',
    queryParams: 'exact',
    paths: 'exact'
  }

}
$(document).ready(function () {
  $(document).on("keypress", function (event) {
    // If 'alt + g' keys are pressed:
    if (event.which === 169) {
      $('#toggle-grid').toggle();
    }
  });

  $('#toggle-grid').on("click"
    , function () {
      $('.pixel-grid').toggle();
      $('#toggle-grid').toggleClass('orange');
    });
});

var main = function () {
  $('.card').on('mouseenter', function () {
    $(this).find('.card-text').slideDown(300);
  });

  $('.card').on('mouseleave', function (event) {
    $(this).find('.card-text').css({
      'display': 'none'
    });
  });
};

$(document).ready(main);

