import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-competency-development',
  templateUrl: './competency-development.component.html',
  styleUrls: ['./competency-development.component.css']
})
export class CompetencyDevelopmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
