import { Component, AfterViewInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-our-services-carousel',
  templateUrl: './our-services-carousel.component.html',
  styleUrls: ['./our-services-carousel.component.css']
})
export class OurServicesCarouselComponent implements AfterViewInit {
  
  constructor() { }

  ngAfterViewInit(): void {
    $('.carousel .carousel-item').each((index: number, element: Element) => {
      var minPerSlide = 3;
      var next = $(element).next();
      if (!next.length) {
        next = $(element).siblings(':first');
      }
      next.children(':first-child').clone().addClass('center-image').appendTo($(element));
      
      for (var i=0; i<minPerSlide; i++) {
        next = next.next();
        if (!next.length) {
          next = $(element).siblings(':first');
        }
        next.children(':first-child').clone().addClass('center-image').appendTo($(element));
      }
    });

    // Initialize the carousel
    $('#recipeCarousel').carousel({
      interval: 20000
    });

    // Start autoplay
    $('#recipeCarousel').carousel('cycle');
  }
}
