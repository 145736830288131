<section id="lets-connect" class="h-100 pb-5 mb-3 pt-5">

  <div class="container">
    <div class="row ">
      <div class="col-lg-6 pb-4 got-a-project">
        <div class="text-white">
          <div class="mb-3" data-aos="fade-up" data-aos-duration="2000" data-aos-offset="0">
            <span class="mb-5" style="font-family: Lato !important;font-size: 34px;">
              Got a Project?
              <span>Let's chat</span>
            </span>
          </div>
          <p data-aos="fade-up" data-aos-duration="2000" data-aos-offset="0">Tell us everything. Our team is passionate
            about delivering results.</p>
          <p data-aos="fade-up" data-aos-duration="2000" data-aos-offset="0">This means being bold, taking risks and
            going beyond expectations.</p>
          <div data-aos="fade-up" data-aos-duration="2000" data-aos-offset="0">
            <a routerLink="/contact" class="know-more">contact us <i class="fa fa-arrow-right"
                aria-hidden="true"></i></a>
          </div>
        </div>
      </div>

      <div class="col-lg-6 ">
        <section class="meet meet_default section text-white ml-2 ">
          <div class="form-horizontal ">
            <form #f="ngForm" (ngSubmit)="sendMail(f)" novalidate>

              <div class="messages"></div>

              <div class="controls mb-5 pb-5">

                <div class="row" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2000"
                  data-aos-offset="0">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label for="form_name">Full name</label>
                      <input id="form_name" ngModel type="text" name="name" class="form-control"
                        placeholder="How should we address you?" required="required"
                        data-error="Firstname is required.">
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>
                </div>

                <div class="row" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2000"
                  data-aos-offset="0">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label for="form_email">Email *</label>
                      <input id="form_email" ngModel type="email" name="email" class="form-control"
                        placeholder="Lets start a chain" required="required" data-error="Valid email is required.">
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>
                </div>

                <div class="row" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2000"
                  data-aos-offset="0">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label for="form_phone">Phone No</label>
                      <input id="form_phone" ngModel type="tel" name="phone" class="form-control"
                        placeholder="So nothings gets lost in translation.">
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>
                </div>

                <div class="row" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2000"
                  data-aos-offset="0">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label for="form_message">Comments</label>
                      <textarea id="form_message" ngModel name="message" class="form-control"
                        placeholder="Disruption starts here.How can we help?" rows="3" required
                        data-error="Please,leave us a message."></textarea>
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>

                  <div class="col-lg-12" data-aos="fade-up" data-aos-anchor-placement="top-bottom"
                    data-aos-duration="2000" data-aos-offset="0">
                    <button type="submit" style="border:none; z-index:-1" class="submit-btn text-white text-bold">
                      <h5 style="font-size: 1.1rem;">Get our Attention <i class="fa fa-long-arrow-right"></i></h5>
                    </button>
                  </div>
                </div>

              </div>

            </form>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>