import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-analog-mixed-signal-designs',
  templateUrl: './analog-mixed-signal-designs.component.html',
  styleUrls: ['./analog-mixed-signal-designs.component.css']
})
export class AnalogMixedSignalDesignsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
