import { Component } from '@angular/core';

@Component({
  selector: 'app-industry-cards',
  templateUrl: './industry-cards.component.html',
  styleUrls: ['./industry-cards.component.css']
})
export class IndustryCardsComponent {

}
