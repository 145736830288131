<!-- Deprecated Code, not used in navbar -->
<nav class="navbar-expand-sm fixed-top navbar-light py-lg-0">
  <div class="container">
    <a class="navbar-brand page-scroll" href="/">
      <img src="assets/images/msaii-logo.png" id="logo-nav-top" alt="msaii logo" class="logo-text">
    </a>


    <button class="navbar-toggle collapsed" type="button" data-toggle="collapse" data-target="#main_nav"
      aria-controls="main_nav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>


    <div class="collapse navbar-toggler navbar-toggleable-sm justify-content-end"
      [ngClass]="{ collapse: collapsed, 'navbar-collapse': true }" id="main_nav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link nav-text-white" routerLink="/about">
            About
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link nav-text-white" routerLink="/industries">
            Industries
          </a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle nav-text-white" data-toggle="dropdown">
            Services
          </a>
          <ul class="dropdown-menu">
            <li class="dropdown-submenu">
              <a class="dropdown-item" [routerLink]="['services','automotive']">
                Automotive
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" [routerLink]="['automotive', 'virtual-validation']">
                    Virtual Validation
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" [routerLink]="['automotive', 'embedded-systems']">
                    Embedded Systems
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" [routerLink]="['automotive', 'product-life-cycle-management']">
                    Product Life Cycle Management
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" [routerLink]="['automotive', 'tool-die']">
                    Tool &amp; Die
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" [routerLink]="['automotive', '3d-printing']">
                    3D Printing
                  </a>
                </li>
              </ul>
            </li>
            <li class="dropdown-submenu">
              <a class="dropdown-item" [routerLink]="['services', 'digital-industry']">
                Digital Industry & Manufacturing Engineering
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" [routerLink]="['digital-industry', 'ai-ml']">
                    AI / ML
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" [routerLink]="['digital-industry', 'iot']">
                    IOT
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" [routerLink]="['digital-industry','ui-ux']">
                    UI / UX </a>
                </li>
                <li>
                  <a class="dropdown-item" [routerLink]="['digital-industry', 'software-testing']">
                    Software Testing
                  </a>
                </li>
                <li class="dropdown-submenu">
                  <a class="dropdown-item" [routerLink]="['digital-industry', 'semiconductor']">
                    Semicondutor
                  </a>
                  <ul class="dropdown-menu">
                    <li class="dropdown-submenu"><a class="dropdown-item"
                        [routerLink]="['semiconductor', 'vlsi']">VLSI</a>
                      <ul class="dropdown-menu">
                        <li>
                          <a class="dropdown-item" [routerLink]="['semiconductor', 'vlsi']" fragment="amsdesign">
                            Analog & Mixed Signal (AMS) Design
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" [routerLink]="['semiconductor', 'vlsi']" fragment="rtldesign">
                            RTL Design
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" [routerLink]="['semiconductor', 'vlsi']" fragment="designverify">
                            Design Verification
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" [routerLink]="['semiconductor', 'vlsi']" fragment="designdebug">
                            Design For Test And Debug
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" [routerLink]="['semiconductor', 'vlsi']" fragment="physicaldesg">
                            Physical Design
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" [routerLink]="['semiconductor', 'vlsi']" fragment="sivalidation">
                            FPGA Emulation and Post SI Validation
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" [routerLink]="['semiconductor', 'vlsi']" fragment="systemcsol">
                            SystemC Solutions
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <a class="dropdown-item" [routerLink]="['services','web-development']">
                Web Development
              </a>
            </li>
            <li class="dropdown-submenu">
              <a class="dropdown-item" [routerLink]="['services','epc-bim']"> EPC/BIM </a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" [routerLink]="['epc-bim', 'rebar-detailing-services']">Rebar Detailing
                    Services</a></li>
                <li><a class="dropdown-item" [routerLink]="['epc-bim','3d-bim-services']">3D BIM Services (LOD 500)</a>
                </li>
                <li><a class="dropdown-item" [routerLink]="['epc-bim', 'scan-to-bim-services']">Scan to BIM Services</a>
                </li>
                <li><a class="dropdown-item" [routerLink]="['epc-bim', 'detailing-services']">Detailing (2D CAD)
                    Services</a></li>
              </ul>
            </li>
            <li class="dropdown-submenu"><a class="dropdown-item" [routerLink]="['services','aerospace']"> Aerospace
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" [routerLink]="['services', 'aerospace']" fragment='industriies'>
                    Industries
                  </a>
                </li>
              </ul>
            </li>
            <li class="dropdown-submenu"><a class="dropdown-item" [routerLink]="['services','energy']"> Energy </a>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" [routerLink]="['services','energy', 'wind']">
                    Wind Energy
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" [routerLink]="['services','energy', 'solar']">
                    Solar Energy
                  </a>
                </li>
                <!-- <li>
                  <a class="dropdown-item" [routerLink]="['services','energy', 'fossil']">
                    Fossil Energy
                  </a>
                </li> -->
              </ul>
            </li>
            <li class="dropdown-submenu">
              <a class="dropdown-item" [routerLink]="['services','waste-water-treatment']">
                Waste Water Treatment
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item"> <a class="nav-link nav-text-white" routerLink="/careers"> Careers </a> </li>
        <li class="nav-item"> <a class="nav-link nav-text-white" routerLink="/contact"> Contact</a></li>
      </ul>
    </div>
  </div>
</nav>
