<section id="cyber-banner" class="min-banner overlay text-white">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="banner-text">
            <h1>Cyber Security</h1>
            <h4 class="mt-3">Our team is passionate about delivering results</h4>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="pt-5 design-content pb-5">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <p>MSAII combine domain knowledge with our expertise in the areas of embedded and security engineering to prevent external attacks.</p>
                <p>We support you with services, embedded software and tools development for securing embedded systems against cyber-attacks.</p>
                <p>We offer thorough consulting on security issues, from threat analysis to security strategies and architectures to the implementation and testing of all security functions.</p>
                <p>MSAII support in developing end-to-end solutions with the aim of ensuring the highest possible degree of security at all times.</p>
                <p>We conduct a detailed risk analysis for new projects to ensure secure products and services that comply with the regulations.</p>
                <p>With a proactive approach, MSAII considers cyber security into account right from day one in product development, so that potential security loopholes do not arise in the first place.</p>
                <p>Our participation in the IT Security Techniques and Mechanisms group (DIN NIA 01-27-02 AK) allows us to actively advance the field of security.</p>
                <p>The experience of the ecosystem of security-relevant elements, developed with our project experience, allows for a customized design for our customers from the early definition phase of a project.</p>
                <h1>Our Services</h1>
                <ul>
                    <li>Threat and vulnerability analyses</li>
                    <li>Risk assessment</li>
                    <li>Security concepts and Protocol design</li>
                    <li>Secure software architecture</li>
                    <li>Secure hardware design</li>
                    <li>Choice of controls and cryptographic algorithms</li>
                    <li>Development support</li>
                    <li>Secure coding</li>
                    <li>Code reviews based on secure coding guidelines</li>
                    <li>Security testing</li>
                    <li>Protocol validation and verification</li>
                    <li>Fuzzing tests</li>
                    <li>Penetration tests</li>
                </ul>
            </div>
        </div>
    </div>
  </section>
  <section class="common-pad industries-main">
    <div class="container">
      <h1 class="py-2">Other services</h1>
      <div class="row">
        <div class="col-lg-4 mb-5">
          <div class="indust-wrap">
            <img src="assets/images/embeded-system/embeded-system.jpg" alt="">
            <div class="indust-info">
              <h5>Embedded Software Development</h5>
              <p>The global automotive industry is always in flux, as new models &amp; designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','embedded-software-system']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-5">
          <div class="indust-wrap">
            <img src="assets/images/embeded-system/design-verification.jpg" alt="">
            <div class="indust-info">
              <h5>Design Verification & Validation</h5>
              <p>Digital manufacturing is an integral part of product lifecycle management (PLM)</p>
              <a [routerLink]="['/','design-verification-validation']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
            <div class="indust-wrap">
              <img src="assets/images/embeded-system/modal-based.jpg" alt="">
              <div class="indust-info">
                <h5>Model Based Development</h5>
                <p>A semiconductor material has an electrical conductivity value falling between that of a conductor</p>
                <a [routerLink]="['/','model-based-development']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
        <div class="col-lg-4 mb-5">
            <div class="indust-wrap">
                <img src="assets/images/embeded-system/system-engineering.jpg" alt="">
                <div class="indust-info">
                    <h5>Systems Engineering</h5>
                    <p>The global automotive industry is always in flux, as new models &amp; designs alter the shape and performance of automobiles.</p>
                    <a [routerLink]="['/','system-engineering']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
                </div>
            </div>
        </div>
        <div class="col-lg-4 mb-5">
          <div class="indust-wrap">
            <img src="assets/images/embeded-system/functional-safety.jpg" alt="">
            <div class="indust-info">
              <h5>Functional Safety</h5>
              <p>The global automotive industry is always in flux, as new models &amp; designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','functional-safety']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-cover mb-4 industries-main">
    <div class="container">
      <h1 class="pb-2">Industries</h1>
      <div class="row mb-5">
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/auto-1.png" alt="">
            <div class="indust-info">
              <h5>Automotive Industry</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/', 'services','automotive']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/auto-2.png" alt="">
            <div class="indust-info">
              <h5>Digital Manufacturing Industry</h5>
              <p>Digital manufacturing is an integral part of product lifecycle management (PLM)</p>
              <a [routerLink]="['/','services', 'digital-industry']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/industires/semiconductor.png" alt="">
            <div class="indust-info">
              <h5>semiconductor</h5>
              <p>A semiconductor material has an electrical conductivity value falling between that of a conductor</p>
              <a [routerLink]="['/', 'digital-industry', 'semiconductor']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row pb-5">
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/industires/webdevelop.png" alt="">
            <div class="indust-info">
              <h5>Web development</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','services','web-development']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/aec-bim.jpg" alt="">
            <div class="indust-info">
              <h5>AEC/BIM</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','services','epc-bim']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/aerospace.png" alt="">
            <div class="indust-info">
              <h5>Aerospace</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of Aerospace.</p>
              <a [routerLink]="['/','services','aerospace']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/energy.png" alt="">
            <div class="indust-info">
              <h5>Energy</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','services','energy']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/waste-water-treatment.png" alt="">
            <div class="indust-info">
              <h5>Waste Water Treatment</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','services','waste-water-treatment']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        </div>
    </div>
  </section>
