<section id="embeded-system-banner" class="min-banner text-white overlay">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="banner-text">
            <h1 class="py-4">Embedded Systems</h1>
            <h4>Our team is passionate about delivering results</h4>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="design-content pt-5 pb-5">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <p>MSAII Embedded System services provide an independent and objective measure of applications or product's performance and quality. MSAII has expertise in various tools that are required for Software development; Model based development and Verification & Validation of Embedded Systems used across various industry verticals - Life Sciences, Medical, High-tech and Automotive. We have also demonstrated test automation capability by leveraging our expertise in latest technologies.</p>
                <h6>MSAII tools and methodologies for Embedded System service offerings include:</h6>
                <ul>
                    <li>Hardware-in-loop simulation (HIL)/Model-in-Loop(MIL)/Software-in-Loop(SIL)</li>
                    <li>Modeling and Simulation</li>
                    <li>Customized Test RIGS</li>
                    <li>Python/PERL Scripts based Automated Testing</li>
                    <li>C++ based embedded software development</li>
                    <li>UML Based Simulation</li>
                    <li>LabView /Matlab /Simulink</li>
                    <li>Third Party Labs for Environmental Tests</li>
                    <li>Embedded Software Developement</li>
                    <li>Design Verification & Validation</li>
                    <li>Model Based Development</li>
                    <li>Functionality Safety</li>
                    <li>Systems Engineering</li>
                    <li>Cyber Security</li>
                </ul>
            </div>
        </div>
    </div>
  </section>

  <section id="" class="connect-pad industries-main pb-5">
    <div class="container">
      <h1 class="py-2">Other services</h1>
      <div class="row mb-5">
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/embeded-system/embeded-system.jpg" alt="">
            <div class="indust-info">
              <h5>Embedded Software Development</h5>
              <p>The global automotive industry is always in flux, as new models &amp; designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','embedded-software-system']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/embeded-system/design-verification.jpg" alt="">
            <div class="indust-info">
              <h5>Design Verification & Validation</h5>
              <p>Digital manufacturing is an integral part of product lifecycle management (PLM)</p>
              <a [routerLink]="['/','design-verification-validation']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/embeded-system/modal-based.jpg" alt="">
            <div class="indust-info">
              <h5>Model Based Development</h5>
              <p>A semiconductor material has an electrical conductivity value falling between that of a conductor</p>
              <a [routerLink]="['/','model-based-development']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/embeded-system/functional-safety.jpg" alt="">
            <div class="indust-info">
              <h5>Functional Safety</h5>
              <p>The global automotive industry is always in flux, as new models &amp; designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','functional-safety']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/embeded-system/system-engineering.jpg" alt="">
            <div class="indust-info">
              <h5>Systems Engineering</h5>
              <p>The global automotive industry is always in flux, as new models &amp; designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','system-engineering']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
            <div class="indust-wrap">
              <img src="assets/images/embeded-system/cyber-security.jpg" alt="">
              <div class="indust-info">
                <h5>Cyber Security</h5>
                <p>The global automotive industry is always in flux, as new models &amp; designs alter the shape and performance of automobiles.</p>
                <a [routerLink]="['/','cyber-security']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
      </div>
    </div>
  </section>
