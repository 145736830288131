<section id="designs-banner" class="overlay min-banner">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="banner-text">
            <h1 class="py-4">Design</h1>
            <h4>Our team is passionate about delivering results</h4><br>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="pt-5 pb-5 industries-main designs">
    <div class="container">
        <div class="row">
            <div class="col-12 mb-3">
                <div class="design-content">
                    <p>MSAII provides industrial design services for the automotive, consumer, commercial, and healthcare industries. Our industrial design services combined with our engineering expertise allow us to generate design solutions that are innovative and have fast-turnaround development schedules.</p>
                    <p>MSAII can assist in all of the following areas: ideas, concept development, concept refinement, 3-D rendering, and package design. The industrial design process usually begins with conceptual studies and iteration between MSAII and our clients. MSAII will work closely with the client to ensure that the holistic design intent is captured and engineering considerations are incorporated at this early stage of development.</p>
                </div>
            </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h1>MSAII's core competencies include</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-5">
            <ul>
              <li>Production Design and Engineering</li>
              <li>Concept Development</li>
              <li>Feasibility Studies</li>
              <li>Vehicle Packaging</li>
              <li>Target Setting</li>
              <li>System Integration</li>
              <li>Powertrain/Calibration</li>
              <li>Automotive: BIW, Interior, Exterior,Lighting,Closures,Chassis, Seating</li>
              <li>CAD customization </li>
            </ul>
          </div>
        </div>
      <div class="row text-white">
        <div class="col-lg-4 mb-5">
            <div class="indust-wrap">
                <a [routerLink]="['/','product-design']">
                    <img src="assets/images/designs/product-design.png" alt="">
                    <h3>Product design</h3>
                </a>
            </div>
        </div>
        <div class="col-lg-4 mb-5">
            <div class="indust-wrap">
                <a [routerLink]="['/','engineering']">
                    <img src="assets/images/designs/eng.png" alt="">
                    <h3>Engineering</h3>
                </a>
            </div>
        </div>
        <div class="col-lg-4 mb-5">
          <div class="indust-wrap">
            <a [routerLink]="['/','competency-development']">
                <img src="assets/images/designs/competancy.png" alt="">
                <h3>Competancy Development</h3>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

