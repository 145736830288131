import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { $ } from 'protractor';
@Component({
  selector: 'app-vlsi',
  templateUrl: './vlsi.component.html',
  styleUrls: ['./vlsi.component.css']
})

export class VlsiComponent implements OnInit {
  private fragment: string | null = "";
  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;
    })
  }

  ngAfterViewInit(): void {
    try {
      if (this.fragment !== "") {
        document.querySelector('#' + this.fragment)!.scrollIntoView({behavior: 'smooth'});
      }
    } catch (e) {
      console.log(e);
    }
  }

}
