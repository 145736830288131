<nav class="nav">
    <div class="container">
        <div class="flexbox">
            <div class="logo" style="padding: 0;">
                <a routerLink="/" class="logo_icon">
                    <span class="logo_span__one">M</span>
                    <span class="logo_span__two">SAII</span>
                </a>
            </div>
            <div class="navbar_container" #navbar (click)="toggleMenu(navbar, sidebar)">
                <div class="hamburger1"></div>
                <div class="hamburger2"></div>
                <div class="hamburger3"></div>
            </div>
        </div>
        <div id="mainListDiv" #main_nav class="main_list">
            <ul class="navlinks">
                <li><a routerLink="/about-us" class="closenav">About</a></li>
                <li>
                    <a routerLink="/how-we-work" class="closenav dropbtn">How We Work</a>
                    <!-- <div class="dropdown">
                        <div class="dropdown-content">
                            <a routerLink="/how-we-work/team-extension">Managed Team Extension</a>
                            <a routerLink="/how-we-work/agile-squads">Agile Squads</a>
                            <a routerLink="/how-we-work/professional-services">Professional Services</a>
                        </div>
                    </div> -->
                </li>
                <!-- <li><a routerLink="/industries" class="closenav">Industries</a></li> -->
                <li><a routerLink="/news-and-insights" class="closenav">News And Insights</a></li>
                <li><a routerLink="/services" class="closenav">Services</a></li>
                <li><a routerLink="/careers" class="closenav">Careers</a></li>
                <li><a routerLink="/contact-us" class="closenav">Contact</a></li>
            </ul>
        </div>
        <div id="sidebar" #sidebar class="sidebar">
            <ul class="navlinks">
                <li><a (click)="toggleMenu(navbar, sidebar)" routerLink="/" class="closenav">Home</a></li>
                <li><a (click)="toggleMenu(navbar, sidebar)" routerLink="/about-us" class="closenav">About</a></li>
                <li><a (click)="toggleMenu(navbar, sidebar)" routerLink="/how-we-work" class="closenav">How we Work</a></li>
                <li><a (click)="toggleMenu(navbar, sidebar)" routerLink="/industries" class="closenav">Industries</a>
                </li>
                <li><a (click)="toggleMenu(navbar, sidebar)" routerLink="/services" class="closenav">Services</a>
                </li>
                <li><a (click)="toggleMenu(navbar, sidebar)" routerLink="/careers" class="closenav">Careers</a></li>
                <li><a (click)="toggleMenu(navbar, sidebar)" routerLink="/contact-us" class="closenav">Contact</a></li>
            </ul>
        </div>
        <!-- <span class="navTrigger">
            <i></i>
            <i></i>
            <i></i>
        </span> -->
    </div>
</nav>