<section id="industries" class="pt-5 serviceColumn "  >
  <div class="mt-5" style="margin-top: 10%;">
  <div class="container mt-5">

    <div class="row">
      <div class="col-lg-12 mb-2">
        <div class="section-title text-center" data-aos="fade-down" data-aos-duration="2000">
          <h2 class="title_color">Our Services</h2>
          <p>We are providing
            various types of services based on your requirements, budget and timetable. <br>
            Here, you'll discover a diverse a dilerse team of talented people realizing their potential and forging their own paths to success.
            <br>You can ask better questions to get better answers</p>
        </div>
      </div>
    </div>
  </div>
      
  <!-- <div class="container">
      <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="card-wrapper d-flex justify-content-between">
                
                <div class="card industries_content">
                    <div class="industries_image">
                        <img src="assets/images/auto-1.png" alt="stock_illustration" class="image-fluid" />
                    </div>
                    <div class="content">
                        <p class="title">Automotive</p>
                        <p class="description">
                        The global automotive industry is always in flux, as new models & designs alter the shape and
                        performance of automobiles.
                        </p>
                        <div class="button">
                        <a [routerLink]="['/', 'services','automotive']" class="link">Explore</a>
                        </div>
                    </div>
                </div>  
                <div class="card industries_content">
                  <div class="industries_image">
                      <img src="assets/images/aerospace.png" alt="stock_illustration" class="image-fluid" />
                  </div>
                  <div class="content">
                      <p class="title">Aerospace</p>
                      <p class="description">
                          The global automotive industry is always in flux, as new models &
                          designs alter the shape and performance of Aerospace.
                      </p>
                      <div class="button">
                      <a [routerLink]="['/', 'services','aerospace']" class="link">Explore</a>
                      </div>
                  </div>
              </div> 
                <div class="card industries_content">
                  <div class="industries_image">
                      <img src="assets/images/auto-2.png" alt="stock_illustration" class="image-fluid"  />
                  </div>
                  <div class="content">
                      <p class="title">Digital Manufacturing</p>
                      <p class="description">
                          Digital manufacturing is an integral part of product lifecycle management (PLM)
                      </p>
                      <div class="button">
                      <a [routerLink]="['/', 'services','digital-industry']" class="link">Explore</a>
                      </div>
                  </div>
              </div>    
              </div>
            </div>
            <div class="carousel-item">
              <div class="card-wrapper d-flex justify-content-between">
               
                <div class="card industries_content">
                  <div class="industries_image">
                      <img src="assets/images/industires/semiconductor.png" alt="stock_illustration" class="image-fluid" />
                  </div>
                  <div class="content">
                      <p class="title">SEMICONDUCTOR</p>
                      <p class="description">
                          A semiconductor material has an electrical conductivity value falling between that of a conductor.
                      </p>
                      <div class="button">
                      <a [routerLink]="['/', 'digital-industry', 'semiconductor']" class="link">Explore</a>
                      </div>
                  </div>
              </div> 
                <div class="card industries_content" >
                  <div class="industries_image">
                      <img src="assets/images/industires/webdevelop.png" alt="stock_illustration" class="image-fluid" />
                  </div>
                  <div class="content">
                      <p class="title">Web development</p>
                      <p class="description">
                          The global automotive industry is always in flux, as new models & designs alter the shape and
                          performance of automobiles.
                      </p>
                      <div class="button">
                      <a [routerLink]="['/', 'services','web-development']" class="link">Explore</a>
                      </div>
                  </div>
              </div>  
               
                  <div class="card industries_content">
                    <div class="industries_image">
                        <img src="assets/images/energy.png" alt="stock_illustration" class="image-fluid" />
                    </div>
                    <div class="content">
                        <p class="title">Energy</p>
                        <p class="description">
                            The global automotive industry is always in flux, as new models &
                            designs alter the shape and performance of automobiles.
                        </p>
                        <div class="button">
                        <a [routerLink]="['/', 'services','energy']" class="link">Explore</a>
                        </div>
                    </div>
                </div>
              </div>
            </div>
            
          </div>
          
            <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
              <div class="carousel-arrow">
                  <svg xmlns="http://www.w3.org/2000/svg" width="65" height="120" viewBox="0 0 65 120">
                      <path data-name="Path 39640" d="M56.1,0,0,60l56.1,60,8.9-9.3L17.6,60,65,9.3Z" fill="#121619" stroke="#000" stroke-width="5"/>
                      </svg>
              </div>
               
            </a>
          
          
            <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
              <div class="carousel-arrow">
                  <svg xmlns="http://www.w3.org/2000/svg" width="65" height="120" viewBox="0 0 65 120">
                      <path data-name="Path 39641" d="M8.9,0,65,60,8.9,120,0,110.7,47.4,60,0,9.3Z" fill="#121619" stroke="#000" stroke-width="5"/>
                      </svg>
              </div>
               
            </a>
          
        </div>
    </div> -->

    <div class="container " id="corosal">
      <!-- <div class="row"> -->
        
        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner mt-5 mb-2">
              <!-- <div class="carousel-item active">
                <div class="card-wrapper d-flex justify-content-between"> -->
                  <div class="carousel-item active">
                  <div class="col-sm col-md-4 col-lg col-centered" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="0">
                    <div class="mb-3">
                      <a routerLink="/servicespage" fragment="automotive" class="slider">
                        <div class="maintxt" style="background-image:url(/assets/images/auto_banner.png) !important;">
                          Automotive
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                  <div class="carousel-item">
                  <div class="col-sm col-md-4 col-lg col-centered" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="0">
                    <div class="mb-3">
                      <a href="/services/aerospace" class="slider">
                        <div class="maintxt aerospace" style="background-image:url(/assets/images/aerospace.png) !important;">
                          Aerospace
                        </div>
                      </a>
                    </div>
                  </div>
                  </div>
                  <div class="carousel-item">
                  <div class="col-sm col-md-4 col-lg col-centered" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="0">
                    <div class="mb-3">
                      <a href="/servicespage#digital-manufacturing" class="slider">
                        <div class="maintxt digital-manufacturing"
                          style="background-image:url(/assets/images/industires/digital-industry-banner.png) !important;">
                          Digital Manufacturing
                        </div>
                      </a>
                    </div>
                  </div>
                  </div>
                  <!-- <div class="card industries_content">
                      <div class="industries_image">
                          <img src="assets/images/auto-1.png" alt="stock_illustration" class="image-fluid" />
                      </div>
                      <div class="content">
                          <p class="title">Automotive</p>
                          <p class="description">
                          The global automotive industry is always in flux, as new models & designs alter the shape and
                          performance of automobiles.
                          </p>
                          <div class="button">
                          <a [routerLink]="['/', 'services','automotive']" class="link">Explore</a>
                          </div>
                      </div>
                  </div>  
                  <div class="card industries_content">
                    <div class="industries_image">
                        <img src="assets/images/aerospace.png" alt="stock_illustration" class="image-fluid" />
                    </div>
                    <div class="content">
                        <p class="title">Aerospace</p>
                        <p class="description">
                            The global automotive industry is always in flux, as new models &
                            designs alter the shape and performance of Aerospace.
                        </p>
                        <div class="button">
                        <a [routerLink]="['/', 'services','aerospace']" class="link">Explore</a>
                        </div>
                    </div>
                </div> 
                  <div class="card industries_content">
                    <div class="industries_image">
                        <img src="assets/images/auto-2.png" alt="stock_illustration" class="image-fluid"  />
                    </div>
                    <div class="content">
                        <p class="title">Digital Manufacturing</p>
                        <p class="description">
                            Digital manufacturing is an integral part of product lifecycle management (PLM)
                        </p>
                        <div class="button">
                        <a [routerLink]="['/', 'services','digital-industry']" class="link">Explore</a>
                        </div>
                    </div>
                </div>     -->
                <!-- </div>
              </div> -->
              <!-- <div class="carousel-item">
                <div class="card-wrapper d-flex justify-content-between"> -->
                  <div class="carousel-item">
                    <div class="col-sm col-md-4 col-lg col-centered" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="0">
                      <div class="mb-3">
                        <a href="/servicespage#semiconductor" class="slider">
                          <div class="maintxt semiconductor" style="background-image:url(/assets/images/aec-bim.png) !important;">
                            Semiconductor
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  
                  <div class="carousel-item">
                    <div class="col-sm col-md-4 col-lg col-centered" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="0">
                      <div class="mb-3">
                        <a href="/services/web-development" class="slider">
                          <div class="maintxt web-development"
                            style="background-image:url(/assets/images/web-dev/web-dev-banner.jpg) !important;">
                            Web Development
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="col-sm col-md-4 col-lg col-centered" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="0">
                      <div class="mb-3">
                        <a href="/services/energy" class="slider">
                          <div class="maintxt energy" style="background-image:url(/assets/images/energy.png) !important;">
                            Energy
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                <!-- </div>
              </div> -->
              
            </div>
            
              <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                <div class="carousel-arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="120" viewBox="0 0 65 120">
                        <path data-name="Path 39640" d="M56.1,0,0,60l56.1,60,8.9-9.3L17.6,60,65,9.3Z" fill="#121619" stroke="#000" stroke-width="5"/>
                        </svg>
                </div>
                 
              </a>
            
            
              <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <div class="carousel-arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="120" viewBox="0 0 65 120">
                        <path data-name="Path 39641" d="M8.9,0,65,60,8.9,120,0,110.7,47.4,60,0,9.3Z" fill="#121619" stroke="#000" stroke-width="5"/>
                        </svg>
                </div>
                 
              </a>
            
          </div>
      </div>
    </div>
</section>