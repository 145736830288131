<section id="aerospace-banner" class="text-white overlay banner">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="banner-text" style="margin-top: 25%">
          <h1 class="py-2">Aerospace </h1>
          <h2 class="lato-reg">Engineering</h2>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="pt-5 pb-5 design-content">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <h2 class="lato-bold">Our Capabilities</h2>
        <p class="pt-3 pb-3 lato-reg">
          With a proven service lines that extends from concept generation to manufacturing and aftermarket support,
          MSAII is the preferred engineering partner to the Aerospace industry enabling OEM’s and Tier 1 suppliers
          conceptualize and build futuristic aerospace solutions.</p>

        <p class="pt-3 pb-3 lato-reg">
          Our domain expertise spans across commercial, business, military and multi-purpose
          aircrafts in addition to UAVs. From fixed wing to rotary aircraft segments, MSAII has established a long
          term strategic relationship with Aerospace OEMs and Tier-1 suppliers to provide integrated solutions for
          systems and sub-systems – including primary and secondary structures (metallic and composites), electrical
          wiring harness design, aircraft interiors, avionics, manufacturing engineering, technical publication, reports
          on non-conformances, daily repairs and life extension.</p>

        <p class="pt-3 pb-3 lato-reg">
          MSAII aims to continue its outreach in aiding the aerospace industry which is
          looking
          for innovative engineering partners to overcome challenges of digital transformation by deploying digital
          accelerators for each stage of the business lifecycle.
        </p>
      </div>
    </div>
  </div>
</section>
<section class="bg-cover pt-5 design-content pb-5">
  <div class="container">
    <div class="row">
      <div class="col-12 ">
        <h2 class="text-transform text-black font-weight-bold pb-5">Segments We Cover</h2>
        <div class="row text-white">
          <div class="col-3">
            <div class="circle">
              <div class="text-center pt-4"><img src="assets/images/Aero engine_100X100_white.png" />
                <h5>Aero Engine</h5>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="circle">
              <div class="text-center pt-4"><img src="assets/images/Aero structure_ 100X100_white.png" />
                <h5>Aero Structure</h5>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="circle">
              <div class="text-center pt-4"><img src="assets/images/Aero systems_ 100X100_white.png" />
                <h5>Aero Systems</h5>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="circle">
              <div class="text-center pt-4"><img src="assets/images/Avionics_ 100X100_white.png" />
                <h5>Avionics</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="row text-white">
          <div class="col-3">
            <div class="circle">
              <div class="text-center pt-4"><img src="assets/images/AIR TRAFFIC MANAGEMENT_100x100.png" />
                <h5>Air Traffic Management</h5>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="circle">
              <div class="text-center pt-4"><img
                  src="assets/images/INFLIGHT ENTERTAINMENT AND CONNECTIVITY_100x100.png" />
                <h5>Digital Solutions</h5>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="circle">
              <div class="text-center pt-4"><img src="assets/images/UAM.png" />
                <h5>Urban Air Mobility (UAM)</h5>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="circle">
              <div class="text-center pt-4"><img src="assets/images/Defense.png" />
                <h5>Defence</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

