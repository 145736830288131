<section id="industries-banner" class="overlay min-banner text-white">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="banner-text">
            <h1>Silicon Technology</h1>

          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-cover pt-5 pb-5 chip">
    <div class="container">
        <div class="row">
            <div class="col-8">
                <h2>Ideation, innovation & <br> validation – a complete circle</h2>
                <h6>Ideation, innovation & validation – a complete circle</h6>
                <p>Innovators are constantly pushing the limits of design. Manufacturing facilities and OEMs are constantly trying to match their pace. Semiconductor companies are on the lookout for partners capable to work in tandem and end-to-end to realize advanced, production ready chips. As the engineering partner of these innovators, we are pushed to customize our test solutions, develop complex boards and bridge the gap between the simulated world and high volume manufacturing.</p>
            </div>
            <div class="col-4">
              <img src="assets/images/industires/Chip_Solutions_IMG.png" class="w-75" alt="">
            </div>
        </div>
    </div>
  </section>
  <section class="bg-cover pt-5 pb-5 bg-light chip-capabilities">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center mb-5">
                <h2 class="capitalize">Our Capabilities</h2>
                <span>Mapping our Chip competence</span>
            </div>
        </div>
        <div class="row text-center">
            <div class="col">
                <div class="chips-icon">
                    <img src="assets/images/industires/vlsi-design.png" alt="">
                    <h5>VLSI Design</h5>
                </div>
            </div>
            <div class="col">
                <div class="chips-icon">
                    <img src="assets/images/industires/test-eng.png" alt="">
                    <h5>Test Engineering</h5>
                </div>
            </div>
            <div class="col">
                <div class="chips-icon">
                    <img src="assets/images/industires/product-eng.png" alt="">
                    <h5>Product Engineering</h5>
                </div>
            </div>
        </div>
    </div>
  </section>
  <section id="vlsi-design" class="pt-5 pb-5 chip">
    <div class="container">
        <div class="row">
            <div class="col-8">
                <h2>VLSI Design</h2>
                <h6>Driving the semiconductor design arena – Analog, Digital & Mixed Signal</h6>
                <p>Our design excellence is an enabler to support all phases of the VLSI product life-cycle from conceptualization to product engineering across multiple verticals including Automotive, Computing and Consumer Electronics.</p>
                <p>have a well established design flow, excellent process flow and quality compliance system with customized business models that can support customer needs.</p>
                <a [routerLink]="['/', 'semiconductor', 'vlsi']" class="know-more">Know MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
              </div>
            <div class="col-4">
              <img src="assets/images/industires/msaii_C_Design_infographic.png" class="w-75" alt="">
            </div>
        </div>
    </div>
  </section>
  <section id="test-engineering" class="pt-5 pb-5 text-white bg-orange chip">
    <div class="container">
        <div class="row">
            <div class="col-4">
                <img src="assets/images/industires/msaii_Test_Engineering_infographic.png" class="w-75" alt="">
              </div>
            <div class="col-8">
                <h2>Test Engineering</h2>
                <h6>The apex of testing platform and infrastructure</h6>
                <p>Supporting major semiconductor companies across five continents for their semiconductor testing and associated engineering solutions, we have developed a competency across multiple testing platforms.</p>
                <p>addition, our infrastructure hosts varied setups that meet your every testing need, from bench characterization, reliability testing to failure analysis.</p>
            </div>
        </div>
    </div>
  </section>
  <section id="product-engineering" class="pt-5 pb-5 chip">
    <div class="container">
        <div class="row">
            <div class="col-8">
                <h2>Product Engineering</h2>
                <h6>Transforming Chips to the next level</h6>
                <p>Our depth of product engineering and our familiarity with silicon technology enables us to take on complex engineering projects. Right from the design, through the pre-silicon and post-silicon phases, we bring various types of analysis and test measures concerning the wafer, the dashboard, product qualification, etc.</p>
                <p>These help us to absorb and analyze the enormous amount of data from manufacturing to make meaningful changes to improve yields, reduce test time and increase overall efficiency.</p>
              </div>
            <div class="col-4">
              <img src="assets/images/industires/msaii_Product_Engineering_infographic.png" class="w-75" alt="">
            </div>
        </div>
    </div>
  </section>
  <section id="core-services" class="common-pad pt-5 pb-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="section-title mb-5">
            <h1>Digital Industry Services</h1>
            <p>MSAII is a modern engineering company, dedicated to providing high quality design and product development
              services for the engineering industry.</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="core-services-wrap">
            <a [routerLink]="['/','digital-industry', 'ai-ml']">
              <img src="assets/images/al-ml.png" class="img-fluid" alt="">
              <div class="lato-bold">AI / ML</div>
            </a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="core-services-wrap">
            <a [routerLink]="['/','digital-industry', 'iot']">
              <img src="assets/images/iot.png" alt="iot">
              <div class="lato-bold">IOT</div>
            </a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="core-services-wrap">
            <a [routerLink]="['/','digital-industry', 'ui-ux']">
              <img src="assets/images/ui-ux-3.jpg" alt="ui-ux">
              <div class="lato-bold">UI / UX</div>
            </a>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-4">
          <div class="core-services-wrap">
            <a [routerLink]="['/', 'digital-industry','software-testing']">
              <img src="assets/images/testing/software-testing.png" alt="software-testing">
              <div class="lato-bold">Software Testing</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
