<section id="embeded-system-banner" class="min-banner text-white overlay">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="banner-text">
            <h1>Embedded</h1><br><h2>Software  Development</h2>
            <h4>Our team is passionate about delivering results</h4>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-cover pt-5 design-content pb-5">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <p>Software development in all phases of the agile software development process, which also takes account of requirements engineering, architecture, design, documentation and implementation.</p>
                <h6>Our Services</h6>
                <ul>
                    <li>Software development using C/C++ in Linux and Real Time Operating Systems (RTOS) and architectures (QNX, FreeRTOS, RT Linux etc.)</li>
                    <li>Design complex platform software (low level and middleware)</li>
                    <li>Delivery of System Models, Design Patterns and System Architecture of Embedded Systems</li>
                    <li>Adaptation to structured software development process (code reviews, version control, unit test development, on-hardware test & debugging)</li>
                    <li>CI/CD development practices which included version control, build, deploy on embedded targets</li>
                    <li>Development of embedded software for automotive systems according to AUTOSAR standard</li>
                    <li>AUTOSAR Adaptive Platform and AUTOSAR Classic Expertise</li>
                    <li>Integrate AUTOSAR SW Components from OEM into our leading-edge MultiCore ECUs.</li>
                    <li>Configuration and integration of AUTOSAR Basic Software (BSW) Modules and communication stack</li>
                    <li>AUTOSAR architecture and communication protocols (CAN, FlexRay,Ethernet)</li>
                    <li>Expertise Davinci developer, Davinci Configurator and Vector CANoe</li>
                    <li>Embedded Software Developement</li>
                    <li>Design Verification & Validation</li>
                    <li>Model Based Development</li>
                    <li>Functionality Safety</li>
                    <li>Systems Engineering</li>
                    <li>Cyber Security</li>
                </ul>
            </div>
        </div>
    </div>
  </section>
  <section id="" class=" common-pad industries-main">
    <div class="container">
      <h1 class="py-2">Other services</h1>
      <div class="row">
        <div class="col-lg-4 mb-5">
          <div class="indust-wrap">
            <img src="assets/images/embeded-system/design-verification.jpg" alt="">
            <div class="indust-info">
              <h5>Design Verification & Validation</h5>
              <p>Digital manufacturing is an integral part of product lifecycle management (PLM)</p>
              <a [routerLink]="['/','design-verification-validation']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-5">
          <div class="indust-wrap">
            <img src="assets/images/embeded-system/modal-based.jpg" alt="">
            <div class="indust-info">
              <h5>Model Based Development</h5>
              <p>A semiconductor material has an electrical conductivity value falling between that of a conductor</p>
              <a [routerLink]="['/','model-based-development']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-5">
          <div class="indust-wrap">
            <img src="assets/images/embeded-system/functional-safety.jpg" alt="">
            <div class="indust-info">
              <h5>Functional Safety</h5>
              <p>The global automotive industry is always in flux, as new models &amp; designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','functional-safety']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-5">
          <div class="indust-wrap">
            <img src="assets/images/embeded-system/system-engineering.jpg" alt="">
            <div class="indust-info">
              <h5>Systems Engineering</h5>
              <p>The global automotive industry is always in flux, as new models &amp; designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','system-engineering']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-5">
            <div class="indust-wrap">
              <img src="assets/images/embeded-system/cyber-security.jpg" alt="">
              <div class="indust-info">
                <h5>Cyber Security</h5>
                <p>The global automotive industry is always in flux, as new models &amp; designs alter the shape and performance of automobiles.</p>
                <a [routerLink]="['/','cyber-security']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
      </div>
    </div>
    <section class="bg-cover mb-4 industries-main">
      <div class="container">
        <h1 class="pb-2">Industries</h1>
        <div class="row mb-5">
          <div class="col-lg-4">
            <div class="indust-wrap">
              <img src="assets/images/auto-1.png" alt="">
              <div class="indust-info">
                <h5>Automotive Industry</h5>
                <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
                <a [routerLink]="['/', 'services','automotive']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="indust-wrap">
              <img src="assets/images/auto-2.png" alt="">
              <div class="indust-info">
                <h5>Digital Manufacturing Industry</h5>
                <p>Digital manufacturing is an integral part of product lifecycle management (PLM)</p>
                <a [routerLink]="['/','services', 'digital-industry']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="indust-wrap">
              <img src="assets/images/industires/semiconductor.png" alt="">
              <div class="indust-info">
                <h5>semiconductor</h5>
                <p>A semiconductor material has an electrical conductivity value falling between that of a conductor</p>
                <a [routerLink]="['/', 'digital-industry', 'semiconductor']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
        </div>
        <div class="row pb-5">
          <div class="col-lg-4">
            <div class="indust-wrap">
              <img src="assets/images/industires/webdevelop.png" alt="">
              <div class="indust-info">
                <h5>Web development</h5>
                <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
                <a [routerLink]="['/','services','web-development']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="indust-wrap">
              <img src="assets/images/aec-bim.jpg" alt="">
              <div class="indust-info">
                <h5>AEC/BIM</h5>
                <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
                <a [routerLink]="['/','services','epc-bim']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="indust-wrap">
              <img src="assets/images/aerospace.png" alt="">
              <div class="indust-info">
                <h5>Aerospace</h5>
                <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of Aerospace.</p>
                <a [routerLink]="['/','services','aerospace']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <div class="indust-wrap">
              <img src="assets/images/energy.png" alt="">
              <div class="indust-info">
                <h5>Energy</h5>
                <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
                <a [routerLink]="['/','services','energy']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="indust-wrap">
              <img src="assets/images/waste-water-treatment.png" alt="">
              <div class="indust-info">
                <h5>Waste Water Treatment</h5>
                <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
                <a [routerLink]="['/','services','waste-water-treatment']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
          </div>
      </div>
    </section>
