<section id="modal-based-banner" class="min-banner text-white overlay">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="banner-text">
            <h1>Functional Safety</h1>
            <h4>Our team is passionate about delivering results</h4>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="pad-50 design-content pb-5 pt-5">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <p>We support across various industries – from chip design to the entire assessment of a Product Development to deliver customers tailored solutions to provide seamless concepts in functional safety</p>
                <p>Functional safety has become a critically important issue across all areas of industry with interactions and dependencies between functions and subsystems – this is especially crucial in complex and distributed systems.</p>
                <p>With the expanding digitalization and automation of life and industry also new challenges and requirements for functional safety technology arise. Based on your existing architecture, we identify threats, assess pitfalls, derive safety objectives, and develop safety concepts.</p>
                <p>Our experience in safety management and the development of safety-critical functions has been proven across a variety of industries, from concept to validation.</p>
                <p>Integration into the existing tool environment is an important aspect of today's requirements management. MSAI enables all the possibilities to seamlessly integrate into your tool chain.</p>
                <p>For clients who regularly exchanging requirements documents with their business partners, MSAI supports various Industry standards and offers import and export possibilities for a large range of other formats.</p>
                <h1>Our Services</h1>
                <ul>
                    <li>Safety Management and Engineering (System, Hardware and Software Design)</li>
                    <li>HARA (Hazard and Risk Analysis)</li>
                    <li>Functional (FSC) and Technical Safety Concepts (TSC)</li>
                    <li>Implementation of Safety Mechanisms in Hardware and Software</li>
                    <li>Safety Analyses (FTA, FMEDA, FMEA)</li>
                    <li>Tool Development and Qualification to Ensure Traceability</li>
                    <li>Establish Processes and Methods to achieve and maintain compliance</li>
                    <li>Propel safety culture across your organization</li>
                </ul>
            </div>
        </div>
    </div>
  </section>
  <section class="common-pad industries-main">
    <div class="container">
      <h1 class="py-2">Other services</h1>
      <div class="row">
        <div class="col-lg-4 mb-5">
          <div class="indust-wrap">
            <img src="assets/images/embeded-system/embeded-system.jpg" alt="">
            <div class="indust-info">
              <h5>Embedded Software Development</h5>
              <p>The global automotive industry is always in flux, as new models &amp; designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','embedded-software-system']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-5">
          <div class="indust-wrap">
            <img src="assets/images/embeded-system/design-verification.jpg" alt="">
            <div class="indust-info">
              <h5>Design Verification & Validation</h5>
              <p>Digital manufacturing is an integral part of product lifecycle management (PLM)</p>
              <a [routerLink]="['/','design-verification-validation']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
            <div class="indust-wrap">
              <img src="assets/images/embeded-system/modal-based.jpg" alt="">
              <div class="indust-info">
                <h5>Model Based Development</h5>
                <p>A semiconductor material has an electrical conductivity value falling between that of a conductor</p>
                <a [routerLink]="['/','model-based-development']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
        <div class="col-lg-4 mb-5">
          <div class="indust-wrap">
            <img src="assets/images/embeded-system/system-engineering.jpg" alt="">
            <div class="indust-info">
              <h5>Systems Engineering</h5>
              <p>The global automotive industry is always in flux, as new models &amp; designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','system-engineering']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-5">
            <div class="indust-wrap">
              <img src="assets/images/embeded-system/cyber-security.jpg" alt="">
              <div class="indust-info">
                <h5>Cyber Security</h5>
                <p>The global automotive industry is always in flux, as new models &amp; designs alter the shape and performance of automobiles.</p>
                <a [routerLink]="['/','cyber-security']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
      </div>
    </div>
  </section>
  <section class="bg-cover mb-4 industries-main">
    <div class="container">
      <h1 class="pb-2">Industries</h1>
      <div class="row mb-5">
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/auto-1.png" alt="">
            <div class="indust-info">
              <h5>Automotive Industry</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/', 'services','automotive']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/auto-2.png" alt="">
            <div class="indust-info">
              <h5>Digital Manufacturing Industry</h5>
              <p>Digital manufacturing is an integral part of product lifecycle management (PLM)</p>
              <a [routerLink]="['/','services', 'digital-industry']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/industires/semiconductor.png" alt="">
            <div class="indust-info">
              <h5>semiconductor</h5>
              <p>A semiconductor material has an electrical conductivity value falling between that of a conductor</p>
              <a [routerLink]="['/', 'digital-industry', 'semiconductor']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row pb-5">
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/industires/webdevelop.png" alt="">
            <div class="indust-info">
              <h5>Web development</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','services','web-development']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/aec-bim.jpg" alt="">
            <div class="indust-info">
              <h5>AEC/BIM</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','services','epc-bim']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/aerospace.png" alt="">
            <div class="indust-info">
              <h5>Aerospace</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of Aerospace.</p>
              <a [routerLink]="['/','services','aerospace']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/energy.png" alt="">
            <div class="indust-info">
              <h5>Energy</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','services','energy']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/waste-water-treatment.png" alt="">
            <div class="indust-info">
              <h5>Waste Water Treatment</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','services','waste-water-treatment']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        </div>
    </div>
  </section>
