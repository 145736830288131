import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-aerospace',
  templateUrl: './aerospace.component.html',
  styleUrls: ['./aerospace.component.css']
})


//industriies
export class AerospaceComponent implements OnInit {
  private fragment: any = "";
  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;
    })
  }

  ngAfterViewInit(): void {
    try {
      if (this.fragment !== "") {
        document.querySelector('#' + this.fragment)!.scrollIntoView();
      }
    } catch (e) {
      console.log(e);
    }
  }

}
