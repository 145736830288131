import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-model-based-development',
  templateUrl: './model-based-development.component.html',
  styleUrls: ['./model-based-development.component.css']
})
export class ModelBasedDevelopmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
