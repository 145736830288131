import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-digital-ind-manu-engg',
  templateUrl: './digital-ind-manu-engg.component.html',
  styleUrls: ['./digital-ind-manu-engg.component.css']
})
export class DigitalIndManuEnggComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
