<swiper [config]="config" class="mySwiper fade fadeOut">

  
    <ng-template swiperSlide class="animated-banner justify-content-center">
      <img class="home_image1" src="/assets/homeimages/homepagebanner-1.png"/>
      <div class="home-text1">
        <p class="animateText home_h1" data-aos="fade-up" data-aos-duration="2000">
          <span class="upper-heading">we create powerful strategies</span><br> <span class="lower-heading"> practical solutions</span>
        </p>
        <br>

        <div class="home-text-sub-1" style="color: white; margin-top: -25px;" data-aos="fade-up" data-aos-duration="3000"
          data-aos-anchor-placement="bottom-bottom">
        <strong style="display: inline;">MSAII</strong><span style="display: inline;"> is a result-driven firm. A masterful team that executes every engagement <br>
          with purpose and focus, on measurable, actionable results.</span>
       </div>

        <h5 style="line-height: 20px;color: white;" class="ht0" data-aos="fade-up" data-aos-duration="3000"
          data-aos-anchor-placement="bottom-bottom">
          <a class="nav-link" routerLink="/about-us" class="know-more">
            Know more
            <i class="fa fa-arrow-right" aria-hidden="true"></i>
          </a>
        </h5>
      </div>
      <!-- Sliding Lines -->
      <div class="banner-line banner-line--one"></div>
      <div class="banner-line banner-line--two"></div>
      <div class="banner-line banner-line--three"></div>
      <div class="banner-line banner-line--four"></div>
    </ng-template>
    
    <ng-template swiperSlide>
      <img class="home_image2" src="/assets/homeimages/homepagebanner-2.png">
      <div class="home-text2 b1">
        <p class="animateText home_h1" data-aos="fade-up" data-aos-duration="2000"
          data-aos-anchor-placement="top-bottom"><strong>BIG</strong> IDEAS</p>
  
        <p class="animateText home_h1" data-aos="fade-up" data-aos-duration="2000"
          data-aos-anchor-placement="top-bottom"><strong>CREATIVE</strong> PEOPLE</p>
  
        <p class="animateText home_h1 pb-3" data-aos="fade-up" data-aos-duration="2000"
          data-aos-anchor-placement="top-bottom"><strong>NEW</strong> TECHNOLOGY.</p>
  
        <h5 style="line-height: 12px;color: white;" class="ht0" data-aos="fade-up" data-aos-duration="3000"
          data-aos-anchor-placement="top-bottom">
          <a class="nav-link" routerLink="/about-us" class="know-more know-second">
            Know more
            <i class="fa fa-arrow-right" aria-hidden="true"></i>
          </a>
        </h5>
      </div>
    </ng-template>

    

    <ng-template swiperSlide class="animated-banner">
      <img class="home_image2" src="/assets/homeimages/homepagebanner-3.png">
      <!-- <div class="home-text2 b1"> -->
        <div class="home-text1">
        
          <!-- <h1 class="animateText home_h1" data-aos="fade-up" data-aos-duration="2000"
          data-aos-anchor-placement="top-bottom">BIG IDEAS,<br> CREATIVE PEOPLE,<br>NEW TECHNOLOGY</h1> -->
  
          <p class="animateText home_h1" data-aos="fade-up" data-aos-duration="2000" data-aos-anchor-placement="top-bottom">
            <!-- BIG IDEAS -->
            <strong>AI/ML,</strong> Analytics, <strong> Data</strong> Engineering,
            <span><strong>Cloud</strong> infrastructure</span>
          </p>
  
        <!-- <h1 class="animateText home_h1" data-aos="fade-up" data-aos-duration="2000"
          data-aos-anchor-placement="top-bottom">CREATIVE PEOPLE,</h1> -->
  
        <!-- <h1 class="animateText home_h1 pb-3" data-aos="fade-up" data-aos-duration="2000"
          data-aos-anchor-placement="top-bottom">NEW TECHNOLOGY.</h1> -->
  
          <h5 style="color: white;margin-top: -5px;" data-aos="fade-up" data-aos-duration="3000"
          data-aos-anchor-placement="bottom-bottom">
          Descriptive cognitive analytics and data scooping to warehousing. 
          <span>Cloud-based infrastructure to cloud native application.</span>
        </h5>
  
        <h5 style="line-height: 12px;color: white;" class="ht0" data-aos="fade-up" data-aos-duration="3000"
          data-aos-anchor-placement="top-bottom">
          <a class="nav-link" routerLink="/about-us" class="know-more know-second">
            Know more
            <i class="fa fa-arrow-right" aria-hidden="true"></i>
          </a>
        </h5>
      </div>
      <!-- Sliding Lines -->
      <div class="banner-line banner-line--one"></div>
      <div class="banner-line banner-line--two"></div>
      <div class="banner-line banner-line--three"></div>
      <div class="banner-line banner-line--four"></div>
    </ng-template>
    
  </swiper>