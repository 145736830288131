<section id="compotancy-banner" class="min-banner text-white overlay">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="banner-text">
            <h1>Competency</h1>
            <h2>development <span  class="border-right pr-2"></span>  support</h2>
            <p>Our team is passionate about delivering results</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="pt-5 pb-5 design-content">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h6>Skills those are essential for a successful management process:</h6>
                <p>Train our engineers with a good balance of technical, human, and business skills in order to work our hardest and meet our customers’ high expectations. Each of our branches in USA, Canada, and India has a support system in place for engineers in each region, providing detailed support through group leaders.</p>
                <img src="assets/images/designs/service_1.png" class="img-fluid b-radius w-50">
                <div class="pt-5">
                <h1>Training and Development</h1>
                <p>Employee training and development refers to the continued efforts of a company to boost the performance of its employees. Organisations intend to train and develop employees by using an array of Learning methods and programs. Management skills can be defined as certain attributes or abilities that an executive should possess in order to fulfill specific tasks in an organisation.</p>
                <p>Our personalized training courses help organisations to understand the technologies along with their implementation and management systems, and offering courses at the preliminary, intermediate, and advanced levels. Each courses managed by experienced trainers provides lots of tips, techniques, and samples to enhance trainee’s skills. The Corporate professionals are trained as to how to, function, improve decision making abilities, enhance customer service, face challenges, resolve conflict and take advantage of opportunities and how to adapt to a continuously changing future.</p>
                <p>On the contrary, a developmental program centers on acquiring broader skills that can be applied in a wide range of situations. They include skills such as decision-making, communication, and leadership.</p>
                <p>Benefits of Employee Training and Development</p>
                <ul>
                    <li>Addressing the Gap and use effectively</li>
                    <li>Improved the performance of employees</li>
                    <li>Boosts the company profile and reputation</li>
                    <li>Innovation & System</li>
                </ul>
            </div>
            </div>
        </div>
    </div>
  </section>
  <section class="industries-main designs">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 mb-5">
            <div class="indust-wrap">
                <a [routerLink]="['/','product-design']">
                    <img src="assets/images/designs/product-design.png" alt="">
                    <h3>Product design</h3>
                </a>
            </div>
        </div>
        <div class="col-lg-4 mb-5">
            <div class="indust-wrap">
                <a [routerLink]="['/','engineering']">
                    <img src="assets/images/designs/eng.png" alt="">
                    <h3>Engineering</h3>
                </a>
            </div>
        </div>
      </div>
    </div>
  </section>