import { Component, HostListener, OnInit } from '@angular/core';
import * as $ from 'jquery'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})


export class HeaderComponent implements OnInit {
  scroll: boolean = false;
  collapsed = true;
  constructor() { }

  ngOnInit(): void {
  }

  toggleMenu(currentClass: HTMLDivElement, sidebar: HTMLDivElement) {
    currentClass.classList.toggle("mobile_navbar_container");
    sidebar.classList.toggle("active");
  }

  ngAfterViewInit(): void {
    // const navTrigger = document.getElementsByClassName("navTrigger")[0]
    // var navCloseTrigger0:any = document.getElementsByClassName("closenav")[0]
    // var navCloseTrigger1:any = document.getElementsByClassName("closenav")[1]
    // var navCloseTrigger2:any = document.getElementsByClassName("closenav")[2]
    // var navCloseTrigger3:any = document.getElementsByClassName("closenav")[3]
    // var navCloseTrigger4:any = document.getElementsByClassName("closenav")[4]
    // var navCloseTrigger5:any = document.getElementsByClassName("closenav")[5]

    // if (navTrigger === undefined) {
    //   console.log("Header Initalized too early")
    //   console.clear()
    // } else {
    //   navTrigger.addEventListener("click", () => {
    //     if (navTrigger.classList.contains("active")) {
    //       navTrigger.classList.remove("active")
    //     } else {
    //       navTrigger.classList.add("active")
    //     }
    //     $("#mainListDiv").toggleClass("show_list");
    //     $("#mainListDiv").fadeIn();
    //   }, true)
    // }

    // $(window).scroll(function () {
    //   if ($(document).scrollTop()!! > 50) {
    //     if (window.screen.width > 780) {
    //       $(".navlinks li a").css("color", "#2E3A4D")
    //       $('.nav').addClass('affix');
    //     } else {
    //       $(".navTrigger i").css("backgroundColor", "#111")
    //       $(".nav").css("backgroundColor", "#fff")
    //     }
    //     $(".logo a").css("color", "#00b8d7")
    //   } else {
    //     if (window.screen.width > 780) {
    //       $(".navlinks li a").css("color", "#fff")
    //       $(".navlinks li a:hover").css("color", "rgba(0, 183, 213, 0.8)")
    //       $('.nav').removeClass('affix');

    //     } else {
    //       $(".navTrigger i").css("backgroundColor", "#fff")
    //       $(".nav").css("backgroundColor", "transparent")
    //     }
    //     $(".logo a").css("color", "#fff")
    //   }
    // });
    // navCloseTrigger0.addEventListener("click", () => {
    //   $("#mainListDiv").toggleClass("show_list");
    //   navTrigger.classList.remove("active")
    //   $("#mainListDiv").fadeOut();
    // }, true)
    // navCloseTrigger1.addEventListener("click", () => {
    //   $("#mainListDiv").toggleClass("show_list");
    //   navTrigger.classList.remove("active")
    //   $("#mainListDiv").fadeOut();
    // }, true)
    // navCloseTrigger2.addEventListener("click", () => {
    //   $("#mainListDiv").toggleClass("show_list");
    //   navTrigger.classList.remove("active")
    //   $("#mainListDiv").fadeOut();
    // }, true)
    // navCloseTrigger3.addEventListener("click", () => {
    //   $("#mainListDiv").toggleClass("show_list");
    //   navTrigger.classList.remove("active")
    //   $("#mainListDiv").fadeOut();
    // }, true)
    // navCloseTrigger4.addEventListener("click", () => {
    //   $("#mainListDiv").toggleClass("show_list");
    //   navTrigger.classList.remove("active")
    //   $("#mainListDiv").fadeOut();
    // }, true)
    // navCloseTrigger5.addEventListener("click", () => {
    //   $("#mainListDiv").toggleClass("show_list");
    //   navTrigger.classList.remove("active")
    //   $("#mainListDiv").fadeOut();
    // }, true)
  }

  // $(window).scroll(function () {
  //   if ($(document).scrollTop()!! > 50) {
  //     if (window.screen.width > 780) {
  //       $(".navlinks li a").css("color", "#2E3A4D")
  //       $('.nav').addClass('affix');
  //     } else {
  //       $(".navTrigger i").css("backgroundColor", "#111")
  //       $(".nav").css("backgroundColor", "#fff")
  //     }
  //     $(".logo a").css("color", "#00b8d7")
  //   } else {
  //     if (window.screen.width > 780) {
  //       $(".navlinks li a").css("color", "#fff")
  //       $(".navlinks li a:hover").css("color", "rgba(0, 183, 213, 0.8)")
  //       $('.nav').removeClass('affix');

  //     } else {
  //       $(".navTrigger i").css("backgroundColor", "#fff")
  //       $(".nav").css("backgroundColor", "transparent")
  //     }
  //     $(".logo a").css("color", "#fff")
  //   }
  // });

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    var navLink = document.querySelector(".main_list .navlinks");
    var nav = document.querySelector(".nav");
    var logo = document.querySelector(".nav .container .logo a");

    // IF SCROLLED DOWN
    if (window.scrollY > 50) {

      logo?.classList.add("blue__clr")
      nav?.classList.add("affix")

      // IF IN DESKTOP MODE
      if (window.screen.width > 780) {
        navLink?.classList.add("black__clr")
      }
      // IF IN MOBILE MODE
      else {
        // nav?.classList.add("white__bg")
      }

    }
    // IF NOT SCROLLED DOWN
    else {

      logo?.classList.remove("blue__clr")
      nav?.classList.remove("affix")

      // IF IN DESKTOP MODE
      if (window.screen.width > 780) {
        navLink?.classList.remove("black__clr")
      }
      // IF IN MOBILE MODE
      else {
        // nav?.classList.remove("white__bg")
      }
    }
  }
}
