<section id="digital-industries-banner" class="min-banner text-white overlay pb-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="banner-text">
            <span><h1>DIGITAL INDUSTRY <span  class="border-right pr-2"></span></h1></span> <br><h2 class="strong"> MANUFACTURING ENGINEERING</h2>
            <h4 class="pt-4">Enhancing technology with improved digital strategies</h4><br>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="getintouch" class="common-pad pb-5 pt-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="ourservices-text">
            <h1>Ushering in a new era of Digital Engineering and Manufacturing</h1>
            <p>Exponential technology changes such as the Internet of Things, Industry 4.0, Pervasive Intelligence, “software defined,” and the physical and digital integration have shifted expectations regarding product functionality. Today, the way products and the services around them are designed, manufactured, and consumed is driving the evolution of business models and redefining value chains</p>
            <h1>Solutions for the physical and digital worlds</h1>
            <p>MSAII’s Digital Engineering and Manufacturing Services brings together deep domain expertise to lead the convergence of the physical and digital worlds through technology, engineering, and manufacturing expertise. Consulting, technology, and assets-led solutions enable global companies to unlock the true potential of their product portfolios and manufacturing efficiencies.</p>
            <p>MSAII offers a highly differentiated, comprehensive portfolio of services and solutions to meet the needs of convergence of disruptive technologies. Through global centers of expertise, we engage with you to build successful products, manufacturing operations, and services in an increasingly connected world. Our expertise in the core solution interventions is made scalable through our Rightshore® delivery model.</p>
            <p>Our experts work with your teams to identify opportunities to innovate and develop tailor-made solutions for your requirements. We believe that this human-centered approach to technology is what makes the difference for your business. We call this the MSAII Effect.</p>
            <p>LEADING the convergence of the physical and digital worlds through technology, engineering, and manufacturing expertise</p>
            <p>MSAII has successfully delivered results across sectors. Our portfolio of offers helps shape industry trends including autonomous aircraft systems, connected vehicles, sustainable energy, intelligent healthcare, smart manufacturing, and hybrid infrastructure. Every aspect of product engineering, continued development, and manufacturing demands enhanced scrutiny and creative disruption</p>
            </div>
        </div>
      </div>
    </div>
  </section>
  <section id="core-services" class="common-pad pt-5 pb-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="section-title mb-5">
            <h1>Digital Industry Services</h1>
            <p>MSAII is a modern engineering company, dedicated to providing high quality design and product development
              services for the engineering industry.</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="core-services-wrap">
            <a [routerLink]="['/','digital-industry', 'ai-ml']">
              <img src="assets/images/al-ml.png" class="img-fluid" alt="">
              <div class="lato-bold">AI / ML</div>
            </a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="core-services-wrap">
            <a [routerLink]="['/','digital-industry', 'iot']">
              <img src="assets/images/iot.png" alt="iot">
              <div class="lato-bold">IOT</div>
            </a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="core-services-wrap">
            <a [routerLink]="['/','digital-industry', 'ui-ux']">
              <img src="assets/images/ui-ux-3.jpg" alt="ui-ux">
              <div class="lato-bold">UI / UX</div>
            </a>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-4">
          <div class="core-services-wrap">
            <a [routerLink]="['/', 'digital-industry','software-testing']">
              <img src="assets/images/testing/software-testing.png" alt="software-testing">
              <div class="lato-bold">Software Testing</div>
            </a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="core-services-wrap">
            <a [routerLink]="['/', 'digital-industry', 'semiconductor']">
              <img src="assets/images/industires/digital-banner.png" alt="digital banner">
              <div class="lato-bold">Semiconductor</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
