<section id="testing-banner" class="min-banner overlay">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="banner-text">
          <h1>Software Testing</h1>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="pt-5 pb-5 design-content pb-0">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h3><b>Software Testing & Quality Assurance Services</b></h3>
        <p class="pt-2">MSAII offers complete Software testing services for global enterprises and across the
          industries. We specialize in providing end-to-end application testing solutions, setting up Testing Center of
          Excellence, QA strategies at optimal cost, desired Quality and ensure delivery at the right time every time.
        </p>
        <h3>
          <b>Testing Stages</b>
        </h3>
        <p class="pt-2">we at MSAII, perform testing on various stages to keep test cases, testing process organized and
          easy to manage.</p>
        <img src="/assets/images/testing/testing_2.png" class="d-block w-75 m-auto" alt="stages of testing done">
        <h3 class="pt-3 mt-2"><b>Our Software Testing Services</b></h3>
        <p class="pt-2">We offer a wide range of software testing and QA services for AR/VR or Digital solutions for
          your AI/ML applications. We constantly work on complex QA requirements and with our structured processes,
          tools and techniques, we adapt with ease to the industry agility.</p>
        <p>A decade in design has allowed our team to perfect the process of delivering UI and UX services. We follow
          established design standards, workflows, and guidelines — you get the product you need, delivered by expert
          designers within the set timeframe.</p>
        <p>Our team of software testers & QA professionals have experience in various QA and testing services like test
          automation, performance testing and functional testing. Our professional approach through all facilitate in
          enhancing the product/project quality and development experience.</p>
        <img src="/assets/images/testing/testing_1.png" class="d-block w-75 m-auto" alt="types of testing done">
      </div>
    </div>
  </div>
</section>
<section class="pad-50 industries-main designs pb-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 mb-5">
        <div class="indust-wrap">
          <a [routerLink]="['/','software-testing','test-automation']">
            <img src="assets/images/testing/automation-testing.jpeg" alt="">
            <h3>Test Automation</h3>
          </a>
        </div>
      </div>
      <div class="col-lg-4 mb-5">
        <div class="indust-wrap">
          <a [routerLink]="['/','software-testing','manual-testing']">
            <img src="assets/images/testing/manual-testing.jpeg" alt="">
            <h3>Manual Testing</h3>
          </a>
        </div>
      </div>
      <div class="col-lg-4 mb-5">
        <div class="indust-wrap">
          <a [routerLink]="['/','software-testing','application-support']">
            <img src="assets/images/testing/software-testing.png" alt="">
            <h3>Application Support</h3>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="core-services" class="common-pad pt-5 pb-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="section-title mb-5">
          <h1>Digital Industry Services</h1>
          <p>MSAII is a modern engineering company, dedicated to providing high quality design and product development
            services for the engineering industry.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <div class="core-services-wrap">
          <a [routerLink]="['/','digital-industry', 'ai-ml']">
            <img src="assets/images/al-ml.png" class="img-fluid" alt="">
            <div class="lato-bold">AI / ML</div>
          </a>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="core-services-wrap">
          <a [routerLink]="['/','digital-industry', 'iot']">
            <img src="assets/images/iot.png" alt="iot">
            <div class="lato-bold">IOT</div>
          </a>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="core-services-wrap">
          <a [routerLink]="['/','digital-industry', 'ui-ux']">
            <img src="assets/images/ui-ux-3.jpg" alt="ui-ux">
            <div class="lato-bold">UI / UX</div>
          </a>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-lg-4">
        <div class="core-services-wrap">
          <a [routerLink]="['/', 'digital-industry', 'semiconductor']">
            <img src="assets/images/industires/digital-banner.png" alt="digital banner">
            <div class="lato-bold">Semiconductor</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
