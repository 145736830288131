<section id="web-dev-banner" class="overlay min-banner text-white">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="banner-text">
            <h1>Development</h1>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class=" webDev text-center pt-5 pb-5">
      <div class="container">
          <div class="row justify-content-center">
              <div class="col-7">
                  <h2 class="lato-bold">Web Development</h2>
                  <p>Whether it’s about turning your ideas into profitable software solutions or getting the gen-y gung-ho about your fun idea – We provide high quality, cost effective and reliable result oriented web applications to help you succeed in rapidly changing tech-driven markets.</p>
              </div>
          </div>
      </div>
  </section>
  <section class=" pt-5 pb-5 webDev bg-light">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center pb-5">
                <h3 class="lato-bold">JavaScript</h3>
            </div>
        </div>
        <div class="row text-center">
            <div class="col-lg-4 mb-3">
                <div class="tech-skills">
                    <div class="image">
                    <img src="assets/images/web-dev/react.png" class="img-fluid w-50" alt="">
                    </div>
                    <div class="pt-1">
                    <h5 class="lato-bold">React</h5>
                    <span>Looking for Top- Rated Remote React JS Developer or A React Team?</span>
                    <p>We are a leading ReactJS development company offering visually appealing UI and UX possibilities coupled with hi-end solutions and efficient performance.</p>
                </div>
                </div>
            </div>
            <div class="col-lg-4 mb-3">
                <div class="tech-skills">
                    <div class="image">
                        <img src="assets/images/web-dev/nodejs.png" class="img-fluid w-50" alt="">
                    </div>

                    <div class="image">
                    <h5 class="lato-bold">NodeJS</h5>
                    <span>Leverage Our NodeJS Development Expertise in order to Work Better Together</span>
                    <p>Do you have requirements for a specific Node.js version? We have top-of-the-line expertise in all the latest versions of Node.js that includes 10.x, 12.x and 14.x.</p>
                </div>
                </div>
            </div>
            <div class="col-lg-4 mb-3">
                <div class="tech-skills">
                    <div class="image">
                        <img src="assets/images/web-dev/vue.png" class="img-fluid w-50" alt="">
                    </div>

                    <div class="pt-1">
                    <h5 class="lato-bold">Vue.js</h5>
                    <span>Leverage Our Vue.js Development Expertise in Order to Work Better Together</span>
                    <p>At Msaii We have top-of-the-line expertise in all the latest versions of Vue.js to build scalable web architectures, so you don’t have to worry about scalability and flexibility when your product grows.</p>
                </div>
                </div>
            </div>
            <div class="col-lg-4 mb-3">
                <div class="tech-skills">
                    <div class="image mb-1">
                      <img src="assets/images/web-dev/angularjs.png" class="img-fluid w-50" alt="">
                    </div>
                    <h5 class="lato-bold">AngularJS</h5>
                    <span>AngularJS Development Services Where Front-end Evolution Begins</span>
                    <p>We are the early adopter and now the veterans of the latest, greatest, and most quickly adopted front-end technology of this decade.</p>
                </div>
            </div>
            <div class="col-lg-4 mb-3">
                <div class="tech-skills">
                    <div class="image">
                    <img src="assets/images/web-dev/bootstrap.png" class="img-fluid w-50" alt="">
                    </div>
                    <h5 class="lato-bold">Bootstrap Development</h5>
                    <span>The most popular HTML, CSS, and JS library in the world.</span>
                    <p>Bootstrap is the most popularly reliable HTML, CSS, and JavaScript framework for developing responsive front end and web applications.</p>
                </div>
            </div>
        </div>
      </div>
  </section>
  <section class="pt-5 pb-5 webDev text-center">
    <div class="container">
        <div class="row">
            <div class="col-12  pb-5">
                <h3 class="lato-bold">Web Component</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 mb-3">
                <div class="tech-skills">
                    <div class="image">
                    <img src="assets/images/web-dev/polymerjs.png" class="img-fluid w-25" alt="">
                </div>
                    <h5 class="lato-bold">Polymer.Js</h5>
                    <span>Build. Distribute. Adopt.</span>
                    <p>Polymer is a library for creating Web Components using HTML elements.Polymer is a basic library that makes it faster and easier than ever before to build beautiful applications on the web</p>
                </div>
            </div>
            <div class="col-lg-6 mb-3">
                <div class="tech-skills">
                    <div class="image">
                    <img src="assets/images/web-dev/stenciljs.png" class="img-fluid w-25" alt="">
                </div>
                    <h5 class="lato-bold">Stencil.js</h5>
                    <span>Build. Distribute. Adopt.</span>
                    <p>We have top-of-the-line expertise in Stencil.js to Reduce design debt, connect disparate tech teams and enforce brand consistency at scale with code-based, world-class design systems that work everywhere.</p>
                </div>
            </div>
        </div>
      </div>
  </section>
  <section class="pt-5 pb-5 webDev bg-orange text-center">
    <div class="container">
        <div class="row">
            <div class="col-12 text-white">
                <h2 class="lato-bold">Mobile Application Development</h2>
            </div>
        </div>
        <div class="row text-white">
            <div class="col-lg-6 mb-4">
                <div class="tech-skills">
                    <div class="image">
                    <img src="assets/images/web-dev/angular-native.png" class="img-fluid w-25" alt="">
                </div>
                    <h5 class="lato-bold text-white">Angular Native</h5>
                    <span>Angular for NativeScript</span>
                    <p>Combine NativeScript, a framework for building native iOS and Android apps using JavaScript, and Angular, a framework for architecting JavaScript applications of all sizes, and you have a powerful software architecture—one that allows you to build mobile and web apps using the same technology stack, and in some cases, the same code.</p>
                </div>
            </div>
            <div class="col-lg-6 mb-4">
                <div class="tech-skills">
                    <div class="image">
                    <img src="assets/images/web-dev/ionic.png" class="img-fluid w-25" alt="">
                </div>
                    <h5 class="lato-bold text-white">Ionic</h5>
                    <span>Ionic Mobile App Development Services</span>
                    <p>Ionic is a comprehensive open-source SDK framework that is widely used to build interactive, engaging, eye-appealing, and high-performance mobile apps. To scale the mobile app and simplify the development Ionic mobile app framework comes with a native-style and user-friendly mobile UI element.</p>
                </div>
            </div>
            <div class="col-lg-6 mb-4">
                <div class="tech-skills">
                    <div class="image mb-3">
                    <img src="assets/images/web-dev/android.png" class="img-fluid w-50" alt="">
                </div>
                    <h5 class="lato-bold text-white">Android App </h5>
                    <span>Android App Development Company</span>
                    <p>We simply cannot deny the fact that Android is the world's most popular mobile application platform and it makes a great deal of business sense to have a presence on this platform in the form of an application.</p>
                </div>
            </div>
            <div class="col-lg-6 mb-4">
                <div class="tech-skills">
                    <div class="image">
                    <img src="assets/images/web-dev/iphone.png" class="img-fluid w-25" alt="">
                    </div>
                    <h5 class="lato-bold text-white">iOS App</h5>
                    <span>iPhone Application Development Company</span>
                    <p>We are one of the best iPhone application development companies worldwide by Clutch, AppFutura and GoodFirms. We are well-versed in developing end-to-end custom mobile app solutions using the latest iOS SDKs.</p>
                </div>
            </div>
        </div>
      </div>
  </section>
  <section class="pt-5 pb-5 webDev text-center">
    <div class="container">
        <div class="row">
            <div class="col-12 pb-5">
                <h3 class="lato-bold">Open Source Web Framework</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 mb-3">
                <div class="tech-skills">
                    <div class="image">
                        <img src="assets/images/web-dev/ruby-on-rails.png" class="img-fluid w-25 pt-3" alt="">
                    </div>
                    <h5 class="lato-bold">Ruby on Rails</h5>
                    <span>Build. Distribute. Adopt.</span>
                    <p>If you are planning to leverage Ruby on Rails Development services, Msaii  is a one-stop solution to hire dedicated Ruby on Rails developers. We have top-notch Agile software teams of full-stack RoR developers.</p>
                </div>
            </div>
            <div class="col-lg-4 mb-3">
                <div class="tech-skills">
                    <div class="image">
                        <img src="assets/images/web-dev/docker.png"  class="img-fluid w-50"alt="">
                    </div>
                    <h5 class="lato-bold">Docker</h5>
                    <span>End to End Docker Development and Consulting Services</span>
                    <p>Hire Certified Docker developers from us to containerize your application and deploy them more frequently than ever before.</p>
                </div>
            </div>
            <div class="col-lg-4 mb-3">
                <div class="tech-skills">
                    <div class="image">
                        <img src="assets/images/web-dev/spree-commerce-logo.png" class="img-fluid" alt="">
                    </div>
                    <h5 class="lato-bold">Spree Commerce</h5>
                    <span>Spree Commerce Development</span>
                    <p>Hire Spree Commerce developers from us to build a store precisely the way you want using artistic expertise of our Ruby on Rails.</p>
                </div>
            </div>
            <div class="col-lg-4 mb-3">
                <div class="tech-skills">
                    <div class="image mb-3">
                        <img src="assets/images/web-dev/python.png" class="img-fluid w-50" alt="">
                    </div>
                    <h5 class="lato-bold">Python</h5>
                    <span>Most-Trusted Python Development Company</span>
                    <p>Outsource Python development services from us as we are globally renowned as a prominent python development company.</p>
                </div>
            </div>
            <div class="col-lg-4 mb-3">
                <div class="tech-skills">
                    <div class="image">
                      <img src="assets/images/web-dev/php.png" class="img-fluid w-50" alt="">
                    </div>
                    <h5 class="lato-bold">PHP</h5>
                    <span>Trusted PHP Development Company</span>
                    <p>Msaii has become a synonym of PHP application development. We are here to let the world know about your business through the medium of website.</p>
                </div>
            </div>
        </div>
      </div>
  </section>
  <section class="pad-50 webDev bg-light pt-5 text-center">
    <div class="container">
        <div class="row">
            <div class="col-12 pb-5">
                <h3 class="lato-bold">Cloud</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 mb-5">
                <div class="tech-skills">
                    <div class="image mb-4">
                        <img src="assets/images/web-dev/aws-logo.png" class="img-fluid w-50" alt="aws">
                    </div>

                    <h5 class="lato-bold">AWS</h5>
                    <span>AWS Cloud Consulting Partner</span>
                    <p>Global AWS cloud infrastructure is one of the most secure, flexible, and reliable cloud computing environments available on and off the universe. At Msaii, our AWS certified developers bring the power of AWS to your company’s infrastructure.</p>
                </div>
            </div>
            <div class="col-lg-6 mb-4">
                <div class="tech-skills">
                    <div class="image">
                        <img src="assets/images/web-dev/heroku.png" class="img-fluid w-25" alt="heroku">
                    </div>

                    <h5 class="lato-bold">Heroku</h5>
                    <span>Platform as a service based on a managed container system</span>
                    <p>Integrated data services and a powerful ecosystem, for deploying and running modern apps. The Heroku developer experience is an app-centric approach for software delivery, integrated with today’s most popular developer tools and workflows.</p>
                </div>
            </div>
        </div>
      </div>
  </section>
  <section>
      <div class="container-fluid text-center text-white">
          <div class="row">
              <div class="col-lg-4 p-3 bg-yellow">
                  <div class="service-cell">
                      <img src="assets/images/web-dev/settings.png" class="img-fluid w-25" alt="it talent">
                      <h4 class="lato-bold">Top 1% IT Talent</h4>
                      <p>Bacancy Technology is an exclusive hub of top dedicated software developers, UI/UX designers, QA experts, and product managers with an incredibly rare and hidden talents you will ever come across. We let you access the top 1% IT talent from independent software developers to the fully managed teams.</p>
                  </div>
              </div>
              <div class="col-lg-4 p-3 bg-yellow-med">
                <div class="service-cell">
                    <img src="assets/images/web-dev/calendar.png" class="img-fluid w-25" alt="timezone aligned">
                    <h4 class="lato-bold">Time Zone Aligned</h4>
                    <p>Timezone is never a constraint when you are working with Bacancy Technology. We follow one very simple principle – our developers and your time zone. Hire dedicated software developers from us and make collaboration in a faraway to work according to your time zone, deadline, and milestone.</p>
                </div>
            </div>
            <div class="col-lg-4 p-3 bg-yellow-dark">
                <div class="service-cell">
                    <img src="assets/images/web-dev/team.png" class="img-fluid w-25" alt="exp. team">
                    <h4 class="lato-bold">Experienced Team</h4>
                    <p>Whether you are looking for skilled developers in emerging technologies or looking for an extended arms to augment your existing team, we can lend a helping hand in both situations. We are a full-stack software development company with 300+ skilled and experienced software developers whom you can hire at your convenience to address the ongoing business challenges</p>
                </div>
            </div>
          </div>
      </div>
  </section>

