<section id="embeded-system-banner" class="min-banner text-white overlay">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="banner-text">
            <span><span></span><h1>design  <span style="font-family: Lato-thin !important;"> Verification <span class="border-right"></span> <span class="pl-3"></span> validation</span></h1></span>
            <h4>Our team is passionate about delivering results</h4>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-cover pt-5 design-content pb-5">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <p>In the Verification & Validation space, MSAII offerings range from developing test scripts, delivering system and performing test automation. We are also involved in model-based testing and loop simulation for control modules. The test automation platforms built in-house provide cost savings to our clients.</p>
                <h6>Hardware-In-Loop (HIL)</h6>
                <p>Hardware commissioning, modeling, test automation, processes for verification and validation of entire modules – as a prime contractor we develop and maintain your independent HiL system landscape regardless of the manufacturer. On-site, or off-site in our secure back offices.</p>
                <h1>Our Services</h1>
                <ul>
                    <li>Hardware-Independent Consulting</li>
                    <li>Simulator Hardware Deployment (i.e. NI, dSPACE, ETAS, OPAL-RT, VT systems etc..)</li>
                    <li>Conceptual Design and Deployment of the Entire Infrastructure</li>
                    <li>Restructuring (Planning, Coordination, Implementation)</li>
                    <li>Development of Circuits-,Plant and IO-Models</li>
                    <li>Test Preparation and Test Automation (Open Loop and Closed Loop)</li>
                </ul>
                <h6>Software-In-Loop (SIL)/Model-In-Loop (MIL)</h6>
                <p>Testing abstract behavior of a system or sub-system for Model-in-the-Loop execution (MIL) Test development and automation of production-ready source code and compiled object code for software-in-the-loop (SIL) and processor-in-the-loop (PIL) execution</p>
                <h1>Our Services</h1>
                <ul>
                    <li>Development of Simulation Environments on different variants of Control Unit</li>
                    <li>Design, development and validation of SIL and MIL models</li>
                    <li>Designing real-time applications targeting floating- or fixed-point processors</li>
                    <li>Development of test infrastructure for model verification</li>
                    <li>Model exchange for several simulation tools and platforms</li>
                    <li>Delivery of customized Models based on customer needs</li>
                    <li>Development of methods and processes to reach higher level of automation in model build and verification</li>
                </ul>
            </div>
        </div>
    </div>
  </section>
  <section class="common-pad industries-main">
    <div class="container">
      <h1 class="py-2">Other services</h1>
      <div class="row">
        <div class="col-lg-4 mb-5">
          <div class="indust-wrap">
            <img src="assets/images/embeded-system/embeded-system.jpg" alt="">
            <div class="indust-info">
              <h5>Embedded Software Development</h5>
              <p>The global automotive industry is always in flux, as new models &amp; designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','embedded-software-system']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-5">
          <div class="indust-wrap">
            <img src="assets/images/embeded-system/modal-based.jpg" alt="">
            <div class="indust-info">
              <h5>Model Based Development</h5>
              <p>A semiconductor material has an electrical conductivity value falling between that of a conductor</p>
              <a [routerLink]="['/','model-based-development']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-5">
          <div class="indust-wrap">
            <img src="assets/images/embeded-system/functional-safety.jpg" alt="">
            <div class="indust-info">
              <h5>Functional Safety</h5>
              <p>The global automotive industry is always in flux, as new models &amp; designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','functional-safety']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-5">
          <div class="indust-wrap">
            <img src="assets/images/embeded-system/system-engineering.jpg" alt="">
            <div class="indust-info">
              <h5>Systems Engineering</h5>
              <p>The global automotive industry is always in flux, as new models &amp; designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','system-engineering']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-5">
            <div class="indust-wrap">
              <img src="assets/images/embeded-system/cyber-security.jpg" alt="">
              <div class="indust-info">
                <h5>Cyber Security</h5>
                <p>The global automotive industry is always in flux, as new models &amp; designs alter the shape and performance of automobiles.</p>
                <a [routerLink]="['/','cyber-security']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
      </div>
    </div>
  </section>
  <section class="bg-cover mb-4 industries-main">
    <div class="container">
      <h1 class="pb-2">Industries</h1>
      <div class="row mb-5">
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/auto-1.png" alt="">
            <div class="indust-info">
              <h5>Automotive Industry</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/', 'services','automotive']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/auto-2.png" alt="">
            <div class="indust-info">
              <h5>Digital Manufacturing Industry</h5>
              <p>Digital manufacturing is an integral part of product lifecycle management (PLM)</p>
              <a [routerLink]="['/','services', 'digital-industry']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/industires/semiconductor.png" alt="">
            <div class="indust-info">
              <h5>semiconductor</h5>
              <p>A semiconductor material has an electrical conductivity value falling between that of a conductor</p>
              <a [routerLink]="['/', 'digital-industry', 'semiconductor']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row pb-5">
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/industires/webdevelop.png" alt="">
            <div class="indust-info">
              <h5>Web development</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','services','web-development']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/aec-bim.jpg" alt="">
            <div class="indust-info">
              <h5>AEC/BIM</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','services','epc-bim']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/aerospace.png" alt="">
            <div class="indust-info">
              <h5>Aerospace</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of Aerospace.</p>
              <a [routerLink]="['/','services','aerospace']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/energy.png" alt="">
            <div class="indust-info">
              <h5>Energy</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','services','energy']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/waste-water-treatment.png" alt="">
            <div class="indust-info">
              <h5>Waste Water Treatment</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','services','waste-water-treatment']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        </div>
    </div>
  </section>
