import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-three-d-printing',
  templateUrl: './three-d-printing.component.html',
  styleUrls: ['./three-d-printing.component.css']
})
export class ThreeDPrintingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
