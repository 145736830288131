<section id="whoweare" class="text-white min-banner pt-3 pb-3">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 whoweareDiv">
        <div class="banner-text">
          <h1 data-aos="fade-up" data-aos-duration="2000">
            We are Feature-Driven people
          </h1>
          <p class="py-4" data-aos="fade-up" data-aos-duration="3000">
            Our team is passionate about delivering results
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<section id="what-you-can-do-here" class="p-space">
  <div class="container">
    <p class="careers-title text-center">
      It all start with Human Intelligence
    </p>
    <p class="careers-description" data-aos="fade-up" data-aos-duration="3000">
      At MSAII, we believe in pushing the boundaries and connecting motivated
      people to the right opportunities for them. <br />
      Here, you'll discover a diverse a dilerse team of talented people
      realizing their potential and forging their own paths to success.
      <br />
      Our corporate culture is empowered and embedded in a strong value system
      that reflects our enthics , culture and commitment.
    </p>
  </div>
</section>
<section id="what-you-can-do-here" class="p-space">
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-5 d-flex flex-column justify-content-center">
      <div class="container-fluid ">
        <div class="careers-content">
          <p class="careers-title">What you can do here</p>
          <app-read-more
            class="careers-description"
            text="You can ask better questions to get better answers. You can inspire change and open doors. You can learn and lead. You can be the best version of yourself by helping to build a better working world. Explore what's possible on MASII teams and across MASII services."
          ></app-read-more>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-xl-6 col-xll-6 col-12">
      <div class="careers-img">
        <img src="/assets/career_image/first.png" alt=""  style="object-fit: cover;"/>
        <div class="overlay-right"></div>
      </div>
    </div>
  </div>
</section>

<section id="like-to-work" class="p-space">
  <div class="row">
    <div class="col-md-6">
      <div class="container-fluid">
        <div class="careers-img">
          <img src="/assets/career_image/second.jpg" alt=""  style="object-fit: cover;" />
          <div class="overlay-left"></div>
        </div>
      </div>
    </div>
    <div
      class="col-lg-5 col-xl-5 col-xll-5 col-12 d-flex flex-column justify-content-center order-1 order-lg-2 order-xl-2 order-xll-2"
    >
      <div class="careers-content right">
        <p class="careers-title">What it's like to work here</p>
        <app-read-more
          class="careers-description"
          text="A better working world starts with the people at MSAII who are building it every day. Imagine having the freemom to customize the career you envision, in a place where an inclusive, supportive culture opens doors to rich experiences and opportunities."
        ></app-read-more>
      </div>
    </div>
  </div>
</section>

<div class="row">
  <div class="col-md-12">
    <section
      id="jobs"
      class="p-space d-flex flex-column justify-content-center"
    >
      
        <div class="jobs-content">
          <p class="jobs-title text-center">Are you up for a new challenge?</p>
          <p class="jobs-description text-center">
            We are looking for new talents all over the world. Have a look at
            our job openings!
          </p>
          <a href="#" class="job-btn">Show Jobs</a>
        </div>

      <div class="overlay-blue"></div>
    </section>
  </div>
</div>

<section id="join-us" class="p-space">
  <div class="row">
    <div class="col-md-1"></div>
    <div
      class="col-lg-5 col-xl-5 col-xll-5 col-12 d-flex flex-column justify-content-center"
    >
      <div class="careers-content">
        <p class="careers-title">How to join us</p>
        <app-read-more
          class="careers-description"
          text="Do the work you love. Be inspired by new opportunities. Whether you're an experienced professional looking for your next challenge, or a student with dreams of shaping the future, we'll help you make an impact from the moment you join."
        ></app-read-more>
      </div>
    </div>
    <div class="col-lg-6 col-xl-6 col-xll-6 col-12">
      <div class="careers-img">
        <img src="/assets/career_image/fourth.png" alt="" style="object-fit: cover;"/>
        <div class="overlay-right"></div>
      </div>
    </div>
  </div>
</section>

<section id="We-bring" class="p-space">
  <div class="row">
    <div class="col-md-6">
      <div class="careers-img">
        <img src="/assets/career_image/fifth.jpg" alt=""  style="object-fit: cover;" />
      </div>
    </div>

    <div
      class="col-lg-5 col-xl-5 col-xll-5 d-flex flex-column justify-content-center"
    >
      <div class="careers-content right">
        <p class="careers-title">
          We bring diverse ideas, backgrounds and mindsets together
        </p>
        <app-read-more
          class="careers-description"
          text="Ideas aren't bound by borders, and neither are we. We know that by bringing together different perspectives, insights and experiences, we'll create a more inclusive environment for MSAll people."
        ></app-read-more>

        <!-- <p class="careers-description">Ideas aren't bound by borders, and neither are we. We know that by bringing together different perspectives, insights and experiences, we'll create a more inclusive environment for MSAll people.</p> -->
      </div>
    </div>

    <div class="col-md-1"></div>
  </div>
</section>

<section id="embracing-change" class="p-space">
  <div class="row">
    <div class="col-md-1"></div>
    <div
    class="col-lg-5 col-xl-5 col-xll-5 col-12 d-flex flex-column justify-content-center"
  >
    <div class="careers-content">
      <p class="careers-title">
        We're embracing change in everything we do
      </p>
      <app-read-more
        class="careers-description"
        text="That's why we've established a Global Innovation team to led. The team is focused on understanding the external forces changing our world and how to do things differently in 6 months, 3 years, 10 years and beyond and beyond."
      ></app-read-more>
      <!-- <p class="careers-description">That's why we've established a Global Innovation team to led. The team is focused on understanding the external forces changing our world and how to do things differently in 6 months, 3 years, 10 years and beyond and beyond.</p> -->
    </div>
  </div>
  <div class="col-lg-6 col-xl-6 col-xll-6 col-12">
    <div class="careers-img">
      <img src="/assets/career_image/sixth.jpg" alt=""  style="object-fit: cover;" />
      <div class="overlay-blue"></div>
    </div>
  </div>
    

  </div>
</section>

<section id="We-invest-in-technology" class="p-space">
  <div class="row">
  

          <div
            class="col-lg-6 col-xl-6 col-xll-6 col-12 order-2 order-lg-1 order-xl-1 order-xll-1"
          >
            <div class="careers-img">
              <img src="/assets/career_image/seventh.jpg" alt=""  style="object-fit: cover;" />
              <div class="overlay-blue"></div>
            </div>
          </div>
          <div
            class="col-lg-5 col-xl-5 col-xll-5 col-12 order-1 order-lg-2 order-xl-2 order-xll-2 d-flex flex-column justify-content-center d-flex flex-column justify-content-center"
          >
            <div class="careers-content right">
              <p class="careers-title">
                We invest in technology to do more meaningful work
              </p>
              <app-read-more
                class="careers-description"
                text="Today, more than apeople with technology backgrounds work throughout the MSAll organization. We're market-eaders in IT Services and Solution. MSAII teams have provided ground-breaking Innovative projects for clients. And we're just getting started."
              ></app-read-more>
              <!-- <p class="careers-description">Today, more than apeople with technology backgrounds work throughout the MSAll organization. We're market-eaders in IT Services and Solution. MSAII teams have provided ground-breaking Innovative projects for clients. And we're just getting started.</p> -->
            </div>
          </div>
   
   

    </div>
   

</section>

<section id="We-invest-in-your-growth" class="p-space">
  <div class="row">

 <div class="col-md-1"></div>

          <div
            class="col-lg-5 col-xl-5 col-xll-5 col-12 d-flex flex-column justify-content-center"
          >
            <div class="careers-content">
              <p class="careers-title">
                We invest in your growth, you personalize your development
              </p>
              <app-read-more
                class="careers-description"
                text="At MSAll you can become a transformative leader and help MSAII clients innovate and work through unprecedented change. We're investing in your development in unique ways, With its focus on technology, leadership and business skills, you can build the transformative mindsets and tech skillsets we all need to thrive."
              ></app-read-more>

              <!-- <p class="careers-description">At MSAll you can become a transformative leader and help MSAII clients innovate and work through unprecedented change. We're investing in your development in unique ways, With its focus on technology, leadership and business skills, you can build the transformative mindsets and tech skillsets we all need to thrive.</p> -->
            </div>
          </div>
          <div class="col-lg-6 col-xl-6 col-xll-6 col-12">
            <div class="careers-img">
              <img src="/assets/career_image/8.jpg" alt=""  style="object-fit: cover;" />
              <!-- <div class="overlay-blue"></div> -->
            </div>
          </div>
 
    


  </div>
</section>

<div class="row">
<div class="col-md-12">
  <section
  id="job-qa-last"
  class="p-space d-flex flex-column justify-content-center"
>
  <div class="container-fluid">
    <div class="jobs-content">
      <p class="jobs-title text-center">
        Any questions about applying with us?
      </p>
      <p class="jobs-description text-center">
        Check out our application process overview and our answers to
        frequently asked questions.
      </p>
      <a href="#" class="job-btn">More Information</a>
    </div>
  </div>
  <div class="overlay-blue"></div>
</section>
</div>

 
</div>

<section id="our-offer" class="p-space">
  <div class="row">
   
    <div class="col-md-12">
      <div class="container-fluid">
        <p class="jobs-title">Explore our offer</p>
        <div class="row">
          <div class="col-lg-3 col-xl-3 col-xll-6 col-12">
            <div class="our-offer-img">
              <img
                src="/assets/career_image/your career opportunity.png"
                alt=""
                style="object-fit: cover;"
              />
              <p class="our-offer-title">Your career opportunities</p>
            </div>
          </div>
          <div class="col-lg-3 col-xl-3 col-xll-6 col-12">
            <div class="our-offer-img">
              <img src="/assets/career_image/employees_Benifits.png" alt=""  />
              <p class="our-offer-title">Your benifits</p>
            </div>
          </div>
          <div class="col-lg-3 col-xl-3 col-xll-6 col-12">
            <div class="our-offer-img">
              <img
                src="/assets/career_image/your training opportunities.png"
                alt=""
              />
              <p class="our-offer-title">Your training opportunities</p>
            </div>
          </div>
          <div class="col-lg-3 col-xl-3 col-xll-6 col-12">
            <div class="our-offer-img">
              <img
                src="/assets/career_image/your work life balance.png"
                alt=""
              />
              <p class="our-offer-title">Your work/life balance</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  </div>
</section>