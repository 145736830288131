<section id="services-banner" class="min-banner text-white overlay pb-5">
    <div class="overley"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12 servicesBannerDiv">
          <div class="banner-text">
            <h1 class="services_one" data-aos="fade-up" data-aos-duration="2000">News And Insights</h1>
          </div>
        </div>
      </div>
    </div>
  </section>

<section id="ourvalues">
    <div class="head text-center mt-5 mb-5">
        <h4>News And Insights Comming Soon..</h4>
    </div>
</section>