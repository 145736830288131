<section id="whoweare" class="text-white min-banner pt-3 pb-3">
  <div class="container">
    <div class="row ">
      <div class="col-lg-12 whoweareDiv">
        <div class="banner-text">
          <h1 data-aos="fade-up" data-aos-duration="2000">We are Feature-Driven people</h1>
          <p class="py-4" data-aos="fade-up" data-aos-duration="3000">Our team is passionate about
            delivering results</p>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="getintouch" class="pb-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 collab_text">
        <div class="ourservices-text">

          <h2 class="lato-reg" data-aos="zoom-in" data-aos-duration="2000">We work in an exclusively collaborative
            manner</h2>

          <span class="pt-5">
            <p class="lato-reg p__one" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0">MSAII is a modern
              engineering company, dedicated to providing high quality design and product development services for the
              engineering industry.</p>

            <p class="lato-reg p__one" data-aos="fade-up" data-aos-duration="2000" data-aos-offset="0">We offer full
              range of design and product development services from idea generation and problem-solving to prototype
              development and training. Our assignments cover a wide range of vertical markets and engineering
              industries.</p>

            <p class="lato-reg p__two" data-aos="fade-up" data-aos-duration="2000" data-aos-offset="0">MSAII provides a unique blend of
              design, creativity and engineering talent. Our expertise allows clients to remain focused on their core
              business goals as we efficiently handle all of their design requirements.</p>

            <P class="lato-reg p__two" data-aos="fade-up" data-aos-duration="2000" data-aos-offset="0">Our proven approach will reduce your
              design & development times, streamline your project and ultimately improve your business outcomes.</P>
          </span>
        </div>
      </div>
      <div class="col-lg-5 collab" data-aos="fade-up" data-aos-duration="2000">
      </div>
    </div>
  </div>
</section>

<section class="row our_mission">
  <div class="our_mission__one col-lg-6" style="padding:0px !important;" data-aos="fade-up" data-aos-duration="1000">
    <!-- <img src="assets/images/mission.png" class="img-fluid"> -->
  </div>
  <div class="who-we-content col-lg-6 pt-5 pb-5 pl-4">
    <div class="">
      <h1 class="lato-bold" data-aos="slide-up" data-aos-duration="1000" data-aos-offset="0">Our Mission</h1>

      <p class="lato-reg p__one" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0">
        Our mission is to listen, understand and respond to our customers with distinctive
        technological solutions; and nurture our partnership, globally by a rapid and sensible approach.
      </p>

      <p class="lato-reg p__two" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0">
        Our emphasis is on providing excellent customer services by creating a nurturing environment for employees where
        they take the business "ownership".
      </p>
    </div>
  </div>
</section>

<section class="row our_vision">
  <div class="who-we-content bg-blue col-lg-6 pb-5 pt-5 pl-4 ">
    <div class="">
      <h1 class="lato-bold" data-aos="slide-up" data-aos-duration="1000" data-aos-offset="0">Our vision</h1>
      <p class="lato-reg p__one" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0">
        Moral integrity, professional honesty, loyalty and impartiality towards the customer,
        transparency towards the market.
      </p>
      <p class="lato-reg p__two" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0">
        We interpret quality as an element to give added value to the customer, exceed
        their expectations, allow them to seize new opportunities and strengthen their reputation.
      </p>
    </div>
  </div>
  <div class="our_vision__two col-lg-6" style="padding:0px !important;" data-aos="fade-up" data-aos-duration="1000">
    <!-- <img src="assets/images/vision.png" class="img-fluid"> -->
  </div>
</section>

<section id="business_values" class="pt-5 pb-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 business_values__one">
        <div class="bvalues-text">
          <!-- <h1 class="lato-bold pt-3 pb-3">Business Values</h1> -->

          <p class="lato-reg pt-3 bv_p__one" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0">At its
            core, <span style="font-weight: 700;">disruption</span> is a
            successful alteration to a set of values, purchase patterns, and human behavior.</p>

          <p class="lato-reg pt-3 bv_p__two" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0">MSAII is
            powered by people that possess as wide an array of expertise and
            skills as they do drive for active, positive change. We are most proud of our diversity in background,
            expertise, and industries. To be a disruptor, you need the right breadth experience— not just agency
            experience.</p>
          <!-- <ul class="lato-reg">
            <li><i class="fa fa-arrow-right light" aria-hidden="true"></i> Increased focus on innovation and product
              development</li>
            <li><i class="fa fa-arrow-right" aria-hidden="true"></i> Improved quality of processes</li>
            <li><i class="fa fa-arrow-right" aria-hidden="true"></i> Enhanced operational efficiency</li>
            <li><i class="fa fa-arrow-right" aria-hidden="true"></i> Reduced lead time to production</li>
            <li><i class="fa fa-arrow-right" aria-hidden="true"></i> Reduced time-to-market</li>
          </ul> -->
        </div>
      </div>

      <div class="col-lg-5 business_values__two" data-aos="fade-up" data-aos-duration="2000">
      </div>
    </div>
  </div>
</section>

<section class="groupOfCompanies pos bg-gray pt-5 pb-5">
  <div class="container">
    <div class="">
      <h2 class="lato-bold mb-4" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0">Group of Companies</h2>
      <h5 class="lato-reg" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0">The MSAII's Group's core
        offer is supported by two specialized sub-brands that enable a full
        suite of end-to-end solutions for our clients.</h5>
    </div>
    <div class="row">

      <div class="column" data-aos="flip-up" data-aos-duration="1000">
        <img src="assets/images/partners/black-board.png" class="img-fluid" alt="card image">

      </div>
      <div class="column" data-aos="flip-up" data-aos-duration="1000">
        <img src="assets/images/partners/allinnov.png" class="img-fluid" alt="card image">

      </div>
    </div>
  </div>
</section>

<section class="featuredClients pos pt-5 pb-5">
  <div class="container">
    <div class="">
      <h2 class="lato-bold mb-4" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0">Featured Clients</h2>
      <h5 class="lato-reg" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0">The MSAII's Group's core
        offer is supported by two specialized sub-brands that enable a full
        suite of end-to-end solutions for our clients.</h5>
    </div>
    <div class="row">

      <div class="column" data-aos="flip-up" data-aos-duration="1000">
        <img src="assets/images/partners/Motherson_Crop.jpg" class="img-fluid" alt="card image">

      </div>
      <div class="column" data-aos="flip-up" data-aos-duration="1000">
        <img src="assets/images/partners/Simpson_Crop.jpg" class="img-fluid" alt="card image">

      </div>
    </div>
  </div>
</section>

<section class="partneredWith pos bg-gray pt-5 pb-5">
  <div class="container">
    <div class="">
      <h2 class="lato-bold mb-4" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0">Partnered with</h2>
      <h5 class="lato-reg" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0">The MSAII's Collaborator's is
        supported to providing the manpower to adopt the latest
        technologies and geared up their skills to market level.</h5>
    </div>
    <div class="row">
      <div class="column" data-aos="flip-up" data-aos-duration="1000">
        <img src="assets/images/partners/msa.png" class="img-fluid" alt="card image">
      </div>
    </div>
  </div>
</section>

<section id="worldwideStructure" class="worldwideStructure pt-4 pb-3">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 worldwideDiv">
        <div class="get-in-touch">

          <h2 class="lato-bold mb-3" data-aos="fade-up" data-aos-duration="2000" data-aos-offset="0">worldwide Structure
          </h2>

          <p class="lato-reg" data-aos="fade-up" data-aos-duration="2000" data-aos-offset="0">At the headquarters an
            international team of designers coming from some of the biggest companies in the world. develops projects
            with an even wider structure collaborating with designers located around the world, from USA, Canada,
            Europe, India and Japan.
          </p>

        </div>
      </div>
    </div>
    <hr>
    <section class="office-location">
      <div class="row">
        <div class="col-lg-12 text-center office-location-img" data-aos="zoom-in" data-aos-duration="2000"
          data-aos-offset="0">
          <!-- <img src="assets/images/map.png" class="img-fluid" alt=""> -->
        </div>
      </div>
    </section>
  </div>
</section>

<section id="contactBanner" class="contactBanner overlay pt-5 pb-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 pt-8">
        <div class="text-white">
          <h1 class="mb-4" data-aos="fade-up" data-aos-duration="2000" data-aos-offset="0">Headquarters</h1>
          <h2 class="mb-4" data-aos="fade-up" data-aos-duration="2000" data-aos-offset="0">Columbus/Ohio</h2>
          <p class="pt-3 lato-reg" data-aos="fade-up" data-aos-duration="2000" data-aos-offset="0">Located in the center
            of north america it is strategically positioned to fulfill the
            demands of our clients in USA, Asia, Europe and North/South America.</p>
        </div>
      </div>
    </div>
  </div>
</section>