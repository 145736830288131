<section id="services-banner" class="min-banner text-white overlay pb-5">
  <div class="overley"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-12 servicesBannerDiv">
        <div class="banner-text">
          <h1 class="services_one" data-aos="fade-up" data-aos-duration="2000">Our services - Digital Strategies</h1>
          <p class="services_two" data-aos="fade-up" data-aos-duration="3000">Our team is passionate about delivering
            results</p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="corosal">
  <app-our-services-carousel></app-our-services-carousel>
</section>


<!-- <section class="serviceColumn pt-5 pb-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="banner-text">
          <h1 class="service_list__one" data-aos="fade-up" data-aos-duration="2000" data-aos-offset="0">Our services
          </h1>
          <p class="service_list__two" data-aos="fade-up" data-aos-duration="3000" data-aos-offset="0">We are providing
            various types of services based on your requirements, budget and timetable. </p>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">

      <div class="col-sm col-md col-lg col-centered" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="0">
        <div class="black_bg mb-3">
          <a routerLink="/servicespage" fragment="automotive">
            <div class="maintxt" style="background-image:url(/assets/images/auto_banner.png) !important;">
              Automotive
            </div>
          </a>
        </div>
      </div>

      <div class="col-sm col-md col-lg col-centered" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="0">
        <div class="black_bg mb-3">
          <a href="/services/aerospace">
            <div class="maintxt aerospace" style="background-image:url(/assets/images/aerospace.png) !important;">
              Aerospace
            </div>
          </a>
        </div>
      </div>

      <div class="col-sm col-md col-lg col-centered" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="0">
        <div class="black_bg mb-3">
          <a href="/servicespage#digital-manufacturing">
            <div class="maintxt digital-manufacturing"
              style="background-image:url(/assets/images/industires/digital-industry-banner.png) !important;">
              Digital Manufacturing
            </div>
          </a>
        </div>
      </div>

      <div class="col-sm col-md col-lg col-centered" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="0">
        <div class="black_bg mb-3">
          <a href="/servicespage#semiconductor">
            <div class="maintxt semiconductor" style="background-image:url(/assets/images/aec-bim.png) !important;">
              Semiconductor
            </div>
          </a>
        </div>
      </div>

      <div class="col-sm col-md col-lg col-centered" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="0">
        <div class="black_bg mb-3">
          <a href="/services/web-development">
            <div class="maintxt web-development"
              style="background-image:url(/assets/images/web-dev/web-dev-banner.jpg) !important;">
              Web Development
            </div>
          </a>
        </div>
      </div>

      <div class="col-sm col-md col-lg col-centered" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="0">
        <div class="black_bg mb-3">
          <a href="/services/energy">
            <div class="maintxt energy" style="background-image:url(/assets/images/energy.png) !important;">
              Energy
            </div>
          </a>
        </div>
      </div>

    </div>
  </div>
</section> -->

<!-- AUTOMOTIVE SECTION  -->
<section id="automotive" class="automotiveServices pb-5 ">
  <div class="container ">
    <div class="row">
      <div class="col-lg-12">
        <div class="banner-text mb-3 py-3 ">
          <h1 class="automotive__h1 " data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0">
            Automotive
          </h1>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">

      <div class="col-sm col-md col-lg col-centered" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="0">
        <div class="black_bg mb-3">
          <a href="/automotive/embedded-systems">
            <div class="automotive_service_list"
              style="background-image: url('/assets/images/embeded-system/banner3.png')">
              Embedded Systems
            </div>
          </a>
        </div>
      </div>

      <div class="col-sm col-md col-lg col-centered" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="0">
        <div class="black_bg mb-3">
          <a href="/automotive/product-life-cycle-management">
            <div class="automotive_service_list" style="background-image: url('/assets/images/plm.jpg')">
              Product life <br> cycle management
            </div>
          </a>
        </div>
      </div>

      <div class="col-sm col-md col-lg col-centered" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="0">
        <div class="black_bg mb-3">
          <a href="/automotive/3d-printing">
            <div class="automotive_service_list" style="background-image: url('/assets/images/3d-printing.png')">
              3D Printing
            </div>
          </a>
        </div>
      </div>

      <div class="col-sm-4 col-md-4 col-lg-4 col-centered" data-aos="zoom-in" data-aos-duration="1000"
        data-aos-offset="0">
        <div class="black_bg mb-3">
          <a href="/automotive/virtual-validation">
            <div class="automotive_service_list" style="background-image: url('/assets/images/virtual.png')">
              Virtual Validation
            </div>
          </a>
        </div>
      </div>

    </div>
  </div>
</section>

<!-- DIGITAL INDUSTRY MANUFACTURING SECTION  -->
<section id="digital-manufacturing" class="digitalServices pb-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="banner-text mb-3 py-3">
          <h1 class="digital__h1" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0">
            Digital Industry Manufacturing Engineering
          </h1>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">

      <div class="col-sm col-md col-lg col-centered" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="0">
        <div class="black_bg mb-3">
          <a href="/services/digital-industry">
            <div class="digital_services_list"
              style="background-image: url('/assets/images/industires/digital-industry-banner.png')">
              Digital industry
            </div>
          </a>
        </div>
      </div>

      <div class="col-sm col-md col-lg col-centered" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="0">
        <div class="black_bg mb-3">
          <a href="/digital-industry/ai-ml">
            <div class="digital_services_list" style="background-image: url('/assets/images/al-ml.png')">
              AI & ML
            </div>
          </a>
        </div>
      </div>

      <div class="col-sm col-md col-lg col-centered" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="0">
        <div class="black_bg mb-3">
          <a href="/digital-industry/iot">
            <div class="digital_services_list" style="background-image: url('/assets/images/iot.png')">
              IOT
            </div>
          </a>
        </div>
      </div>

      <div class="col-sm-4 col-md-4 col-lg-4 col-centered" data-aos="zoom-in" data-aos-duration="1000"
        data-aos-offset="0">
        <div class="black_bg mb-3">
          <a href="/servicepage/#semiconductor">
            <div class="digital_services_list"
              style="background-image: url('/assets/images/industires/digital-banner.png')">
              Semiconductor
            </div>
          </a>
        </div>
      </div>

      <div class="col-sm-4 col-md-4 col-lg-4 col-centered" data-aos="zoom-in" data-aos-duration="1000"
        data-aos-offset="0">
        <div class="black_bg mb-3">
          <a href="/digital-industry/software-testing">
            <div class="digital_services_list"
              style="background-image: url('/assets/images/testing/software-testing.png')">
              Software testing
            </div>
          </a>
        </div>
      </div>

      <div class="col-sm-4 col-md-4 col-lg-4 col-centered" data-aos="zoom-in" data-aos-duration="1000"
        data-aos-offset="0">
        <div class="black_bg mb-3">
          <a href="/digital-industry/ui-ux">
            <div class="digital_services_list" style="background-image: url('/assets/images/ui-ux-1.jpg')">
              UI & UX
            </div>
          </a>
        </div>
      </div>

    </div>
  </div>
</section>

<!-- SEMICONDUCTOR SECTION  -->
<section id="semiconductor" class="semiconductorServices pb-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="banner-text mb-3 py-3">
          <h1 class="semiconductor__h1" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0">
            Semiconductor
          </h1>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">

      <div class="col-sm col-md col-lg col-centered" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="0">
        <div class="black_bg mb-3">
          <a href="/digital-industry/semiconductor#vlsi-design">
            <div class="semiconductor_service_list"
              style="background-image: url('/assets/images/semiconductors/vlsi-design.jpg')">
              VLSI DESIGN
            </div>
          </a>
        </div>
      </div>

      <div class="col-sm col-md col-lg col-centered" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="0">
        <div class="black_bg mb-3">
          <a href="/digital-industry/semiconductor#test-engineering">
            <div class="semiconductor_service_list" style="background-image: url('/assets/images/semiconductors/test-engineering.jpg')">
              Test Engineering
            </div>
          </a>
        </div>
      </div>

      <div class="col-sm col-md col-lg col-centered" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="0">
        <div class="black_bg mb-3">
          <a href="/digital-industry/semiconductor#product-engineering">
            <div class="semiconductor_service_list" style="background-image: url('/assets/images/semiconductors/product-engineering.png')">
              Product Engineering
            </div>
          </a>
        </div>
      </div>

      <!-- <div class="col-sm-4 col-md-4 col-lg-4 col-centered" data-aos="zoom-in" data-aos-duration="1000"
        data-aos-offset="0">
        <div class="black_bg mb-3">
          <a href="/automotive/virtual-validation">
            <div class="semiconductor_service_list" style="background-image: url('/assets/images/virtual.png')">
              Virtual Validation
            </div>
          </a>
        </div>
      </div> -->

    </div>
  </div>
</section>

<!-- ========================================================================================================================================================================== -->

<!-- OLD SERVICES LIST
<section class="common-pad pt-3 pb-3">
  <div class="container">
    <div class="row">
      <div class="col-sm col-md col-lg">
        <article class="card-box">
          <a href="/servicespage/#section1" class="clink">
            <div class="card-wrapper">
              <div class="card-image">
                <img src="/assets/images/aec-bim.png" alt="card-image" />
              </div>
              <div class="card-details">
                <div class="card-meta">
                  <div class="user-profile-image">
                    <img src="https://images.unsplash.com/photo-1600267185393-e158a98703de?crop=entropy&cs=srgb&fm=jpg&ixid=MnwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHx8MTYzMDgyNDc4Mw&ixlib=rb-1.2.1&q=85" alt="profile-image" />
                  </div>
                  <div class="card-info">
                    <p class="card-name">AEC-BIM</p>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </article>
      </div>

      <div class="col-sm col-md col-lg">
        <article class="card-box">
          <a href="/services/aerospace" class="clink">
            <div class="card-wrapper">
              <div class="card-image">
                <img src="/assets/images/aerospace.png" alt="card-image" />
              </div>
              <div class="card-details">
                <div class="card-meta">
                  <div class="user-profile-image">
                    <img src="https://images.unsplash.com/photo-1600267185393-e158a98703de?crop=entropy&cs=srgb&fm=jpg&ixid=MnwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHx8MTYzMDgyNDc4Mw&ixlib=rb-1.2.1&q=85" alt="profile-image" />
                  </div>
                  <div class="card-info">
                    <p class="card-name">Aerospace</p>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </article>
      </div>

      <div class="col-sm col-md col-lg">
        <article class="card-box">
          <a href="/services/energy" class="clink">

            <div class="card-wrapper">
              <div class="card-image">
                <img src="/assets/images/energy.png" alt="card-image" />
              </div>
              <div class="card-details">
                <div class="card-meta">
                  <div class="user-profile-image">
                    <img src="https://images.unsplash.com/photo-1600267185393-e158a98703de?crop=entropy&cs=srgb&fm=jpg&ixid=MnwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHx8MTYzMDgyNDc4Mw&ixlib=rb-1.2.1&q=85" alt="profile-image" />
                  </div>
                  <div class="card-info">
                    <p class="card-name">Energy</p>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </article>
      </div>

      <div class="col-sm col-md col-lg">
        <article class="card-box">
          <a href="/services/waste-water-treatment" class="clink">
            <div class="card-wrapper">
              <div class="card-image">
                <img src="/assets/images/waste-water-treatment.png" alt="card-image" />
              </div>
              <div class="card-details">
                <div class="card-meta">
                  <div class="user-profile-image">
                    <img src="https://images.unsplash.com/photo-1600267185393-e158a98703de?crop=entropy&cs=srgb&fm=jpg&ixid=MnwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHx8MTYzMDgyNDc4Mw&ixlib=rb-1.2.1&q=85" alt="profile-image" />
                  </div>
                  <div class="card-info">
                    <p class="card-name">Forming</p>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </article>
      </div>

      <div class="col-sm col-md">
        <article class="card-box">
          <a href="/servicespage/#section5" class="clink">
            <div class="card-wrapper">
              <div class="card-image">
                <img src="/assets/images/auto_banner.png" alt="card-image" />
              </div>
              <div class="card-details">
                <div class="card-meta">
                  <div class="user-profile-image">
                    <img src="https://images.unsplash.com/photo-1600267185393-e158a98703de?crop=entropy&cs=srgb&fm=jpg&ixid=MnwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHx8MTYzMDgyNDc4Mw&ixlib=rb-1.2.1&q=85" alt="profile-image" />
                  </div>
                  <div class="card-info">
                    <p class="card-name">Automotive</p>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </article>
      </div>

      <div class="col-sm col-md col-lg">
        <article class="card-box">
          <a href="/servicespage/#section6" class="clink">
            <div class="card-wrapper">
              <div class="card-image">
                <img src="/assets/images/industires/digital-industry-banner.png" alt="card-image" />
              </div>
              <div class="card-details">
                <div class="card-meta">
                  <div class="user-profile-image">
                    <img src="https://images.unsplash.com/photo-1600267185393-e158a98703de?crop=entropy&cs=srgb&fm=jpg&ixid=MnwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHx8MTYzMDgyNDc4Mw&ixlib=rb-1.2.1&q=85" alt="profile-image" />
                  </div>
                  <div class="card-info">
                    <p class="card-name">Digital Industry <br>Manufacturing Engg.</p>
                    <p class="card-name" ></p>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </article>
      </div>

      <div class="col-sm col-md col-lg">
        <article class="card-box">
          <a href="/health-care" class="clink">
            <div class="card-wrapper">
              <div class="card-image">
                <img
                  src="https://images.unsplash.com/photo-1630412500200-728764552dc5?crop=entropy&cs=srgb&fm=jpg&ixid=MnwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHx8MTYzMDgyNDM3Nw&ixlib=rb-1.2.1&q=85"
                  alt="card-image" />
              </div>
              <div class="card-details">
                <div class="card-meta">
                  <div class="user-profile-image">
                    <img src="https://images.unsplash.com/photo-1600267185393-e158a98703de?crop=entropy&cs=srgb&fm=jpg&ixid=MnwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHx8MTYzMDgyNDc4Mw&ixlib=rb-1.2.1&q=85" alt="profile-image" />
                  </div>
                  <div class="card-info">
                    <p class="card-name">Health Care</p>
                    <p class="card-name" ></p>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </article>
      </div>

      <div class="col-sm col-md col-lg">
        <article class="card-box">
          <a href="/services/web-development" class="clink">
            <div class="card-wrapper">
              <div class="card-image">
                <img src="/assets/images/web-dev/web-dev-banner.jpg" alt="card-image" />
              </div>
              <div class="card-details">
                <div class="card-meta">
                  <div class="user-profile-image">
                    <img src="https://images.unsplash.com/photo-1600267185393-e158a98703de?crop=entropy&cs=srgb&fm=jpg&ixid=MnwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHx8MTYzMDgyNDc4Mw&ixlib=rb-1.2.1&q=85" alt="profile-image" />
                  </div>
                  <div class="card-info">
                    <p class="card-name">Web Development</p>
                    <p class="card-name" ></p>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </article>
      </div>

      <div class="col-sm col-md col-lg">
        <article class="card-box">
          <a href="/product-design" class="clink">
            <div class="card-wrapper">
              <div class="card-image">
                <img src="/assets/images/designs/product-design.png" alt="card-image" />
              </div>
              <div class="card-details">
                <div class="card-meta">
                  <div class="user-profile-image">
                    <img src="https://images.unsplash.com/photo-1600267185393-e158a98703de?crop=entropy&cs=srgb&fm=jpg&ixid=MnwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHx8MTYzMDgyNDc4Mw&ixlib=rb-1.2.1&q=85" alt="profile-image" />
                  </div>
                  <div class="card-info">
                    <p class="card-name">Product Design</p>
                    <p class="card-name" ></p>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </article>
      </div>

    </div>
  </div>
</section>

Commented AEC-BIM Service

<section id="services" class="common-pad pt-5 pb-5">
  <div class="container">
    <div class="container-fluid main-cont">
      <div class="row news-row">
        <div class="container-fluid col-md-12 col-12 col-sm-6 justify-content-center">
          <div class="sec1 pb-3">
            <h1 class="dispay-3" id="section1">
              AEC-BIM
            </h1>
          </div>
          <div class="card-group">

            <div class="underlay">
              <div class="card">
                <div class="card-img-top" style="background-image: url('/assets/images/aec-bim.png')"></div>
                <div class="card-block p-3">
                  <h5 class="card-title" style="font-family: 'Anton', sans-serif">EPC/BIM
                    <hr>
                  </h5>
                  <p class="card-text">We are the pioneers in offering Design Support Services across the Global
                    Architecture, Engineering, and Construction Industry. Our team has Architects, Engineers, Interior
                    Designers, and CAD Technicians.<a href="services/epc-bim"><u>Read More...</u></a></p>
                </div>
              </div>
            </div>

            <div class="underlay">
              <div class="card">
                <div class="card-img-top" style="background-image: url('/assets/images/bim/2d-cad-detail.png')"></div>
                <div class="card-block p-3">
                  <h5 class="card-title" style="font-family: 'Anton', sans-serif">DETAILING SERVICES
                    <hr>
                  </h5>
                  <p class="card-text">Our Detailing services are on point, comprehensive and designed as required by
                    using our customers. Our up-to-date and licensed variant of quality service provides a detailed
                    picture of the present given structure. <a href="epc-bim/detailing-services"><u>Read More...</u></a>
                  </p>
                </div>
              </div>
            </div>

            <div class="underlay">
              <div class="card">
                <div class="card-img-top" style="background-image: url('/assets/images/redar.jpeg')"></div>
                <div class="card-block p-3">
                  <h5 class="card-title" style="font-family: 'Anton', sans-serif">REBAR DETAILING SERVICES
                    <hr>
                  </h5>
                  <p class="card-text">At MSAII Engineering, our rebar detailers generate shop drawings for the
                    placement of the reinforcing steel. We are a rebar detailing specialist gaining practical experience
                    in detailing with capacities to deliver on time, cost-effective pre and post bid rebar estimating
                    and detailing services.<a href="epc-bim/rebar-detailing-services"><u>Read More...</u></a></p>
                </div>
              </div>
            </div>

            <div class="underlay">
              <div class="card">
                <div class="card-img-top" style="background-image: url('/assets/images/3d-bim.png')"></div>
                <div class="card-block p-3">
                  <h5 class="card-title" style="font-family: 'Anton', sans-serif">3D BIM SERVICES
                    <hr>
                  </h5>
                  <p class="card-text">MSAII's Architectural BIM Services include creating building information models
                    from sketches, CAD drawings, and documentation in BIM, analysis, clash detection and content
                    creation. <a href="epc-bim/3d-bim-services"><u>Read More...</u></a></p>
                </div>
              </div>
            </div>

            <div class="underlay">
              <div class="card">
                <div class="card-img-top" style="background-image: url('/assets/images/bim.jpeg')"></div>
                <div class="card-block p-3">
                  <h5 class="card-title" style="font-family: 'Anton', sans-serif">Scan to BIM services
                    <hr>
                  </h5>
                  <p class="card-text">Our scan to BIM services provides you with an accurate digital representation of
                    the building site that can be used for every possible stage of the project from planning to design,
                    assessment and evaluation.<a href="epc-bim/scan-to-bim-services"><u>Read More...</u></a></p>
                  <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>

OLD AUTOMOTIVE SERVICES LIST

<section id="automotive" class="common-pad pt-5 pb-5">
  <div class="container">
    <div class="container-fluid main-cont">
      <div class="row news-row">
        <div class="container-fluid col-md-12 col-12 col-sm-6 justify-content-center">
          <div class="sec1 pb-3">
            <h1 class="dispay-3" id="section5">
              Automotive
            </h1>
          </div>
          <div class="card-group">

            <div class="underlay">
              <div class="card">
                <div class="card-img-top" style="background-image: url('/assets/images/auto_banner.png')"></div>
                <div class="card-block p-3">
                  <h5 class="card-title" style="font-family: 'Anton', sans-serif">Automotive
                    <hr>
                  </h5>
                  <p class="card-text">MSAII provides industrial design services for the automotive, consumer,
                    commercial, and healthcare industries. Our industrial design services combined with our engineering
                    expertise allow us to generate design solutions that are innovative and have fast-turnaround
                    development schedules.<a href="/services/automotive"><u>Read More...</u></a></p>
                  <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </div>
              </div>
            </div>


            <div class="underlay">
              <div class="card">
                <div class="card-img-top" style="background-image: url('/assets/images/designs/designs.png')"></div>
                <div class="card-block p-3">
                  <h5 class="card-title" style="font-family: 'Anton', sans-serif">Designs
                    <hr>
                  </h5>
                  <p class="card-text">MSAII provides industrial design services for the automotive, consumer,
                    commercial, and healthcare industries. Our industrial design services combined with our engineering
                    expertise allow us to generate design solutions that are innovative and have fast-turnaround
                    development schedules.<a href="/automotive/designs"><u>Read More...</u></a></p>
                  <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </div>
              </div>
            </div>
            <div class="underlay">
              <div class="card">
                <div class="card-img-top" style="background-image: url('/assets/images/embeded-system/banner3.png')">
                </div>
                <div class="card-block p-3">
                  <h5 class="card-title" style="font-family: 'Anton', sans-serif">Embedded Systems
                    <hr>
                  </h5>
                  <p class="card-text">MSAII Embedded System services provide an independent and objective measure of
                    applications or product's performance and quality.<a href="/automotive/embedded-systems"><u>Read
                        More...</u></a></p>
                  <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </div>
              </div>
            </div>
            <div class="underlay">
              <div class="card">
                <div class="card-img-top" style="background-image: url('/assets/images/plm.jpg')"></div>
                <div class="card-block p-3">
                  <h5 class="card-title" style="font-family: 'Anton', sans-serif">Product life cycle management
                    <hr>
                  </h5>
                  <p class="card-text">MSAII offer the best Product Lifecycle Management (PLM) software solutions and
                    Engineering Services for all ties of business to make better decisions on their product development,
                    management, design, and production through our dedicated PLM experts, tools and methods.<a
                      href="/automotive/product-life-cycle-management"><u>Read More...</u></a></p>
                  <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </div>
              </div>
            </div>
            <div class="underlay">
              <div class="card">
                <div class="card-img-top" style="background-image: url('/assets/images/3d-printing.png')"></div>
                <div class="card-block p-3">
                  <h5 class="card-title" style="font-family: 'Anton', sans-serif">3D Printing
                    <hr>
                  </h5>
                  <p class="card-text">Our comprehensive, rapid prototyping services can shorten the time it takes to
                    work through design iterations and hone in on any assembly issues. Rapid Prototyping reduces lead
                    times and helps control the overall cost of your product development.<a
                      href="/automotive/3d-printing"><u>Read More...</u></a></p>
                  <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </div>
              </div>
            </div>
            <div class="underlay">
              <div class="card">
                <div class="card-img-top" style="background-image: url('/assets/images/tool-die-3.jpg')"></div>
                <div class="card-block p-3">
                  <h5 class="card-title" style="font-family: 'Anton', sans-serif">Tool die
                    <hr>
                  </h5>
                  <p class="card-text">We specializes in engineering complex, high-precision dies for medium-to- large
                    parts in a wide variety of materials. Our Design and Engin3d_modelling team partners with customers
                    from concept development through production to ensure the optimal engineering solution and highest
                    level of satisfaction.<a href="/automotive/tool-die"><u>Read More...</u></a></p>
                </div>
              </div>
            </div>
            <div class="underlay">
              <div class="card">
                <div class="card-img-top" style="background-image: url('/assets/images/virtual.png')"></div>
                <div class="card-block p-3">
                  <h5 class="card-title" style="font-family: 'Anton', sans-serif">Virtual Validation
                    <hr>
                  </h5>
                  <p class="card-text">Virtual Performance include, etc., weight, body stiffness, body frequencies,
                    durability, and according to the Key Targets deployments prepared by Msaii.<a
                      href="/automotive/virtual-validation"><u>Read More...</u></a></p>
                  <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

OLD DIGITAL INDUSTRY ENGINEERING

<section id="digital-manufacturing" class="common-pad pb-5">
  <div class="container">
    <div class="container-fluid main-cont">
      <div class="row news-row">
        <div class="container-fluid col-md-12 col-12 col-sm-6 justify-content-center">
          <div class="sec1 pb-3">
            <h1 class="dispay-3" id="section6">
              Digital Industry Manufacturing Engineering
            </h1>
          </div>
          <div class="card-group">

            <div class="underlay">
              <div class="card">
                <div class="card-img-top"
                  style="background-image: url('/assets/images/industires/digital-industry-banner.png')"></div>
                <div class="card-block p-3">
                  <h5 class="card-title" style="font-family: 'Anton', sans-serif">Digital industry
                    <hr>
                  </h5>
                  <p class="card-text">Exponential technology changes such as the Internet of Things, Industry 4.0,
                    Pervasive Intelligence, “software defined,” and the physical and digital integration have shifted
                    expectations regarding product functionality.<a href="/services/digital-industry"><u>Read
                        More...</u></a></p>
                  <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </div>
              </div>
            </div>

            <div class="underlay">
              <div class="card">
                <div class="card-img-top" style="background-image: url('/assets/images/al-ml.png')"></div>
                <div class="card-block p-3">
                  <h5 class="card-title" style="font-family: 'Anton', sans-serif">AI & ML
                    <hr>
                  </h5>
                  <p class="card-text">Msaii makes it easy for businesses to realize true potential from Artificial
                    Intelligence (AI) and Machine Learning (ML). We help enterprise to build, adopt, utilize and
                    implement competent AI & ML solutions that drive better business outcomes.<a
                      href="/digital-industry/ai-ml"><u>Read More...</u></a></p>
                  <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </div>
              </div>
            </div>


            <div class="underlay">
              <div class="card">
                <div class="card-img-top" style="background-image: url('/assets/images/iot.png')"></div>
                <div class="card-block p-3">
                  <h5 class="card-title" style="font-family: 'Anton', sans-serif">IOT
                    <hr>
                  </h5>
                  <p class="card-text">The Internet of Things (IoT) is the network of physical devices which can be part
                    of vehicles, buildings, industries, hospitals and other items, and are embedded with electronics,
                    software, sensors, and network connectivity—that enables these devices to collect and exchange
                    data.<a href="/digital-industry/iot"><u>Read More...</u></a></p>
                  <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </div>
              </div>
            </div>
            <div class="underlay">
              <div class="card">
                <div class="card-img-top" style="background-image: url('/assets/images/industires/digital-banner.png')">
                </div>
                <div class="card-block p-3">
                  <h5 class="card-title" style="font-family: 'Anton', sans-serif">Semiconductor
                    <hr>
                  </h5>
                  <p class="card-text">Innovators are constantly pushing the limits of design. Manufacturing facilities
                    and OEMs are constantly trying to match their pace. Semiconductor companies are on the lookout for
                    partners capable to work in tandem and end-to-end to realize advanced, production ready chips. <a
                      href="servicepage/#section7"><u>Read More...</u></a></p>
                  <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </div>
              </div>
            </div>
            <div class="underlay">
              <div class="card">
                <div class="card-img-top" style="background-image: url('/assets/images/testing/software-testing.png')">
                </div>
                <div class="card-block p-3">
                  <h5 class="card-title" style="font-family: 'Anton', sans-serif">Software testing
                    <hr>
                  </h5>
                  <p class="card-text">MSAII offers complete Software testing services for global enterprises and across
                    the industries.<a href="/digital-industry/software-testing"><u>Read More...</u></a></p>
                  <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </div>
              </div>
            </div>

            <div class="underlay">
              <div class="card">
                <div class="card-img-top" style="background-image: url('/assets/images/ui-ux-1.jpg')"></div>
                <div class="card-block p-3">
                  <h5 class="card-title" style="font-family: 'Anton', sans-serif">UI & UX
                    <hr>
                  </h5>
                  <p class="card-text">MSAII offers complete Software testing services for global enterprises and across
                    the industries. We specialize in providing end-to-end application testing solutions, setting up
                    Testing Center of Excellence, QA strategies at optimal cost, desired Quality and ensure delivery at
                    the right time every time.<a href="/digital-industry/ui-ux"><u>Read More...</u></a></p>
                  <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

OLD WEB DEVELOPMENT SERVICES LIST

<section id="services" class="common-pad pt-5 pb-5">
  <div class="container">
    <div class="container-fluid main-cont">
      <div class="row news-row">
          <div class="container-fluid col-md-12 col-12 col-sm-6 justify-content-center">
            <div class="sec1 pb-3">
              <h1 class="dispay-3" id="section8">
                Web Development 
              </h1>
              </div>
              <div class="card-group">

                <div class="underlay">
                  <div class="card">
                    <div class="card-img-top" style="background-image: url('https://i.imgur.com/wLMJQPH.png')"></div>
                    <div class="card-block p-3" >
                    <h5 class="card-title" style="font-family: 'Anton', sans-serif">Canon Unveils New Lens<hr></h5>
                    <p class="card-text" >Canon will have a full slate of new and updated products to show attendees at this year’s NAB Show. The company has announced its new Compact-Servo 70-200mm telephoto zoom lens <a href="#"><u>Read More...</u></a></p>
                    <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                    </div>
                </div>
              </div>

              <div class="underlay">
                <div class="card">
                  <div class="card-img-top" style="background-image: url('https://i.imgur.com/wLMJQPH.png')"></div>
                  <div class="card-block p-3" >
                  <h5 class="card-title" style="font-family: 'Anton', sans-serif">Canon Unveils New Lens<hr></h5>
                  <p class="card-text" >Canon will have a full slate of new and updated products to show attendees at this year’s NAB Show. The company has announced its new Compact-Servo 70-200mm telephoto zoom lens <a href="#"><u>Read More...</u></a></p>
                  <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                  </div>
              </div>
            </div>
            

            <div class="underlay">
              <div class="card">
                <div class="card-img-top" style="background-image: url('https://i.imgur.com/wLMJQPH.png')"></div>
                <div class="card-block p-3" >
                <h5 class="card-title" style="font-family: 'Anton', sans-serif">Canon Unveils New Lens<hr></h5>
                <p class="card-text" >Canon will have a full slate of new and updated products to show attendees at this year’s NAB Show. The company has announced its new Compact-Servo 70-200mm telephoto zoom lens <a href="#"><u>Read More...</u></a></p>
                <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </div>
            </div>
          </div>
          <div class="underlay">
            <div class="card">
              <div class="card-img-top" style="background-image: url('https://i.imgur.com/wLMJQPH.png')"></div>
              <div class="card-block p-3" >
              <h5 class="card-title" style="font-family: 'Anton', sans-serif">Canon Unveils New Lens<hr></h5>
              <p class="card-text" >Canon will have a full slate of new and updated products to show attendees at this year’s NAB Show. The company has announced its new Compact-Servo 70-200mm telephoto zoom lens <a href="#"><u>Read More...</u></a></p>
              <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
              </div>
          </div>
        </div>
        <div class="underlay">
          <div class="card">
            <div class="card-img-top" style="background-image: url('https://i.imgur.com/wLMJQPH.png')"></div>
            <div class="card-block p-3" >
            <h5 class="card-title" style="font-family: 'Anton', sans-serif">Canon Unveils New Lens<hr></h5>
            <p class="card-text" >Canon will have a full slate of new and updated products to show attendees at this year’s NAB Show. The company has announced its new Compact-Servo 70-200mm telephoto zoom lens <a href="#"><u>Read More...</u></a></p>
            <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
            </div>
        </div>
      </div>
      <div class="underlay">
        <div class="card">
          <div class="card-img-top" style="background-image: url('https://i.imgur.com/wLMJQPH.png')"></div>
          <div class="card-block p-3" >
          <h5 class="card-title" style="font-family: 'Anton', sans-serif">Canon Unveils New Lens<hr></h5>
          <p class="card-text" >Canon will have a full slate of new and updated products to show attendees at this year’s NAB Show. The company has announced its new Compact-Servo 70-200mm telephoto zoom lens <a href="#"><u>Read More...</u></a></p>
          <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
          </div>
      </div>
    </div>
    <div class="underlay">
      <div class="card">
        <div class="card-img-top" style="background-image: url('https://i.imgur.com/wLMJQPH.png')"></div>
        <div class="card-block p-3" >
        <h5 class="card-title" style="font-family: 'Anton', sans-serif">Canon Unveils New Lens<hr></h5>
        <p class="card-text" >Canon will have a full slate of new and updated products to show attendees at this year’s NAB Show. The company has announced its new Compact-Servo 70-200mm telephoto zoom lens <a href="#"><u>Read More...</u></a></p>
        <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
        </div>
    </div>
  </div>
  <div class="underlay">
    <div class="card">
      <div class="card-img-top" style="background-image: url('https://i.imgur.com/wLMJQPH.png')"></div>
      <div class="card-block p-3" >
      <h5 class="card-title" style="font-family: 'Anton', sans-serif">Canon Unveils New Lens<hr></h5>
      <p class="card-text" >Canon will have a full slate of new and updated products to show attendees at this year’s NAB Show. The company has announced its new Compact-Servo 70-200mm telephoto zoom lens <a href="#"><u>Read More...</u></a></p>
      <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
      </div>
  </div>
</div>
</div>
</div>
</div>
</div>
</div>
</section>

OLD PRODUCT DESIGN SERVICES LIST

<section id="services" class="common-pad pt-5 pb-5">
  <div class="container">
    <div class="container-fluid main-cont">
      <div class="row news-row">
          <div class="container-fluid col-md-12 col-12 col-sm-6 justify-content-center">
            <div class="sec1 pb-3">
              <h1 class="dispay-3" id="section9">
                Product Design
              </h1>
              </div>
              <div class="card-group">

                <div class="underlay">
                  <div class="card">
                    <div class="card-img-top" style="background-image: url('https://i.imgur.com/wLMJQPH.png')"></div>
                    <div class="card-block p-3" >
                    <h5 class="card-title" style="font-family: 'Anton', sans-serif">Canon Unveils New Lens<hr></h5>
                    <p class="card-text" >Canon will have a full slate of new and updated products to show attendees at this year’s NAB Show. The company has announced its new Compact-Servo 70-200mm telephoto zoom lens <a href="#"><u>Read More...</u></a></p>
                    <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                    </div>
                </div>
              </div>

              <div class="underlay">
                <div class="card">
                  <div class="card-img-top" style="background-image: url('https://i.imgur.com/wLMJQPH.png')"></div>
                  <div class="card-block p-3" >
                  <h5 class="card-title" style="font-family: 'Anton', sans-serif">Canon Unveils New Lens<hr></h5>
                  <p class="card-text" >Canon will have a full slate of new and updated products to show attendees at this year’s NAB Show. The company has announced its new Compact-Servo 70-200mm telephoto zoom lens <a href="#"><u>Read More...</u></a></p>
                  <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                  </div>
              </div>
            </div>
            

            <div class="underlay">
              <div class="card">
                <div class="card-img-top" style="background-image: url('https://i.imgur.com/wLMJQPH.png')"></div>
                <div class="card-block p-3" >
                <h5 class="card-title" style="font-family: 'Anton', sans-serif">Canon Unveils New Lens<hr></h5>
                <p class="card-text" >Canon will have a full slate of new and updated products to show attendees at this year’s NAB Show. The company has announced its new Compact-Servo 70-200mm telephoto zoom lens <a href="#"><u>Read More...</u></a></p>
                <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </div>
            </div>
          </div>
          <div class="underlay">
            <div class="card">
              <div class="card-img-top" style="background-image: url('https://i.imgur.com/wLMJQPH.png')"></div>
              <div class="card-block p-3" >
              <h5 class="card-title" style="font-family: 'Anton', sans-serif">Canon Unveils New Lens<hr></h5>
              <p class="card-text" >Canon will have a full slate of new and updated products to show attendees at this year’s NAB Show. The company has announced its new Compact-Servo 70-200mm telephoto zoom lens <a href="#"><u>Read More...</u></a></p>
              <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
              </div>
          </div>
        </div>
        <div class="underlay">
          <div class="card">
            <div class="card-img-top" style="background-image: url('https://i.imgur.com/wLMJQPH.png')"></div>
            <div class="card-block p-3" >
            <h5 class="card-title" style="font-family: 'Anton', sans-serif">Canon Unveils New Lens<hr></h5>
            <p class="card-text" >Canon will have a full slate of new and updated products to show attendees at this year’s NAB Show. The company has announced its new Compact-Servo 70-200mm telephoto zoom lens <a href="#"><u>Read More...</u></a></p>
            <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
            </div>
        </div>
      </div>
      <div class="underlay">
        <div class="card">
          <div class="card-img-top" style="background-image: url('https://i.imgur.com/wLMJQPH.png')"></div>
          <div class="card-block p-3" >
          <h5 class="card-title" style="font-family: 'Anton', sans-serif">Canon Unveils New Lens<hr></h5>
          <p class="card-text" >Canon will have a full slate of new and updated products to show attendees at this year’s NAB Show. The company has announced its new Compact-Servo 70-200mm telephoto zoom lens <a href="#"><u>Read More...</u></a></p>
          <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
          </div>
      </div>
    </div>
    <div class="underlay">
      <div class="card">
        <div class="card-img-top" style="background-image: url('https://i.imgur.com/wLMJQPH.png')"></div>
        <div class="card-block p-3" >
        <h5 class="card-title" style="font-family: 'Anton', sans-serif">Canon Unveils New Lens<hr></h5>
        <p class="card-text" >Canon will have a full slate of new and updated products to show attendees at this year’s NAB Show. The company has announced its new Compact-Servo 70-200mm telephoto zoom lens <a href="#"><u>Read More...</u></a></p>
        <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
        </div>
    </div>
  </div>
  <div class="underlay">
    <div class="card">
      <div class="card-img-top" style="background-image: url('https://i.imgur.com/wLMJQPH.png')"></div>
      <div class="card-block p-3" >
      <h5 class="card-title" style="font-family: 'Anton', sans-serif">Canon Unveils New Lens<hr></h5>
      <p class="card-text" >Canon will have a full slate of new and updated products to show attendees at this year’s NAB Show. The company has announced its new Compact-Servo 70-200mm telephoto zoom lens <a href="#"><u>Read More...</u></a></p>
      <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
      </div>
  </div>
</div>
</div>
</div>
</div>
</div>
</div>
</section> 

OLD SEMICONDUCTOR SERVICE LIST

<section id="semiconductor" class="common-pad pb-5">
  <div class="container">
    <div class="container-fluid main-cont">
      <div class="row news-row">
        <div class="container-fluid col-md-12 col-12 col-sm-6 justify-content-center">
          <div class="sec1 pb-3">
            <h1 class="dispay-3" id="section7">
              Semiconductor
            </h1>
          </div>
          <div class="card-group">

            <div class="underlay">
              <div class="card">
                <div class="card-img-top" style="background-image: url('https://i.imgur.com/wLMJQPH.png')"></div>
                <div class="card-block p-3">
                  <h5 class="card-title" style="font-family: 'Anton', sans-serif">Canon Unveils New Lens
                    <hr>
                  </h5>
                  <p class="card-text">Canon will have a full slate of new and updated products to show attendees at
                    this year’s NAB Show. The company has announced its new Compact-Servo 70-200mm telephoto zoom lens
                    <a href="#"><u>Read More...</u></a>
                  </p>
                  <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </div>
              </div>
            </div>

            <div class="underlay">
              <div class="card">
                <div class="card-img-top" style="background-image: url('https://i.imgur.com/wLMJQPH.png')"></div>
                <div class="card-block p-3">
                  <h5 class="card-title" style="font-family: 'Anton', sans-serif">Canon Unveils New Lens
                    <hr>
                  </h5>
                  <p class="card-text">Canon will have a full slate of new and updated products to show attendees at
                    this year’s NAB Show. The company has announced its new Compact-Servo 70-200mm telephoto zoom lens
                    <a href="#"><u>Read More...</u></a>
                  </p>
                  <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </div>
              </div>
            </div>


            <div class="underlay">
              <div class="card">
                <div class="card-img-top" style="background-image: url('https://i.imgur.com/wLMJQPH.png')"></div>
                <div class="card-block p-3">
                  <h5 class="card-title" style="font-family: 'Anton', sans-serif">Canon Unveils New Lens
                    <hr>
                  </h5>
                  <p class="card-text">Canon will have a full slate of new and updated products to show attendees at
                    this year’s NAB Show. The company has announced its new Compact-Servo 70-200mm telephoto zoom lens
                    <a href="#"><u>Read More...</u></a>
                  </p>
                  <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </div>
              </div>
            </div>
            <div class="underlay">
              <div class="card">
                <div class="card-img-top" style="background-image: url('https://i.imgur.com/wLMJQPH.png')"></div>
                <div class="card-block p-3">
                  <h5 class="card-title" style="font-family: 'Anton', sans-serif">Canon Unveils New Lens
                    <hr>
                  </h5>
                  <p class="card-text">Canon will have a full slate of new and updated products to show attendees at
                    this year’s NAB Show. The company has announced its new Compact-Servo 70-200mm telephoto zoom lens
                    <a href="#"><u>Read More...</u></a>
                  </p>
                  <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </div>
              </div>
            </div>
            <div class="underlay">
              <div class="card">
                <div class="card-img-top" style="background-image: url('https://i.imgur.com/wLMJQPH.png')"></div>
                <div class="card-block p-3">
                  <h5 class="card-title" style="font-family: 'Anton', sans-serif">Canon Unveils New Lens
                    <hr>
                  </h5>
                  <p class="card-text">Canon will have a full slate of new and updated products to show attendees at
                    this year’s NAB Show. The company has announced its new Compact-Servo 70-200mm telephoto zoom lens
                    <a href="#"><u>Read More...</u></a>
                  </p>
                  <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </div>
              </div>
            </div>
            <div class="underlay">
              <div class="card">
                <div class="card-img-top" style="background-image: url('https://i.imgur.com/wLMJQPH.png')"></div>
                <div class="card-block p-3">
                  <h5 class="card-title" style="font-family: 'Anton', sans-serif">Canon Unveils New Lens
                    <hr>
                  </h5>
                  <p class="card-text">Canon will have a full slate of new and updated products to show attendees at
                    this year’s NAB Show. The company has announced its new Compact-Servo 70-200mm telephoto zoom lens
                    <a href="#"><u>Read More...</u></a>
                  </p>
                  <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </div>
              </div>
            </div>
            <div class="underlay">
              <div class="card">
                <div class="card-img-top" style="background-image: url('https://i.imgur.com/wLMJQPH.png')"></div>
                <div class="card-block p-3">
                  <h5 class="card-title" style="font-family: 'Anton', sans-serif">Canon Unveils New Lens
                    <hr>
                  </h5>
                  <p class="card-text">Canon will have a full slate of new and updated products to show attendees at
                    this year’s NAB Show. The company has announced its new Compact-Servo 70-200mm telephoto zoom lens
                    <a href="#"><u>Read More...</u></a>
                  </p>
                  <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </div>
              </div>
            </div>
            <div class="underlay">
              <div class="card">
                <div class="card-img-top" style="background-image: url('https://i.imgur.com/wLMJQPH.png')"></div>
                <div class="card-block p-3">
                  <h5 class="card-title" style="font-family: 'Anton', sans-serif">Canon Unveils New Lens
                    <hr>
                  </h5>
                  <p class="card-text">Canon will have a full slate of new and updated products to show attendees at
                    this year’s NAB Show. The company has announced its new Compact-Servo 70-200mm telephoto zoom lens
                    <a href="#"><u>Read More...</u></a>
                  </p>
                  <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

-->