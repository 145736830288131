<section id="whoweare" class="text-white min-banner pt-3 pb-3">
  <div class="container">
    <div class="row ">
      <div class="col-lg-12 whoweareDiv">
        <div class="banner-text">
          <h1 data-aos="fade-up" data-aos-duration="2000"><span class="banner-no-bold">technology Experts</span> at your service</h1>
          <p class="py-4" data-aos="fade-up" data-aos-duration="3000">Multidisciplinary professionals
            in research and continued development of.
            innovation solution in line with the digital
            transformation of business processes.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="ourvalues">
  <div class="head text-center mt-5 mb-5">
    <p class="mb-1 title-heading">We Work In An Exclusively Collaborative Manner</p>
    <p class="description">MSAII is a modern engineering company,dedicated to providing high quality Product design,
      development and Process for the engineering industry
    </p>
  </div>
  <div class="body" >
        <div class="left order-2 order-lg-1 order-xl-1 order-xll-1">
            <div class="img_wrapper  ">
              <!-- <img src="assets/about_image/first_img.jpg" alt=""> -->
             <img src="/assets/homeimages/ourvalueimage-11.png" alt=""> 


              <!-- <div class="overlay-blue"></div> -->
            </div>
        </div>
        <div class="right order-1 order-lg-2 order-xl-2 order-xll-2">
            <p class="title-bold text-center mb-4">Our Values</p>
            <p class="title-description ">In a rapidly evloving word , at MASII , <br>  We are focused on building 
              a world that <br> works better.
            </p>
        </div>
  </div>
</section>

<section id="mission_vision">
  <div class="row">
      <div class="col-lg-6 first-col order-2 order-lg-1 order-xl-1 order-xll-1 pr-5">
        <div class="top_img">
          <div class="img_wrapper">
            <img src="assets/about_image/second_img.jpg" alt="">
          </div>
        </div>
        <div class="bottom_img">
          <div class="img_wrapper">
            <img src="assets/about_image/thried_img.jpg" alt="">
          </div>
        </div>
      </div>
      <div class="col-lg-6 second-col order-1 order-lg-2 order-xl-2 order-xll-2">
        <div class="mission">
          <p class="title-bold">Mission</p>
          <p class="title-description-two">The decisions made by companies will have a direct impact
            on <span class="title-description-bold">diffrentiation, growth</span> and <span class="title-description-bold">
              scalability, profitability, risk containment, customer satisfaction
            </span> and <span class="title-description-bold">time to market </span>of their products or services.Choosing the right IT
            solutions and the right technology partners guarantees a greater probability of success.
            <span class="title-description-bold">MASSII</span> research market segments where to implement
            <span class="title-description-bold">Innovative Sollutions.</span> Which can reduce the adoption
            time and improve the effectiveness and cost-effectiveness of the technology     
          </p>
        </div>
        <div class="vision">
          <p class="title-bold">Vision</p>
          <p class="title-description-two">
            Strategy and technology come together to create unique digital experience.     
          </p>
        </div>
      </div>
  </div>
</section>

<section id="whychoose" style="margin-top: 45px;" >
  <p class="title-heading text-center title-bold ">Why choose MSAII</p>
  <div class="content d-flex flex-column justify-content-center">
    <div class="row">
      <div class="col-lg-6 col-xl-6 col-xll-6 col-12">
          <div class="feature-content d-flex align-items-center mb-5">
              <div class="icon">
                <img src="assets/about_image/about_image_1.png" alt="">
              </div>
              <p class="feature-title">Our team of experts<br/>is at your disposal.</p>
          </div>
          <div class="feature-content d-flex align-items-center mb-5">
            <div class="icon">
              <img src="assets/about_image/about_image_2.png" alt="">
            </div>
            <p class="feature-title">Innovative solutions.</p>
          </div>
          <div class="feature-content d-flex align-items-center">
            <div class="icon">
              <img src="assets/about_image/about_image_3.png" alt="">
            </div>
            <p class="feature-title">Flexibility and Timeliness<br/> in implementation.</p>
          </div>
      </div>
      <div class="col-lg-6 col-xl-6 col-xll-6 col-12">

      </div>
    </div>
  </div>
</section>

<section id="Headquarters">
    <div class="row"> 
      <div class="col-lg-6 col-xl-6 col-xll-6 col-12 d-flex flex-column justify-content-center mb-5">
        <div class="headquarters-details">
          <p class="title">Headquarters</p>
          <p class="location">Columbus/Ohio</p>
          <p class="description mb-0">MSAII is Located in the center of north america it is strategically positioned to fulfill the demands of our clients.
            Whithot a doubt a high-tech modern building surrounded by a forest provides the inspiration and 
            contemporary needs of a Industry.</p>
        </div>
      </div>
      <div class="col-lg-6 col-xl-6 col-xll-6 col-12 right">
        <div class="img_wrapper">
        </div>
      </div>
    </div>
</section>