<section id="eng-banner" class="min-banner text-white overlay">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="banner-text">
            <h1>Engineering</h1>
            <p>Our team is passionate about delivering results</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="pt-5 pb-5 design-content">
    <div class="container">
        <div class="row">
            <div class="col-12">
              <p>msaii Engineering offered the product development and process engineering services, prototyping & experimentation, Proof of concept, analysis and research aiming to provide full vehicle turnkey and package engineering services.</p>
              <p>As a service provider we have engineers located in USA, CANADA and INDIA, msaii Engineering is able to deliver the work packages, executing the entire product and process development and offering a complete service across the following domains.</p>
              <img src="assets/images/designs/eng-2.jpg" class="img-fluid b-radius w-50">
              <div class="pt-5">
              <h1>Our Approach</h1>
              <p>It is well-designed business process based on gates, stages, milestones and deliverables, to control technical and performance accomplishment, time, cost and to ensure robustness in development. It is exceedingly flexible and allows an easily association with any OEM’s process.</p>
              <p>Some of Our core capabilities:</p>
              <ul>
                  <li>Actual design, package investigation and basic architecture</li>
                  <li>Product Benchmarking & Feasibility Studies</li>
                  <li>Concept classification and development</li>
                  <li>Complete Body Engineering (BIW, Exteriors, Interiors, Chassis, etc.)</li>
                  <li>Powertrain (ICE, EV, HYBRID) system integration</li>
                  <li>Chassis system integration</li>
                  <li>Prototypes Manufacturing</li>
                  <li>Homologation and legislation checks</li>
                  <li>Product Quality control</li>
                  <li>Program management</li>
              </ul>
            </div>
            </div>
        </div>
    </div>
  </section>
  <section class="industries-main designs">
    <div class="container">
      <h1 class="py-2">Other services</h1>
      <div class="row">
        <div class="col-lg-4 mb-5">
            <div class="indust-wrap">
                <a [routerLink]="['/','product-design']">
                    <img src="assets/images/designs/product-design.png" alt="">
                    <h3>Product design</h3>
                </a>
            </div>
        </div>
        <div class="col-lg-4 mb-5">
          <div class="indust-wrap">
            <a [routerLink]="['/','competency-development']">
                <img src="assets/images/designs/competancy.png" alt="">
                <h3>Competancy Development</h3>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-cover mb-4 industries-main">
    <div class="container">
      <h1 class="pb-2">Industries</h1>
      <div class="row mb-5">
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/auto-1.png" alt="">
            <div class="indust-info">
              <h5>Automotive Industry</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/', 'services','automotive']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/auto-2.png" alt="">
            <div class="indust-info">
              <h5>Digital Manufacturing Industry</h5>
              <p>Digital manufacturing is an integral part of product lifecycle management (PLM)</p>
              <a [routerLink]="['/','services', 'digital-industry']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/industires/semiconductor.png" alt="">
            <div class="indust-info">
              <h5>semiconductor</h5>
              <p>A semiconductor material has an electrical conductivity value falling between that of a conductor</p>
              <a [routerLink]="['/', 'digital-industry', 'semiconductor']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row pb-5">
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/industires/webdevelop.png" alt="">
            <div class="indust-info">
              <h5>Web development</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','services','web-development']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/aec-bim.jpg" alt="">
            <div class="indust-info">
              <h5>AEC/BIM</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','services','epc-bim']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/aerospace.png" alt="">
            <div class="indust-info">
              <h5>Aerospace</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of Aerospace.</p>
              <a [routerLink]="['/','services','aerospace']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/energy.png" alt="">
            <div class="indust-info">
              <h5>Energy</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','services','energy']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="indust-wrap">
            <img src="assets/images/waste-water-treatment.png" alt="">
            <div class="indust-info">
              <h5>Waste Water Treatment</h5>
              <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance of automobiles.</p>
              <a [routerLink]="['/','services','waste-water-treatment']" class="know-more">KNOW MORE <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        </div>
    </div>
  </section>
