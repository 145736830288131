import { Component } from '@angular/core';

@Component({
  selector: 'app-news-and-insights',
  templateUrl: './news-and-insights.component.html',
  styleUrls: ['./news-and-insights.component.css']
})
export class NewsAndInsightsComponent {

}
