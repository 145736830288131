import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-three-d-bim-services',
  templateUrl: './three-d-bim-services.component.html',
  styleUrls: ['./three-d-bim-services.component.css']
})
export class ThreeDBimServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
