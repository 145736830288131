import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-embedded-systems',
  templateUrl: './embedded-systems.component.html',
  styleUrls: ['./embedded-systems.component.css']
})
export class EmbeddedSystemsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
