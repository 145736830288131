<section id="iot-banner" class="min-banner overlay">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="banner-text">
          <h1>Internet of Things</h1>
        </div>
      </div>
    </div>
  </div>
</section>
<section class=" pb-5 design-content pb-0">
  <div class="container">
    <div class="row pb-5">
      <div class="col-12 pt-5">
        <b>
          <h3>The Internet of Things (IoT) is the network of physical devices which can be part of vehicles, buildings,
            industries, hospitals and other items, and are embedded with electronics, software, sensors, and network
            connectivity—that enables these devices to collect and exchange data.</h3>
        </b>
        <!-- <img src="assets/images/iot_1.jpg" class="b-radius img-fluid w-50" alt=""> -->
        <div class="ig">
          <img src="assets/images/IOT_application.png" class="b-radius img-fluid " alt="infographic">

        </div>
        <div class="pt-5">
          <h3>IOT Engineering Services at MSAII</h3>
          <p class="pt-2">MSAII is a leading IOT Engineering services provider to clients across the globe. Our
            expertise in design and development of IOT solutions helped clients to focus on "What to achieve" rather
            "How to achieve". MSAII IOT engineering services includes,</p>
        </div>
      </div>
    </div>
    <div class="row pb-4 responsive">

      <div class="col-6">
        <div class="responsive c1">
          <h3><b>Firmware Development Engineering</b></h3>
          <p class="pt-3"> MSAII expertise with years of experience on firmware design and development on industry wide
            hardware devices stands us out as a top notch embedded system development service provider. Our experience
            include firmware development on bare metal devices, linux device driver development, BSP development,
            middleware development for peripherals including USB, Ethernet, Serial, I2C, SPI, CAN, Wi-Fi, Bluetooth etc.
          </p>
        </div>
      </div>
      <div class="col-6">
        <div class="responsive c1">
          <h3><b>IOT Product Engineering Services</b></h3>
          <p class="pt-3"> The Internet of Things (IOT) echo system consists of various components which includes
            sensors, controllers, gateways, connectivity, software platforms etc. MSAII expertise to select correct
            combination of hardware and software keeping in mind business applications and budgetary requirements helps
            end clients to meet their end goals with utmost user’s satisfaction.</p>
        </div>
      </div>

    </div>

    <div class="row pb-5 responsive">

      <div class="col-6">
        <div class="responsive c1">
          <h3><b>IOT Platform Integration</b></h3>
          <p class="pt-3">IOT platforms allow easy provisioning and configuration of number of IOT devices. It also
            allows status and statistics management of IOT devices, Over-the-air release upgrade and data analytics
            services with inbuilt security mechanisms which is key to remote device management. MSAII’s experience in
            IOT engineering specializes in integration with IOT platforms like AWS, Microsoft Azure, Google Cloud and
            more.</p>
        </div>
      </div>
      <div class="col-6">
        <div class="responsive c1">
          <h3><b>Web-service API development</b></h3>
          <p class="pt-3">Web Interface to communicate with IOT device requires specialized focus. Designing such web
            interface would require expert level understanding of IOT device generating data at higher rate and light
            weight communication to cater large number of devices. Our Web service API's are based on standards
            like MQTT, HTTP/REST, CoAP, SOAP, and Web sockets etc.</p>
        </div>
      </div>

    </div>

    <h3 class="pt-2">Internet of Things</h3>
    <p class="pt-2">The heart of the Internet of Things system is a platform, and nowadays, we have got a few of those.
      That is why it is so important to choose the right mechanism, which will allow achieving goals related to a
      project. What is IoT platform? It is a kind of connector which makes cooperation between all the elements included
      in the IoT system easier. It is the thing that makes it possible for communication, management, analytics,
      visualisation or databases to be used by means of only one tool- IoT platform.
    </p>
    <p class="pt-3">Find out an opportunity to cooperate with MSAII !</p>
    <h3 class="pt-2">The quality of work is the key to success!</h3>
    <p class="pt-2">Proper research, setting goals, and implementation process along with analysis of the entered data
      are significant elements which contribute to IoT project success. We understand that and that is why we put
      special emphasis on accurate problem recognition and setting the right goals. This makes it much easier to built
      and optimise a tool which matches the needs of our clients. As we apply all the above mentioned elements
      meticulously, we guarantee efficiency and a high standard of our services.</p>

  </div>
</section>

<section id="core-services" class="common-pad pt-5 pb-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="section-title mb-5">
          <h1>Digital Industry Services</h1>
          <p>MSAII is a modern engineering company, dedicated to providing high quality design and product development
            services for the engineering industry.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <div class="core-services-wrap">
          <a [routerLink]="['/','digital-industry', 'ai-ml']">
            <img src="assets/images/al-ml.png" class="img-fluid" alt="">
            <div class="lato-bold">AI / ML</div>
          </a>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="core-services-wrap">
          <a [routerLink]="['/', 'digital-industry', 'semiconductor']">
            <img src="assets/images/industires/digital-banner.png" alt="digital banner">
            <div class="lato-bold">Semiconductor</div>
          </a>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="core-services-wrap">
          <a [routerLink]="['/','digital-industry', 'ui-ux']">
            <img src="assets/images/ui-ux-3.jpg" alt="ui-ux">
            <div class="lato-bold">UI / UX</div>
          </a>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-lg-4">
        <div class="core-services-wrap">
          <a [routerLink]="['/', 'digital-industry','software-testing']">
            <img src="assets/images/testing/software-testing.png" alt="software-testing">
            <div class="lato-bold">Software Testing</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>


<style>
  .c1 {
    width: auto;
    min-height: 300px;
    background-color: #F7F9FD;
    padding: 20px;
  }

  .c1:hover {
    box-shadow: 0px 6px 11px rgba(52, 87, 220, 0.116876);
    /* border: 2.4px solid #00B7D5; */
    box-sizing: border-box;
  }

  .ig {
    text-align: center;
  }
</style>
