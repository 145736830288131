/**
 * [Deprecated] This component is deprecated.
 * Deprecated Code, not used in navbar
 */
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-headers',
  templateUrl: './headers.component.html',
  styleUrls: ['./headers.component.css']
})
export class HeadersComponent implements OnInit {
  scroll:boolean=false;
  collapsed = true;
  constructor() { }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollEvent, true);
  }

  scrollEvent = (event: any): void => {
    let sc = event.target.scrollingElement.scrollTop;
    var element:any = document.getElementsByClassName('navbar-expand-sm ');
    if(sc >=200){
      let imgElement:HTMLImageElement  = document.getElementById("logo-nav-top")! as HTMLImageElement;
      imgElement.src = "assets/images/msaii-logo-darkblue.png";
      element[0].style.background = "#FFFFFF";
      Array.from(document.querySelectorAll(".navbar-nav .nav-item .nav-link")! as NodeListOf<HTMLElement>).forEach(_element => {
        _element.style.color = "#000000"
      })
    }
    if ((sc ==0)){
      element[0].style.background = "";
      Array.from(document.querySelectorAll(".navbar-nav .nav-item .nav-link")! as NodeListOf<HTMLElement>).forEach(_element => {
        _element.style.color = "#FFFFFF"
      })
      let imgElement: HTMLImageElement = document.getElementById("logo-nav-top")! as HTMLImageElement;
      imgElement.src = "assets/images/msaii-logo.png";
    }
  }

}
