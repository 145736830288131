import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-read-more',
  template: `
    <div [class.collapsed]="isCollapsed">
      <p class="careers-description">
        {{ isCollapsed ? truncatedText: text }}
        <button *ngIf="showReadMore" (mouseover)="toggleReadMore()" class="read">
          {{ isCollapsed ? '...Show more' : 'Show less' }}
        </button>
      </p>
    </div>
  `,
  
  styleUrls: ['./read-more.component.css'],
})
export class ReadMoreComponent implements OnChanges {
  @Input() text: string = '';
  showReadMore: boolean = false;
  isCollapsed: boolean = true;
  truncatedText: string = ''; // Variable to store truncated text

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['text']) {
      this.truncatedText = this.text.substring(0, 200); // Truncate the text to 135 characters
      if (this.text.length > 200) {
        this.showReadMore = true; // Show "Read more" button if text length is greater than 135
      }
    }
  }

  toggleReadMore(): void {
    this.isCollapsed = !this.isCollapsed;
  }
}
