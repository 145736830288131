<section id="industries-banner" class="min-banner text-white overlay pb-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 industries__div">
        <div class="banner-text industries__title">
          <h1 class="industries_one" data-aos="fade-up" data-aos-duration="2000">Technology - Digital Strategies</h1>
          <p class="industries_two" data-aos="fade-up" data-aos-duration="3000">Our team is passionate about
            delivering results</p>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="getintouch" class="common-pad pt-5 pb-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-10">
        <div class="industry_services_text">
          <h1 class="mb-4" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0">We are specialized in industries</h1>
          <p data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0">Msaii strives to make a meaningful contribution to the long-term prosperity of our clients, we
            enjoy working with quality clients on quality projects because we are motivated to bring our best
            work and always keyed up for more.</p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="bg-cover mb-4 pb-5 industries-main">
  <div class="container">
    <h1 class="pb-3 mb-3 indust__heading" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0">Industries</h1>

    <div class="row">

      <!-- AUTOMOTIVE -->
      <div class="col-lg-4 ind__services" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="0">
        <div class="indust-wrap">
          <img src="assets/images/auto-1.png" alt="">
          <div class="indust-info">
            <h5>Automotive Industry</h5>
            <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance
              of automobiles.</p>
            <a [routerLink]="['/', 'services','automotive']" class="know-more">KNOW MORE <i class="fa fa-arrow-right"
                aria-hidden="true"></i></a>
          </div>
        </div>
      </div>

      <!-- AEROSPACE -->
      <div class="col-lg-4 ind__services" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="0">
        <div class="indust-wrap">
          <img src="assets/images/aerospace.png" alt="">
          <div class="indust-info">
            <h5>Aerospace</h5>
            <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance
              of Aerospace.</p>
            <a [routerLink]="['/','services','aerospace']" class="know-more">KNOW MORE <i class="fa fa-arrow-right"
                aria-hidden="true"></i></a>
          </div>
        </div>
      </div>

      <!-- DIGITAL MANUFACTURING -->
      <div class="col-lg-4 ind__services" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="0">
        <div class="indust-wrap">
          <img src="assets/images/auto-2.png" alt="">
          <div class="indust-info">
            <h5>Digital Manufacturing Industry</h5>
            <p>Digital manufacturing is an integral part of product lifecycle management (PLM)</p>
            <a [routerLink]="['/','services', 'digital-industry']" class="know-more">KNOW MORE <i
                class="fa fa-arrow-right" aria-hidden="true"></i></a>
          </div>
        </div>
      </div>

    </div>

    <div class="row pb-5">

      <!-- SEMICONDUCTOR -->
      <div class="col-lg-4 ind__services" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="0">
        <div class="indust-wrap">
          <img src="assets/images/industires/semiconductor.png" alt="">
          <div class="indust-info">
            <h5>semiconductor</h5>
            <p>A semiconductor material has an electrical conductivity value falling between that of a conductor</p>
            <a [routerLink]="['/', 'digital-industry', 'semiconductor']" class="know-more">KNOW MORE <i
                class="fa fa-arrow-right" aria-hidden="true"></i></a>
          </div>
        </div>
      </div>

      <!-- WEB DEVELOPMENT -->
      <div class="col-lg-4 ind__services" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="0">
        <div class="indust-wrap">
          <img src="assets/images/industires/webdevelop.png" alt="">
          <div class="indust-info">
            <h5>Web development</h5>
            <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance
              of automobiles.</p>
            <a [routerLink]="['/','services','web-development']" class="know-more">KNOW MORE <i
                class="fa fa-arrow-right" aria-hidden="true"></i></a>
          </div>
        </div>
      </div>

      <!-- ENERGY -->
      <div class="col-lg-4 ind__services" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="0">
        <div class="indust-wrap">
          <img src="assets/images/energy.png" alt="">
          <div class="indust-info">
            <h5>Energy</h5>
            <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance
              of automobiles.</p>
            <a [routerLink]="['/','services','energy']" class="know-more">KNOW MORE <i class="fa fa-arrow-right"
                aria-hidden="true"></i></a>
          </div>
        </div>
      </div>

    </div>

    <!-- <div class="row">

      AEC/BIM
      <div class="col-lg-4">
        <div class="indust-wrap">
          <img src="assets/images/aec-bim.jpg" alt="">
          <div class="indust-info">
            <h5>AEC/BIM</h5>
            <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance
              of automobiles.</p>
            <a [routerLink]="['/','services','epc-bim']" class="know-more">KNOW MORE <i class="fa fa-arrow-right"
                aria-hidden="true"></i></a>
          </div>
        </div>
      </div>

      WASTE WATER TREATMENT
      <div class="col-lg-4">
        <div class="indust-wrap">
          <img src="assets/images/waste-water-treatment.png" alt="">
          <div class="indust-info">
            <h5>Waste Water Treatment</h5>
            <p>The global automotive industry is always in flux, as new models & designs alter the shape and performance
              of automobiles.</p>
            <a [routerLink]="['/','services','waste-water-treatment']" class="know-more">KNOW MORE <i
                class="fa fa-arrow-right" aria-hidden="true"></i></a>
          </div>
        </div>
      </div>

    </div> -->

  </div>
</section>