<section id="contact-us" class="text-white min-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 contactUsDiv">
                <div class="banner-text">
                    <h1 class="contact__one lato-bold" data-aos="fade-up" data-aos-duration="2000">
                        We love meeting new people
                    </h1>
                    <p class="contact__two lato-reg" data-aos="fade-up" data-aos-duration="3000">
                        Let us know how we can help your next project
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="keepInTouch" class="pt-5 mb-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 getInTouchCol">
                <div class="get-in-touch">
                    <h2 class="lato-reg mb-4" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0">Keep in
                        touch</h2>
                    <p class="p1 lato-reg" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0">We build
                        identities and experiences to elevate & empower organizations.</p>
                    <p class="p2 lato-reg" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0">We are your
                        conduit to the most of new cutting-edge technological innovations. We
                        deliver unparalleled value to our clients, who rely on our expertise and many years of
                        experience in Managed Team Extension and AgileSquads.</p>
                </div>
            </div>
            <div class="col-lg-5 keepInTouch__Image" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0">
            </div>
        </div>
    </div>
</section>

<section class="office-location pt-5 pb-5">
    <div class="blog-1__container container has-anim" data-anim="group">
        <div class="row">
            <div class="col-lg-7">
                <div data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0">
                    <h1 class="blog-1__title blog-1__title_dark mb-4 h1"
                        data-anim-parent-trigger="data-anim-parent-trigger" data-mob-anim="group"
                        data-mob-anim-stagger="fade-top" style="transform: matrix(1, 0, 0, 1, 0, 0); opacity: 1;">
                        Head Office - USA (OHIO)
                    </h1>
                </div>

                <div class="blog-1__row col__wrap col__wrap_without-caption has-anim pb-3" data-anim="group"
                    data-anim-delay=".1" data-anim-duration=".2">

                    <div class="left-Contact blog-1__col col__small ph_mail">

                        <div class="blog-1__text py-2 lato-reg" data-aos="fade-up" data-aos-duration="1000"
                            data-aos-offset="0">6660 N, High Street, Worthington, OH, 43085</div>

                        <div class="lato-reg" style="transform: matrix(1, 0, 0, 1, 0, 0); opacity: 1;">
                            <div data-aos="fade-up" data-aos-duration="2000" data-aos-offset="0">
                                <span>Phone:</span>&nbsp;<a href="tel:+1-612-479-9196">+1-612-479-9196</a>
                            </div>
                        </div>
                        <br />

                    </div>

                </div>

                <div data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0">
                    <h1 class="blog-1__title blog-1__title_dark mb-4 h1"
                        data-anim-parent-trigger="data-anim-parent-trigger" data-mob-anim="group"
                        data-mob-anim-stagger="fade-top" style="transform: matrix(1, 0, 0, 1, 0, 0); opacity: 1;">
                        Canada
                    </h1>
                </div>

                <div class="blog-1__row col__wrap col__wrap_without-caption has-anim pb-5" data-anim="group"
                    data-anim-delay=".1" data-anim-duration=".2">

                    <div class="left-Contact blog-1__col col__small ph_mail">

                        <div class="blog-1__text py-2 lato-reg" data-aos="fade-up" data-aos-duration="1000"
                            data-aos-offset="0">123, Fuhrmann Crescent, Regina, Saskatchewan, S4R 7W2
                        </div>

                        <div class="lato-reg" style="transform: matrix(1, 0, 0, 1, 0, 0); opacity: 1;">
                            <div data-aos="fade-up" data-aos-duration="2000" data-aos-offset="0">
                                <span>Phone:</span>&nbsp;
                                <a href="tel:+1 306 502-5997">+1 (306) 502-5997</a>
                            </div>
                        </div>

                    </div>

                </div>

                <div data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0">
                    <h1 class="blog-1__title blog-1__title_dark mb-4 h1"
                        data-anim-parent-trigger="data-anim-parent-trigger" data-mob-anim="group"
                        data-mob-anim-stagger="fade-top" style="transform: matrix(1, 0, 0, 1, 0, 0); opacity: 1;">
                        India
                    </h1>
                </div>

                <div class="blog-1__row col__wrap col__wrap_without-caption has-anim" data-anim="group"
                    data-anim-delay=".1" data-anim-duration=".2">

                    <div class="left-Contact blog-1__col col__small ph_mail">

                        <div class="blog-1__text py-2 lato-reg" data-aos="fade-up" data-aos-duration="1000"
                            data-aos-offset="0">
                            No 51, 4th Floor, Tower-C, <br>Tek Meadows, OMR Road, Sholinganallur, Chennai, Tamil Nadu –
                            600119
                        </div>

                        <div class="lato-reg" style="transform: matrix(1, 0, 0, 1, 0, 0); opacity: 1;">
                            <div data-aos="fade-up" data-aos-duration="2000" data-aos-offset="0">
                                <span>Phone:</span>&nbsp;
                                <a href="tel:+91-44-49574927">+91-44-49574927</a>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <div class="col-lg-5 contact_us_map" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="0"></div>
        </div>
    </div>
</section>

<link href="/css/all-1572875203.css?v=1572945654" rel="stylesheet">
<script src="/js/all-1572875203.js?v=1572945654"></script>
<script type="text/javascript" charset="UTF-8"
    src="https://maps.googleapis.com/maps-api-v3/api/js/42/9/common.js"></script>
<script type="text/javascript" charset="UTF-8"
    src="https://maps.googleapis.com/maps-api-v3/api/js/42/9/util.js"></script>
<script type="text/javascript" charset="UTF-8"
    src="https://maps.googleapis.com/maps-api-v3/api/js/42/9/map.js"></script>
<script type="text/javascript" charset="UTF-8"
    src="https://maps.googleapis.com/maps-api-v3/api/js/42/9/marker.js"></script>
<script type="text/javascript" charset="UTF-8"
    src="https://maps.googleapis.com/maps-api-v3/api/js/42/9/overlay.js"></script>
<script type="text/javascript" charset="UTF-8"
    src="https://maps.googleapis.com/maps-api-v3/api/js/42/9/stats.js"></script>
<script type="text/javascript" charset="UTF-8"
    src="https://maps.googleapis.com/maps-api-v3/api/js/42/9/onion.js"></script>
<script type="text/javascript" charset="UTF-8"
    src="https://maps.googleapis.com/maps-api-v3/api/js/42/9/infowindow.js"></script>
<script type="text/javascript" charset="UTF-8"
    src="https://maps.googleapis.com/maps-api-v3/api/js/42/9/controls.js"></script>