import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aec-bim',
  templateUrl: './aec-bim.component.html',
  styleUrls: ['./aec-bim.component.css']
})
export class AecBimComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
