import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-embedded-software-system',
  templateUrl: './embedded-software-system.component.html',
  styleUrls: ['./embedded-software-system.component.css']
})
export class EmbeddedSoftwareSystemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
