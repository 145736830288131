<section id="ai-ml-banner" class="min-banner overlay">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="banner-text">
            <h1>AI / ML</h1>
            <h4>Msaii help create advance results, drive operational development and Accelerate business efficiency with futuristic Artificial Intelligence (AI) and Machine Learning Solutions.</h4><br>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="pt-5 pb-5 design-content">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h6>Built the Future- Gen AI/ML Solution to embrace true potential of Digital Transformation</h6>
                <p>Msaii makes it easy for businesses to realize true potential from Artificial Intelligence (AI) and Machine Learning (ML). We help enterprise to build, adopt, utilize and implement competent AI & ML solutions that drive better business outcomes. Our AI & ML portfolio powers enterprises with intelligent features that eliminate human errors and increase performance by automating business activities.</p>
                <p>Machine Learning and AI support critical business needs from automation to augmentation by gaining insight through data analysis and engagement of end-users. Msaii’s provides advisory, ideation, and implementation services to customers in all verticals.</p>
                <p>The use of machine learning (ML) and artificial intelligence (AI) is expanding. Smart systems are penetrating in all areas of business and public life, basing on advanced tools for data collection and analysis, knowledge discovery, forecasting, and decision making. These systems can "think" quickly, independently "perceive" their environment, and operate in dynamically changing conditions. This increases the efficiency and quality of operations. The Msaii team has been successfully involved in AI-related projects.</p>
                <h1>As Artificial <span>Intelligence</span> &amp; <br> Machine Learning <span>Experts,</span> we can help you in</h1>
                <p>Implementing complex and highly beneficial algorithms that suit your business model. Our data scientists and AI developers will strategically formulate the algorithms depending on your dataset to overcome your core challenges and forthcoming opportunities.</p>
            </div>
        </div>
    </div>
  </section>
  <section id="core-services" class="common-pad pt-5 pb-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="section-title mb-5">
            <h1>Digital Industry Services</h1>
            <p>MSAII is a modern engineering company, dedicated to providing high quality design and product development
              services for the engineering industry.</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="core-services-wrap">
            <a [routerLink]="['/', 'digital-industry', 'semiconductor']">
              <img src="assets/images/industires/digital-banner.png" alt="digital banner">
              <div class="lato-bold">Semiconductor</div>
            </a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="core-services-wrap">
            <a [routerLink]="['/','digital-industry', 'iot']">
              <img src="assets/images/iot.png" alt="iot">
              <div class="lato-bold">IOT</div>
            </a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="core-services-wrap">
            <a [routerLink]="['/','digital-industry', 'ui-ux']">
              <img src="assets/images/ui-ux-3.jpg" alt="ui-ux">
              <div class="lato-bold">UI / UX</div>
            </a>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-4">
          <div class="core-services-wrap">
            <a [routerLink]="['/', 'digital-industry','software-testing']">
              <img src="assets/images/testing/software-testing.png" alt="software-testing">
              <div class="lato-bold">Software Testing</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
