<section id="product-banner" class="min-banner overlay text-white">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="banner-text">
            <h1>Product </h1> <h2>Design</h2>
            <h4>Everything has been designed</h4>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="pt-5 pb-5 text-uppercase">
    <div class="container">
        <div class="row  pb-5">

            <div class="col-6">
              <img src="assets/images/designs/clay-modeling.jpg" class="img-fluid b-radius w-100">
            </div>
            <div class="col-4">
              <h1>Clay</h1><h1> Modeling</h1>
            </div>
        </div>
        <div class="row pb-5">
            <div class="col-4">
              <h1>Conceptual</h1> <h1>design</h1>
            </div>
            <div class="col-6">
              <img src="assets/images/designs/concept.jpg" class="img-fluid b-radius w-100">
            </div>
          </div>

        <div class="row  pb-5">

            <div class="col-6">
              <img src="assets/images/designs/prototype.jpg" class="img-fluid b-radius w-100">
            </div>
            <div class="col-4">
              <h1>Prototypes</h1>
            </div>

        </div>
            </div>

  </section>

  <section class="industries-main designs">
    <div class="container">
      <div class="row">
        <div class="col-lg-12"><h2>Our Services</h2></div>
        <div class="col-lg-6 mb-5">
            <div class="design-wrap">
                <a [routerLink]="['/','engineering']">
                    <img src="assets/images/designs/eng.png" class="img-fluid b-radius mb-3" alt="">
                    <h3>Engineering</h3>
                </a>
            </div>
        </div>
        <div class="col-lg-6 mb-5">
          <div class="design-wrap">
            <a [routerLink]="['/','competency-development']">
                <img src="assets/images/designs/competancy.png" class="img-fluid b-radius mb-3" alt="">
                <h3>Competancy Development</h3>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
